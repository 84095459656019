import React, { useEffect, useState } from 'react';
import { apiFetch } from '../../security/apiFetch';
import { TransitionLeft, download } from '../../helpers/utils';
import { Button, Grid, Paper, Typography, Zoom, styled, CircularProgress, useMediaQuery } from '@mui/material';
import { getAuth } from '../../security/Authentifier';
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    Cell,
    LabelList,
} from 'recharts';
import rapport from '../../assets/COm_VIS_20240205_pictos_BO-DRH_dernier-rapport.png'
import journee from '../../assets/COm_VIS_20240205_pictos_BO-DRH_journees-realisees.png'
import informationIcon from '../../assets/COm_VIS_20240205_pictos_BO-DRH_informations.png'
import InformationPopup from '../../components/InformationPopup';

const colors = ['#006BFF', '#0F00FF', '#8500FF', '#D600FF', '#FF0085', '#FF000A', '#FF4700', '#FF8B00', '#FFE800', '#00A600', '#35B800', '#BDDC00'];

const ColorButton = styled(Button)(({ theme }) => ({
    color: '#01304A',
    backgroundColor: 'white',
    '&:hover': {
      backgroundColor: '#FEFEFE',
    },
}));

export default function Intervention({
    selectedSiteObject,
    setText,
    setTransition,
    setSeverity,
    setOpenSnackbar,
    handleOpenInformationPopup,
    openInformationPopup,
    setOpenInformationPopup,
}) {
    const [data, setData] = useState(false)
    const [toggleInterventionDashboard, setToggleInterventionDashboard] = useState(false)
    const [horizontalBarChartData, setHorizontalBarChartData] = useState([]);
    const [loading, setLoading] = useState(true);
    const matches = useMediaQuery('(max-width:1428px)');

    useEffect(() => {
        (async () => {
            const getInterventions = await apiFetch(`/intervention/tableau-de-bord?entreprise_id=${selectedSiteObject.id}`)
            if (getInterventions && getInterventions.journees_realisees === 0) {
                setData(false)
                setHorizontalBarChartData([])
                setToggleInterventionDashboard(true)
            } else if (getInterventions) {
                setToggleInterventionDashboard(false)
                setData(getInterventions)
                setHorizontalBarChartData(getInterventions.repartition_par_type)
            } else {
                setText('Erreur lors de la récupération des informations intervention')
                setSeverity('error')
                setTransition(() => TransitionLeft)
                setOpenSnackbar(true)
            }
            setLoading(false);
        })()
    }, [selectedSiteObject, setText, setTransition, setSeverity, setOpenSnackbar])

    const handleDownloadRapport = async (row) => {
        const user = getAuth()
        const exportRapport = await fetch(`${row.url}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization' : 'Bearer ' + user.token,
            },
        })

        if (exportRapport.ok) {
            const blob = await exportRapport.blob()
            download(blob, row.nom)
        }
    }

    const CustomizedLabel = (props) => {
        const {x, y, value, fill} = props;
        return (
            <text x={x + props.width + 10} y={y} dy={11} fill={fill} fontSize={15} textAnchor="bottom">
                {value} %
            </text>
        )
    }

    return (
        <div>
            {loading ? (
                <div style={{ textAlign: 'center', marginTop: 20 }}>
                    <CircularProgress color={'primary'} />
                </div>
            ) : (
                data.journees_realisees > 0 ? <div>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={2}>
                        <Zoom in={!!data} timeout={700} style={{ transitionDelay: '100ms' }}>
                            <Paper elevation={0} sx={{borderRadius: '16px', textAlign: 'center', width: matches ? '160%' : '100%'}}>
                                <div style={{width: '100%'}}>
                                    <img
                                        src={informationIcon}
                                        alt={'information'} 
                                        style={{
                                            float: 'right',
                                            marginTop: 10,
                                            marginRight: 10,
                                            cursor: "pointer",
                                        }}
                                        onClick={
                                            () => handleOpenInformationPopup(
                                                {
                                                    title: 'Journées réalisées',
                                                    text: 'Il s’agit du nombre de journées d’intervention sur site réalisées par Pros-Consulte sur les 12 derniers mois, tous types d’intervention confondus.'
                                                }
                                            )
                                        }
                                    />
                                </div>
                                <div style={{padding: '32px 50px'}} >
                                    <Typography variant='h6' color='primary'>
                                        JOURNÉE(S) RÉALISÉE(S)
                                    </Typography>
                                    <Typography variant='body2' style={{textAlign: 'center'}} sx={{color: 'grey', mt: 2, mb: 3}}>
                                        12 derniers mois
                                    </Typography>
                                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                        <Typography variant='body1' color='primary' sx={{mr: 2}}>
                                            {Math.round(data.journees_realisees)}
                                        </Typography>
                                        <img src={journee} alt='Journées réalisées' />
                                    </div>
                                </div>
                            </Paper>
                        </Zoom>
                        <Zoom in={!!data.dernier_rapport} timeout={700} style={{ transitionDelay: '100ms' }}>
                            <Paper elevation={0} sx={{borderRadius: '16px', textAlign: 'center', mt: 3,width: matches ? '160%' : '100%'}}>
                                <div style={{width: '100%'}}>
                                    <img
                                        src={informationIcon}
                                        alt={'information'} 
                                        style={{
                                            float: 'right',
                                            marginTop: 10,
                                            marginRight: 10,
                                            cursor: 'pointer',
                                        }}
                                        onClick={
                                            () => handleOpenInformationPopup(
                                                {
                                                    title: 'Dernier rapport',
                                                    text: 'Il s’agit du dernier rapport d’intervention mis à votre disposition et rédigé par l’intervenant.'
                                                }
                                            )
                                        }
                                    />
                                </div>
                                <div style={{padding: '25px 35px'}} >
                                    <Typography variant='h6' color='primary' sx={{mb: 3}}>
                                        DERNIER RAPPORT
                                    </Typography>
                                    <img src={rapport} alt='dernier rapport' />
                                    <ColorButton variant='contained' onClick={() => handleDownloadRapport(data.dernier_rapport)} sx={{mt: 3}}>
                                        Télécharger
                                    </ColorButton>
                                </div>
                            </Paper>
                        </Zoom>
                    </Grid>
                    <Grid item xs={12} sm={10}>
                        <Zoom in={!!data} timeout={700} style={{ transitionDelay: '100ms' }}>
                            <Paper elevation={0} sx={{p: '17px 50px', borderRadius: '16px',width: matches ? '80%' : '100%',ml: matches ? 14 : 0}}>
                                <div style={{width: '100%', textAlign: 'center', marginBottom: 50}}>
                                    <Grid container spacing={0} sx={{mb: 3}}>
                                        <Grid item xs={11}>
                                            <Typography variant='h6' color='primary' sx={{ml: 10, fontWeight: 'bold'}}>
                                                Types d'intervention réalisées (en nombre de jours)
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={1}
                                            sx={{pl: 5, cursor: 'pointer'}}
                                            onClick={
                                                () => handleOpenInformationPopup(
                                                    {
                                                        title: "Types d'intervention réalisées",
                                                        text: "Il s’agit de la répartition des journées d’intervention par type d’intervention, sur les 12 derniers mois."
                                                    }
                                                )
                                            }
                                        >
                                            <img src={informationIcon} alt={'information'} />
                                        </Grid>
                                    </Grid>
                                    <Typography variant='body2' style={{textAlign: 'center'}} sx={{color: 'grey', mt: 1}}>
                                    12 derniers mois
                                    </Typography>
                                </div>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={4} sx={{pt: '50px !important'}}>
                                        {horizontalBarChartData.map((entry, index) => (
                                            <div style={{display: 'flex', marginTop: 15}} key={index}>
                                                <div
                                                    style={{
                                                        width: 65,
                                                        height: 12,
                                                        background: colors[index % 20],
                                                        borderRadius: '4px',
                                                        marginTop: 5,
                                                        marginRight: 12
                                                    }}
                                                ></div>
                                                <div style={{color: 'grey'}}>{entry.label}</div>
                                            </div>
                                        ))}
                                    </Grid>
                                    <Grid item xs={12} sm={8}>
                                        <div style={{ flex: 1, overflow: 'hidden' }}>
                                            <BarChart
                                                width={600}
                                                height={55 * horizontalBarChartData.length}
                                                data={horizontalBarChartData}
                                                layout="vertical"
                                            >   
                                                <XAxis type="number" axisLine={false} display="none" />
                                                <YAxis type="category" axisLine={true} display="true" />
                                                <Bar dataKey="journees_realisees" fill="#B37FEB" barSize={12} radius={8}>
                                                    <LabelList
                                                        dataKey="journees_realisees"
                                                        position="insideBottom"
                                                        angle={0}
                                                        offset={15}
                                                        fill="grey"
                                                        content={<CustomizedLabel />}
                                                    />
                                                    {horizontalBarChartData.map((entry, index) => (
                                                        <Cell key={`cell-${index}`} fill={colors[index % 20]} />
                                                    ))}
                                                </Bar>
                                            </BarChart>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Zoom>
                    </Grid>
                </Grid>
                <InformationPopup
                    open={openInformationPopup}
                    handleClose={() => setOpenInformationPopup(false)}
                />
            </div> : 
            <div>
                <Zoom in={toggleInterventionDashboard} timeout={700} style={{ transitionDelay: '400ms' }}>
                    <Paper elevation={0} sx={{p: '35px 50px', borderRadius: '16px'}}>
                        <Typography variant='h6' sx={{mb: 2}}>
                            Il n'y a pas d'intervention organisée pour le moment.
                        </Typography>
                        <Typography variant='body1'>
                            Découvrez nos interventions sur site.
                        </Typography>
                    </Paper>
                </Zoom>
                <Zoom in={toggleInterventionDashboard} timeout={700} style={{ transitionDelay: '700ms' }}>
                    <Paper sx={{p: '35px 50px', borderRadius: '16px', mt: 3}}>
                        <Grid container spacing={0}>
                            <Grid item xs={12} sm={6}>
                                <div className='player-left-yt' style={{marginTop: 25}}>
                                    <iframe
                                        width="389"
                                        height="220"
                                        src="https://www.youtube.com/embed/slqWJ_nO52k?si=9IlPF-9RjJ6zyViU?autoplay=1&showinfo=0&modestbranding=1&wmode=transparent&controls=1&color=white&rel=0&enablejsapi=1&playsinline=1&&version=3&theme=light&autohide=1&egm=0&showsearch=0&loop=1"
                                        title="Nos interventions sur site"
                                        frameborder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen;"
                                        allowfullscreen
                                    />
                                </div>
                                <Typography variant='body2' style={{textAlign: 'center'}}>Nos interventions sur site</Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Grid container spacing={5}>
                                    <Grid item xs={12} sm={4}>
                                        <div className='player-right-yt'>
                                            <iframe
                                                width="208"
                                                height="103"
                                                src="https://www.youtube.com/embed/8psO5Zoq1YU?si=7QZ6sTvVGolVtvbZ&modestbranding=0"
                                                title="YouTube video player"
                                                frameborder="0"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen;"
                                                allowfullscreen
                                            />
                                        </div>
                                        <Typography variant='body2' style={{textAlign: 'center'}}>Audit RPS</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <div className='player-right-yt'>
                                            <iframe
                                                width="208"
                                                height="103"
                                                src="https://www.youtube.com/embed/BFhTr7gwOtY?si=XaLDHugsQSiyxTQe&modestbranding=0"
                                                title="Enquête harcèlement"
                                                frameborder="0"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen;"
                                                allowfullscreen
                                            />
                                        </div>
                                        <Typography variant='body2' style={{textAlign: 'center'}}>Enquête harcèlement</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <div className='player-right-yt'>
                                            <iframe
                                                width="208"
                                                height="103"
                                                src="https://www.youtube.com/embed/X8kwGG6msmA?si=wiqit8L9Vjaw2N6G&modestbranding=0"
                                                title="Permanence sur site"
                                                frameborder="0"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen;"
                                                allowfullscreen
                                            />
                                        </div>
                                        <Typography variant='body2' style={{textAlign: 'center'}}>Permanence sur site</Typography>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={5} sx={{mt: 3}}>
                                    <Grid item xs={12} sm={4}>
                                        <div className='player-right-yt'>
                                            <iframe
                                                width="208"
                                                height="103"
                                                src="https://www.youtube.com/embed/MntvEDaUwho?si=SNoI3aQ31RnYDtev&modestbranding=0"
                                                title="Cellule de crise"
                                                frameborder="0"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen;"
                                                allowfullscreen
                                            />
                                        </div>
                                        <Typography variant='body2' style={{textAlign: 'center'}}>Cellule de crise</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <div className='player-right-yt'>
                                            <iframe
                                                width="208"
                                                height="103"
                                                src="https://www.youtube.com/embed/h40Haq-hI5k?si=gfJ-IAqCt-kojAY3&modestbranding=0"
                                                title="Appui à la résolution de conflit"
                                                frameborder="0"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen;" 
                                                allowfullscreen
                                            />
                                        </div>
                                        <Typography variant='body2' style={{textAlign: 'center'}}>Appui à la résolution de conflit</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <div className='player-right-yt'>
                                            <iframe
                                                width="208"
                                                height="103"
                                                src="https://www.youtube.com/embed/-zbqZxe3CJ8?si=w0_zPG7EbIbO17zX&modestbranding=0"
                                                title="Analyse des pratiques professionnelles"
                                                frameborder="0"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen;"
                                                allowfullscreen
                                            />
                                        </div>
                                        <Typography variant='body2' style={{textAlign: 'center'}}>Analyse des pratiques professionnelles</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </Zoom>
            </div>)}
        </div>
    )
}