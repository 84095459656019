import React, { useEffect, useState } from 'react';
import { apiFetch } from '../../security/apiFetch';
import { TransitionLeft } from '../../helpers/utils';
import { Button, Grid, Paper, Typography, Zoom, styled, CircularProgress } from '@mui/material';
import moment from 'moment';
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    Cell,
    LabelList,
} from 'recharts';
import realisee from '../../assets/COm_VIS_20240205_pictos_BO-DRH_formations-realisees.png'
import prevues from '../../assets/COm_VIS_20240205_pictos_BO-DRH_formations-prevues.png'
import distenciel from '../../assets/COm_VIS_20240205_pictos_BO-DRH_distanciel.png'
import presentiel from '../../assets/COm_VIS_20240205_pictos_BO-DRH_presenciel.png'
import informationIcon from '../../assets/COm_VIS_20240205_pictos_BO-DRH_informations.png'
import InformationPopup from '../../components/InformationPopup';

const colors = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', 'red', 'pink'];

const ColorButton = styled(Button)(({ theme }) => ({
    color: '#01304A',
    backgroundColor: 'white',
    '&:hover': {
        backgroundColor: '#FEFEFE',
    },
}));

export default function Formation({
    selectedSiteObject,
    setText,
    setTransition,
    setSeverity,
    setOpenSnackbar,
    openInformationPopup,
    setOpenInformationPopup,
    handleOpenInformationPopup,
}) {
    const [data, setData] = useState(false)
    const [toggleFormationDashboard, setToggleFormationDashboard] = useState(false)
    const [horizontalBarChartData, setHorizontalBarChartData] = useState([]);
    const [loading, setLoading] = useState(true); // Etat pour le chargement des données

    useEffect(() => {
        (async () => {
            setLoading(true); // Définir l'état de chargement sur true avant de charger les données
            const getDashboardData = await apiFetch(`/formation/tableau-de-bord?entreprise_id=${selectedSiteObject.id}`)
            if (getDashboardData && getDashboardData.formations_realisees === 0) {
                setData(false)
                setHorizontalBarChartData([])
                setToggleFormationDashboard(true)
            } else if (getDashboardData) {
                setToggleFormationDashboard(false)
                setData(getDashboardData)
                setHorizontalBarChartData(getDashboardData.repartition_par_theme)
            } else {
                setText('Erreur lors de la récupération des informations formation')
                setSeverity('error')
                setTransition(() => TransitionLeft)
                setOpenSnackbar(true)
            }
            setLoading(false); // Définir l'état de chargement sur false après le chargement des données
        })()
    }, [selectedSiteObject, setText, setTransition, setSeverity, setOpenSnackbar])

    const CustomizedLabel = (props) => {
        const { x, y, value, fill } = props;
        return (
            <text x={x + props.width + 10} y={y} dy={11} fill={fill} fontSize={15} textAnchor="bottom">
                {value} %
            </text>
        )
    }

    return (
        <div>
            {loading ? ( 
                <div style={{ textAlign: 'center', marginTop: '20px' }}>
                    <CircularProgress />
                </div>
            ) : (!toggleFormationDashboard ? <Zoom in={!!data}>
                <div>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={3}>
                            <Paper elevation={0} sx={{p: '40px 10px', borderRadius: '16px', textAlign: 'center'}}>
                                <Typography variant='body1' color='primary'>
                                    PROCHAINE FORMATION PRÉVUE LE :
                                </Typography>
                                <Typography variant='body1' color='primary' sx={{mt: 2}}>
                                    {data.prochaine_formation_prevue_le ? 
                                        moment(data.prochaine_formation_prevue_le).format('DD.MM.YYYY') : 
                                        'Pas de prochaine formation prévue'}
                                </Typography>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Paper elevation={0} sx={{ borderRadius: '16px', textAlign: 'center' }}>
                                <div style={{ width: '100%' }}>
                                    <img
                                        src={informationIcon}
                                        alt={'information'}
                                        style={{
                                            float: 'right',
                                            marginTop: 10,
                                            marginRight: 10,
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => handleOpenInformationPopup({
                                            title: 'Formation réalisée',
                                            text: "Il s’agit du nombre de formation organisées avec Pros-Consulte depuis le début de notre collaboration."
                                        })}
                                    />
                                </div>
                                <div style={{ padding: '35px 10px' }} >
                                    <Typography variant='body1' color='primary' sx={{ ml: 2 }}>
                                        {data.formations_realisees <= 1 ? 'FORMATION RÉALISÉE' : 'FORMATIONS RÉALISÉES'}
                                    </Typography>
                                    <div style={{ display: 'flex', marginTop: 16, alignItems: 'center', justifyContent: 'center' }}>
                                        <Typography variant='body1' color='primary' sx={{ fontSize: 22, mr: 2 }}>
                                            {data.formations_realisees}
                                        </Typography>
                                        <img src={realisee} alt='FORMATIONS RÉALISÉE' />
                                    </div>
                                </div>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Paper elevation={0} sx={{borderRadius: '16px', textAlign: 'center'}}>
                                <div style={{width: '100%'}}>
                                    <img
                                        src={informationIcon}
                                        alt={'information'} 
                                        style={{
                                            float: 'right',
                                            marginTop: 10,
                                            marginRight: 10,
                                            cursor: 'pointer',
                                        }}
                                        onClick={
                                            () => handleOpenInformationPopup(
                                                {
                                                    title: 'Formations prévues',
                                                    text: "Il s’agit du nombre de formation prévues avec Pros-Consulte."
                                                }
                                            )
                                        }
                                    />
                                </div>
                                <div style={{padding: '35px 10px'}} >
                                    <Typography variant='body1' color='primary' sx={{ ml: 2 }}>
                                        {data.formations_prevues <= 1 ? 'FORMATION PRÉVUE' : 'FORMATIONS PRÉVUES'}
                                    </Typography>
                                    <div style={{ display: 'flex', marginTop: 16, alignItems: 'center', justifyContent: 'center' }}>
                                        <Typography variant='body1' color='primary' sx={{ fontSize: 22, mr: 2 }}>
                                            {data.formations_prevues}   
                                        </Typography>
                                        <img src={prevues} alt='FORMATIONS PRÉVUES' />
                                    </div>
                                </div>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Paper elevation={0} sx={{borderRadius: '16px', textAlign: 'center'}}>
                                <div style={{width: '100%'}}>
                                    <img
                                        src={informationIcon}
                                        alt={'information'} 
                                        style={{
                                            float: 'right',
                                            marginTop: 10,
                                            marginRight: 10,
                                            cursor: 'pointer',
                                        }}
                                        onClick={
                                            () => handleOpenInformationPopup(
                                                {
                                                    title: 'Formations dispensées',
                                                    text: 'Il s’agit du pourcentage de formations réalisées en présentiel ou en distanciel.'
                                                }
                                            )
                                        }
                                    />
                                </div>
                                <div style={{padding: '35px 10px'}}>
                                    <Typography variant='body1' color='primary' sx={{ml: 2}}>
                                        FORMATIONS DISPENSÉES
                                    </Typography>
                                    <div style={{display: 'flex' , alignItems: 'center', justifyContent: 'center', marginTop: 16}}>
                                        <Typography variant='body1' color='primary' sx={{mr: 1, fontSize: 22}}>
                                            {data.pourcentage_formations_presentiel} %
                                        </Typography>
                                        <img src={presentiel} alt='FORMATIONS PRESENTIEL' style={{marginRight: 25}} />
                                        <Typography variant='body1' color='primary' sx={{fontSize: 22, mr: 1}}>
                                            {data.pourcentage_formations_distanciel} %
                                        </Typography>
                                        <img src={distenciel} alt='FORMATIONS DISTENCIEL' />
                                    </div>
                                </div>
                            </Paper>
                        </Grid>
                    </Grid>
                    <Paper elevation={0} sx={{borderRadius: '16px', mt: 4, pb: 3}}>
                        <div style={{width: '100%'}}>
                            <img
                                src={informationIcon}
                                alt={'information'} 
                                style={{
                                    float: 'right',
                                    marginTop: 10,
                                    marginRight: 10,
                                    cursor: 'pointer',
                                }}
                                onClick={
                                    () => handleOpenInformationPopup(
                                        {
                                            title: 'Thèmes de formation',
                                            text: 'Il s’agit des thèmes de formation dispensés ces douze derniers mois.'
                                        }
                                    )
                                }
                            />
                        </div>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={6} sx={{ pl: '70px !important'}}>
                                <Typography variant='body1' sx={{fontWeight: 'bold', mb: 3}} color='primary'>
                                    Thèmes de formation (12 derniers mois)
                                </Typography>
                                {horizontalBarChartData.map((entry, index) => (
                                    <div style={{display: 'flex', marginTop: 5}} key={index}>
                                        <div
                                            style={{
                                                width: 65,
                                                height: 12,
                                                background: colors[index % 20],
                                                borderRadius: '4px',
                                                marginTop: 5,
                                                marginRight: 12
                                            }}
                                        ></div>
                                        <div>{entry.label}</div>
                                    </div>
                                ))}
                            </Grid>
                            <Grid item xs={12} sm={6} sx={{ mt: 3}}>
                                <div style={{ flex: 1, overflow: 'hidden'}}>
                                    <BarChart
                                        width={500}
                                        height={55 * horizontalBarChartData.length}
                                        data={horizontalBarChartData}
                                        layout="vertical"
                                    >
                                        <XAxis type="number" axisLine={false} display="none" />
                                        <YAxis type="category" axisLine={true} display="true" />
                                        <Bar dataKey="pourcentage" fill="#B37FEB" barSize={12} radius={8}>
                                            <LabelList
                                                dataKey="pourcentage"
                                                position="insideBottom"
                                                angle={0}
                                                offset={15}
                                                fill="grey"
                                                content={<CustomizedLabel />}
                                            />
                                            {horizontalBarChartData.map((entry, index) => (
                                                <Cell key={`cell-${index}`} fill={colors[index % 20]} />
                                            ))}
                                        </Bar>
                                    </BarChart>
                                </div>
                            </Grid>
                        </Grid>
                    </Paper>
                    <InformationPopup
                        open={openInformationPopup}
                        handleClose={() => setOpenInformationPopup(false)}
                    />
                </div>
            </Zoom> : 
            <Zoom in={toggleFormationDashboard}>
                <div style={{textAlign: 'center', paddingBottom: 40*8}}>
                    <Typography variant='h6' sx={{mt: 2}}>
                        Il n'y a pas de formation organisée pour le moment.
                    </Typography>
                    <Typography variant='body1' sx={{fontWeight: 'bold', mt: 2}}>
                        Vous souhaitez en savoir plus sur nos formations ?
                    </Typography>
                    <ColorButton
                        variant='contained'
                        sx={{mt: 2}}
                        onClick={() => window.open('https://issuu.com/geo-psy/docs/catalogue_formation_-_2024_1_', '_blank')}
                    >
                        Télécharger
                    </ColorButton>
                </div>
            </Zoom>)}
        </div>
    )
}