import React, { useState } from 'react'
import { Typography, TextField, Button } from "@mui/material";
import { LockOpen } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { TransitionLeft } from '../helpers/utils';
import LoadingButton from '@mui/lab/LoadingButton';
import AlertSnackbar from '../components/AlertSnackbar';
import { apiFetch } from '../security/apiFetch';
import logo from '../assets/logo.jpg'

export default function ResetPassword() {
    const [error, setError] = useState(false)
    const [fieldError, setFieldError] = useState({})
    const [loading, setLoading] = useState(false)
    const [transition, setTransition] = useState(() => TransitionLeft)
    const [severity, setSeverity] = useState('info')
    const [message, setMessage] = useState(false)
    let navigate = useNavigate()

    document.title = 'Espace RH - Changer de mot de passe'

    const handleSubmit = async (e) => {
        setLoading(true)
        setError(false)
        setFieldError({})
        e.preventDefault()
        let data = new FormData(e.target)
        const body = {
            email: data.get('email'),
        }

        const changePassword = await apiFetch('/mon-compte/mot-de-passe-oublie', {
            method: 'POST',
            body: JSON.stringify(body)
        }, true)
        if (changePassword === "success") {
            setMessage('La demande de changement de mot de passe a bien été pris en compte, un mail vient de vous êtes envoyé, veuillez cliquer sur le lien.')
            setTransition(() => TransitionLeft)
            setSeverity('success')
            setError(true)
            setTimeout(() => {
                navigate('/login')
            }, 3000)
        } else {
            setMessage(changePassword.message + ' veuillez réessayer !' || 'Désolé, une erreur ses produites lors de la connexion, veuillez réessayer !')
            setTransition(() => TransitionLeft)
            setSeverity('error')
            setError(true)
        }
        

        setLoading(false)
    }

    return (
        <div 
            className='login'
        >
            <div
                style={{
                    maxWidth: 380,
                    width: '100%',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)'
                }}
            >
                <div style={{padding: 20, borderRadius: '10px', backgroundColor: 'white', }}>
                    <div style={{width: '100%', margin: 'auto', textAlign: 'center'}}>
                        <img src={logo} alt="Pros consulte" width={100} height={100} />
                    </div>
                    <Typography 
                        variant='h5' 
                        color={'primary'} 
                        style={{
                            fontSize: 22,
                            fontWeight: 'bold',
                            letterSpacing: 1,
                            marginBottom: 15
                        }}
                    >
                        Bienvenue sur votre espace ! 
                    </Typography>
                    <Typography variant='h6' color={'primary'} style={{fontSize: 15}}>
                        Pour changer votre mot de passe, veuillez renseigner votre email.
                    </Typography><br />
                    <Typography variant='h6' color={'primary'} style={{fontSize: 15}}>
                        Si vous n'avez pas encore votre identifiant, <a href="https://www.pros-consulte.com/contact/">contactez-nous</a>.
                    </Typography>
                    <form noValidate onSubmit={handleSubmit} autoComplete="on">
                        <TextField
                            color={'primary'}
                            autoComplete='email'
                            id="email"
                            name="email"
                            label="Email *"
                            sx={{width: '100%', marginTop: 3.75}}
                            error={!!fieldError.email}
                            helperText={fieldError.email}
                        /><br />
                        <LoadingButton
                            loading={loading}
                            type="submit"
                            variant="contained"
                            color={'primary'}
                            sx={{marginTop: 5, width: '100%', height: 50, marginBottom: 2}}
                            endIcon={<LockOpen />}
                        >
                            Envoyer
                        </LoadingButton>
                        <Button variant='contained' onClick={() => navigate(-1)} color='secondary'>
                            Retour
                        </Button>
                    </form>
                </div>
            </div>
            <AlertSnackbar open={!!error} setOpen={setError} transition={transition} message={message} severity={severity} />
        </div>
    )
}