import Styles from "./Card.module.css";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSpring, animated } from "react-spring";

function Card({ imagen, item, handleNavigate }) {
    const [show, setShown] = useState(false);
    let navigate = useNavigate()

    const props3 = useSpring({
        transform: show ? "scale(1.03)" : "scale(1)",
        boxShadow: show
            ? "0 20px 25px rgb(0 0 0 / 25%)"
            : "0 2px 10px rgb(0 0 0 / 8%)"
    });

    return (
        <animated.div
            className={Styles.card}
            style={props3}
            onMouseEnter={() => setShown(true)}
            onMouseLeave={() => setShown(false)}
            onClick={() => {
                navigate(`/conseils-prevention/${item.id}`)
            }}
        >
            <div style={{cursor: 'pointer'}} onClick={handleNavigate}>
                <img src={imagen} alt={item.banniere.nom} />
                <p>
                    {item.sous_titre}
                </p>
            </div>
        </animated.div>
    );
}

export default Card;
