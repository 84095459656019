import React, { useEffect, useState } from 'react';
import Card from './Carousel/Card';
import Carroussel from './Carousel/Carousel';
import { Avatar, Grid, Typography, Zoom, CircularProgress } from '@mui/material';
import { apiFetch } from '../security/apiFetch';
import { useNavigate } from 'react-router-dom';
import ReactGA from 'react-ga';

export default function Documents({ setBreadcrumb }) {
    const [psy, setPsy] = useState(null);
    const [cards, setCards] = useState([]);
    const [loading, setLoading] = useState(true);
  
    let navigate = useNavigate();

    document.title = 'Espace RH - Conseils prévention';

    const handleNavigate = (param) => {
        navigate(param.url);
    };

    useEffect(() => {
        (async () => {
            setBreadcrumb([`Conseils prévention`]);
            const getPsy = await apiFetch('/mon-compte/mon-psychologue-referent').catch(() => null);
            if (getPsy && getPsy.nom) {
                setPsy(getPsy);
            } else {
                setPsy(null);
            }
            const getCategories = await apiFetch('/communication/conseil-prevention/categories');
            if (getCategories && getCategories.length) {
                for (const category of getCategories) {
                    const getArticles = await apiFetch(`/communication/conseil-prevention/categories/${category.id}/articles`);
                    if (getArticles && getArticles.length > 0) {   
                        setCards((prevCards) => [
                            ...prevCards,
                            {
                                key: category.id,
                                content: (
                                    <Card imagen={category.banniere.url} item={category} handleNavigate={handleNavigate} setBreadcrumb={setBreadcrumb} />
                                )
                            }
                        ]);
                    }    
                    
                }
            }
            setLoading(false);
        })();
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);
    
    return (
        <div style={{marginTop: 100}}>
            {loading ? ( 
                <div style={{textAlign: 'center', marginTop: 20}}>
                    <CircularProgress color={'primary'} />
                </div>
            ) : (
                <>
                    {!!cards.length && (
                        <Carroussel
                            cards={cards}
                            height="350px"
                            width="30%"
                            margin="0 auto"
                            offset={3}
                            showArrows={true}
                        />
                    )}
                    {psy !== null && (
                        <Zoom in={!!psy} timeout={700} style={{ transitionDelay: '400ms' }}>
                            <div
                                style={{
                                    border: '5px solid #ACCDD8',
                                    borderRadius: 16,
                                    padding: '15px 30px',
                                    marginTop: 120
                                }}
                            >
                                <Typography variant='h6' sx={{fontWeight: 'bold', mb: 3}} color='primary'>Votre Psychologue référent</Typography>
                                <Grid container spacing={5}>
                                    <Grid item xs={12} sm={2} md={1} sx={{ display: 'flex', justifyContent: 'center' }}>
                                        <Avatar src={psy.photo && psy.photo.url} alt={psy.photo && psy.photo.nom} sx={{ width: 100, height: 100}} />
                                    </Grid>
                                    <Grid item xs={12} sm={11} md={11}>
                                        <Typography variant='body1' sx={{fontWeight: 'bold'}}>{psy.nom}</Typography>
                                        <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                                            {psy.specialite === 'Travail' ? 'Psychologue du travail' : `Psychologue ${psy.specialite.toLowerCase()}`}
                                        </Typography>
                                        <Typography variant='body1' sx={{mt: 2}}>{psy.presentation}</Typography>
                                    </Grid>
                                </Grid>
                            </div>
                        </Zoom>
                    )}
                </>
            )}
        </div>
    );
}
