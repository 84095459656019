import { Slide, Tooltip, Typography, Zoom, styled, tooltipClasses } from "@mui/material";
import { routes } from "../security/Routes";
import interventionLogo from '../assets/COm_VIS_20240205_pictos_BO-DRH_journees-realisees.png'
import formationLogo from '../assets/COm_VIS_20240205_pictos_BO-DRH_formations-prevues.png'
import contratLogo from '../assets/COm_VIS_20240205_pictos_BO-DRH_dernier-rapport.png'
import SendToMobileIcon from '@mui/icons-material/SendToMobile';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import PersonalVideoIcon from '@mui/icons-material/PersonalVideo';
import ChatIcon from '@mui/icons-material/Chat';

const StyledTooltip = styled(({ className, ...props }) => (
    <Tooltip placement="right" {...props} arrow TransitionComponent={Zoom} classes={{ popper: className }} />
))(({theme}) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.primary.main,
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.primary.main,
    },
}));

export function TransitionLeft(props) {
    return <Slide {...props} direction="left" />;
}

export function isKnownRoutes(route, location) {
    const r = route.lastIndexOf('/')
    let formattedRoute = route
    if (r > 0) {
        formattedRoute = route.substring(0,r) + '/:id'
    }
    return Object.keys(routes).find((key) => !!(routes[key] === location.state || formattedRoute)); 
}

export function findPage(page) {
    switch(page) {
        case 'interventions':
            return (
                <div style={{display: 'flex'}}>
                    <img src={interventionLogo} alt="mes interventions" />
                    <Typography variant='h5' sx={{fontSize: 20, fontWeight: 'bold', color: '#707070', mt: 0.5, ml: 1}}>
                        MES INTERVENTIONS
                    </Typography>
                </div>
            )
        case 'mon-compte':
            return (
                <Typography variant='h5' sx={{fontSize: 20, fontWeight: 'bold', color: '#707070', mt: 0.5, ml: 1}}>
                    MON COMPTE
                </Typography>
            )
        case 'formations':
            return (
                <div style={{display: 'flex'}}>
                    <img src={formationLogo} alt="mes formations" />
                    <Typography variant='h5' sx={{fontSize: 20, fontWeight: 'bold', color: '#707070', mt: 0.5, ml: 1}}>
                        MES FORMATIONS
                    </Typography>
                </div>
            )
        case 'mon-contrat':
            return (
                <div style={{display: 'flex'}}>
                    <img src={contratLogo} alt="mon contrat" />
                    <Typography variant='h5' sx={{fontSize: 20, fontWeight: 'bold', color: '#707070', mt: 0.5, ml: 1}}>
                        MON CONTRAT
                    </Typography>
                </div>
            )
        case 'ligne-d-ecoute':
            return ' '
        case 'conseils-prevention':
            return (
                ' '
            )
        default:
            return ''
    }
}

export function download(blob, filename) {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
}

export const progressBarWidth = (e, setStyle) => {
    let percentage = 0
    let background = null

    if (e.target.value.length < 4) {
        percentage = 0;
        background = "#dd4b39";
    } else if (e.target.value.length < 6) {
        percentage = 30;
        background = "#dd4b39";
    } else if (e.target.value.length < 8) {
        percentage = 50;
        background = "#ff9800";
    } else {
        percentage = 70;
        background = "#ff9800";
    }

    if ((e.target.value.match(/[a-z]/) != null)) {
        percentage += 10;
    }
    
    if ((e.target.value.match(/[A-Z]/) != null)) {
        percentage += 10;
    }
    
    if ((e.target.value.match(/0|1|2|3|4|5|6|7|8|9/) != null)) {
        percentage += 10;
    }

    if (percentage === 100) {
        background = "#4caf50";
    }

    setStyle({width: percentage, color: background})
}

export const findRightRessourceText = (text) => {
    switch(text) {
        case 'PDF':
            return 'Lire'
        case 'Vidéo':
            return 'Voir'
        case 'Lien hypertexte':
            return 'Consulter'
        default:
            return 'Écouter'
    }
}

export function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export const findIcon = (value) => {
    switch(value) {
        case 1:
            return (
            <StyledTooltip
                title={'Appel entrant'}
            >
                <LoginIcon style={{ color: '#01304A' }} />
            </StyledTooltip>
            )
        case 4:
            return (
            <StyledTooltip
                title={'Tchat'}
            >
                <ChatIcon style={{ color: '#43A44C' }} />
            </StyledTooltip>
            )
        case 2:
            return (
            <StyledTooltip
                title={'Appel sortant'}
            >
                <LogoutIcon style={{ color: '#D96F00' }} />
            </StyledTooltip>
            )
        case 5:
            return (
            <StyledTooltip
                title={'Appel entrant depuis l\'application mobile'}
            >
                <SendToMobileIcon style={{ color: '#32C7C3' }} />
            </StyledTooltip>
            )
        default:
            return (
            <StyledTooltip
                title={'Visio'}
            >
                <PersonalVideoIcon style={{ color: '#8E44AD' }} />
            </StyledTooltip>
            )
    }
}