import {
    Box,
    CircularProgress,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    LinearProgress,
    MenuItem,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Typography,
    Zoom, 
    createTheme,
    ThemeProvider,
    tooltipClasses,
    styled,
    Tooltip
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { DatePickerComponent } from '../components/DatePicker';
import { apiFetch } from '../security/apiFetch';
import { TransitionLeft, download } from '../helpers/utils';
import { useNavigate } from 'react-router-dom';
import VisibilityIcon from '@mui/icons-material/Visibility';
import * as locales from '@mui/material/locale';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { useTheme } from '@emotion/react';
import ReactGA from 'react-ga';

const StyledTooltip = styled(({ className, ...props }) => (
    <Tooltip placement="left" {...props} arrow TransitionComponent={Zoom} classes={{ popper: className }} />
))(({theme}) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.primary.main,
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.primary.main,
    },
}));

function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;
  
    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };
  
    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };
  
    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };
  
    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };
  
    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
              onClick={handleFirstPageButtonClick}
              disabled={page === 0}
              aria-label="première page"
            >
              {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton
              onClick={handleBackButtonClick}
              disabled={page === 0}
              aria-label="page précédente"
            >
              {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
              onClick={handleNextButtonClick}
              disabled={page >= Math.ceil(count / rowsPerPage) - 1}
              aria-label="page suivante"
            >
              {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
              onClick={handleLastPageButtonClick}
              disabled={page >= Math.ceil(count / rowsPerPage) - 1}
              aria-label="dernière page"
            >
              {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </Box>
    );
}

const headCells = [
    {
        id: 'name',
        numeric: false,
        disablePadding: true,
        label: 'Nom',
    },
    {
        id: 'statut',
        numeric: true,
        disablePadding: false,
        label: 'Statut',
    },
    {
        id: 'date',
        numeric: true,
        disablePadding: false,
        label: 'Date',
    },
    {
        id: 'action',
        numeric: true,
        disablePadding: false,
        label: 'Action',
    },
];

const headCellsAvoir = [
    {
        id: 'name',
        numeric: false,
        disablePadding: true,
        label: 'Nom',
    },
    {
        id: 'date',
        numeric: true,
        disablePadding: false,
        label: 'Date',
    },
    {
        id: 'action',
        numeric: true,
        disablePadding: false,
        label: 'Action',
    },
];

function EnhancedTableHead(props) {
    const { order, orderBy, avoir } =
        props;

    return (
        <TableHead>
            <TableRow>
                {avoir ? headCellsAvoir.map((headCell) => {
                    return <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                        style={{color: '#01304A'}}
                    >
                        {headCell.label}
                    </TableCell>
                    }) : headCells.map((headCell) => {
                    return <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                        style={{color: '#01304A'}}
                    >
                        {headCell.label}
                    </TableCell>
                })}
            </TableRow>
        </TableHead>
    );
}
  
export default function Contrat({ profil, setText, setSeverity, setTransition, setOpenSnackbar }) {
    const [site, setSite] = useState('');
    const [statut, setStatut] = useState('');
    const [selectedSiteObject, setSelectedSiteObject] = useState(false);
    const [documents, setDocuments] = useState(false);
    const [factures, setFactures] = useState(false);
    const [avoirs, setAvoirs] = useState(false);
    const [loading, setLoading] = useState(false);
    const [avoirLoading, setAvoirLoading] = useState(false);
    const [loadingDoc, setLoadingDoc] = useState(false);
    const [hideFeatures, setHideFeatures] = useState(false);
    const [items, setItems] = useState(false)
    const [avoirItems, setAvoirItems] = useState(false)
    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)
    const [downloading, setDownloading] = useState(false);
    const [page, setPage] = React.useState(0);
    const [avoirPage, setAvoirPage] = React.useState(0);
    let navigate = useNavigate()

    document.title = 'Espace RH - Mon contrat'

    useEffect(() => {
        (async () => {
            if (profil && profil.entreprises && profil.entreprises.length === 1) {
                setSelectedSiteObject(profil.entreprises[0])
            }
            if (selectedSiteObject) {
                const getDocuments = await apiFetch(`/commerce/documents-contractuels?entreprise_id=${selectedSiteObject.id}`)
                if (getDocuments && getDocuments.length) {
                    setHideFeatures(false)
                    setDocuments(getDocuments)
                } else if (getDocuments && getDocuments.length === 0) {
                    setHideFeatures(false)
                    setDocuments(getDocuments)
                } else {
                    setHideFeatures(false)
                    setDocuments(false)
                    setText('Erreur lors de la récupération des documents contractuels !')
                    setSeverity('error')
                    setTransition(() => TransitionLeft)
                    setOpenSnackbar(true)
                }
            }
        })()
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, [setSeverity, setText, setTransition, setOpenSnackbar, navigate, selectedSiteObject, profil])

    useEffect(() => {
        (async () => {
            setLoading(true)
            setLoadingDoc(true)
            if (profil && profil.entreprises && profil.entreprises.length === 1) {
                setSelectedSiteObject(profil.entreprises[0])
            }
            if (selectedSiteObject) {
                const getFactures = await apiFetch(`/facturation/factures?limit=5&entreprise_id=${selectedSiteObject.id}`)
                if (getFactures && getFactures.items) {
                    setHideFeatures(false)
                    setItems(getFactures.items)
                    setFactures(getFactures)
                } else {
                    setHideFeatures(false)
                    setItems(false)
                    setFactures(false)
                    setText(getFactures || 'Erreur lors de la récupération de la liste des factures !')
                    setSeverity('error')
                    setTransition(() => TransitionLeft)
                    setOpenSnackbar(true)
                }
                const getAvoirs = await apiFetch(`/facturation/avoirs?limit=5&entreprise_id=${selectedSiteObject.id}`)
                if (getAvoirs && getAvoirs.items) {
                    setHideFeatures(false)
                    setAvoirItems(getAvoirs.items)
                    setAvoirs(getAvoirs)
                } else {
                    setHideFeatures(false)
                    setAvoirItems(false)
                    setAvoirs(false)
                    setText(getAvoirs || 'Erreur lors de la récupération de la liste des factures !')
                    setSeverity('error')
                    setTransition(() => TransitionLeft)
                    setOpenSnackbar(true)
                }
            }
            setLoading(false)
            setLoadingDoc(false)
        })()
    }, [selectedSiteObject, setSeverity, setText, setTransition, setOpenSnackbar, navigate, profil])

    const handleChangePage = async (event, newPage) => {
        const filterType =
            statut ? 
                `statut=${statut}&` : ''
        const filterStartDate =
            startDate ?
                `debut=${moment(startDate).format()}&` : ''
        const filterEndDate =
            endDate ?
                `fin=${moment(endDate).format()}&` : ''
        setLoading(true)
        const fetchFactures = await apiFetch(`/facturation/factures?limit=5&page=${newPage + 1}&entreprise_id=${selectedSiteObject.id}&${filterType}${filterStartDate}${filterEndDate}`)
        if (fetchFactures && fetchFactures.items) {
            setItems(fetchFactures.items)
            setFactures(fetchFactures)
            setPage(fetchFactures.page)
        }
        setLoading(false)
        setPage(newPage);
    };

    const handleChangeAvoirPage = async (event, newPage) => {
        setAvoirLoading(true)
        const fetchAvoirs = await apiFetch(`/facturation/avoirs?limit=5&page=${newPage + 1}`)
        if (fetchAvoirs && fetchAvoirs.items) {
            setAvoirItems(fetchAvoirs.items)
            setAvoirs(fetchAvoirs)
            setAvoirPage(fetchAvoirs.page)
        }
        setAvoirLoading(false)
        setAvoirPage(newPage);
    };


    const handleChange = (event) => {
        setSite(event.target.value);
        setStatut('');
        setStartDate(null);
        setEndDate(null)
        setSelectedSiteObject(profil.entreprises.find((entreprise) => entreprise.id === event.target.value));
    };

    const handleChangeStatut= (event) => {
        setStatut(event.target.value);
        fetchFacturesFilter(event.target.value, 'isFromStatut')
    };

    const handleChangeStartDate = (e) => {
        setStartDate(e);
        fetchFacturesFilter(e, 'isFromStartDate')
    }

    const handleChangeEndDate = (e) => {
        setEndDate(e)
        fetchFacturesFilter(e, 'isFromEndDate')
    }

    const handleDownloadDocument = async (document) => {
        setDownloading(true);
        
        const exportRapport = await apiFetch(`${document.url.split('api-rh')[1]}`, {}, false, true)

        if (exportRapport.ok) {
            const blob = await exportRapport.blob()
            download(blob, `${document.nom}`)
        }
        
        setDownloading(false);
    }
    
    const fetchFacturesFilter = async (param, entryPoint) => {
        const filterType =
            statut || entryPoint === 'isFromStatut' ? 
                `statut=${entryPoint === 'isFromStatut' ? param : statut}&` : ''
        const filterStartDate =
            (startDate && entryPoint !== 'isFromStartDate') || (entryPoint === 'isFromStartDate' && param) ?
                `debut=${entryPoint === 'isFromStartDate' ? moment(param).format() : moment(startDate).format()}&` : ''
        const filterEndDate =
            (endDate && entryPoint !== 'isFromEndDate') || (entryPoint === 'isFromEndDate' && param) ?
                `fin=${entryPoint === 'isFromEndDate' ? moment(param).format() : moment(endDate).format()}&` : ''
        setLoading(true)
        const fetchFactures = await apiFetch(`/facturation/factures?limit=5&entreprise_id=${selectedSiteObject.id}&${filterType}${filterStartDate}${filterEndDate}`)
        if (fetchFactures && fetchFactures.items) {
            setItems(fetchFactures.items)
            setFactures(fetchFactures)
            setPage(fetchFactures.total < 6 ? 0 : fetchFactures.page)
        } else {
            setText('Erreur lors de la récupération des interventions !')
            setSeverity('error')
            setTransition(() => TransitionLeft)
            setOpenSnackbar(true)
        }
        setLoading(false)
    }

    const handleShowFacture = async (param, avoir) => {
        window.open(avoir ? param.avoir.url : param.facture.url, "_blank")
    }

    return (
        
        <div style={{marginTop: 20}}>
            
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                    <Zoom in={!!profil} timeout={700} style={{ transitionDelay: '400ms' }}>
                        <Paper sx={{maxWidth: 1000, borderRadius: 5, boxShadow: '-1px 4px 10px #CCCCCC', padding: '35px 30px'}}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={4}>
                                    {profil && profil.entreprises && profil.entreprises.length > 1 && <FormControl fullWidth sx={{maxWidth: 200}}>
                                        <InputLabel id="site-select-label">Mon entreprise</InputLabel>
                                        <Select
                                            labelId="site-select-label"
                                            id="site-select"
                                            value={site}
                                            label="Mon entreprise"
                                            onChange={handleChange}
                                        >
                                            {profil &&
                                            profil.entreprises &&
                                            profil.entreprises.length > 1 &&
                                            profil.entreprises.map((entreprise) => {
                                                return <MenuItem key={entreprise.id} value={entreprise.id}>{entreprise.nom}</MenuItem>
                                            })}
                                        </Select>
                                    </FormControl>}
                                </Grid>
                                <Grid item xs={12} sm={2} sx={{textAlign: 'center'}}>
                                    {selectedSiteObject &&
                                        <img
                                            src={selectedSiteObject.logo && selectedSiteObject.logo.url}
                                            alt={selectedSiteObject.logo && selectedSiteObject.logo.nom}
                                            style={{width: 60, height: 60}}
                                        />}
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    {selectedSiteObject && selectedSiteObject.adresse && 
                                        <Typography variant='body2' sx={{mt: 1.5}}>
                                            {selectedSiteObject.adresse.adresse}
                                        </Typography>}
                                    {selectedSiteObject && selectedSiteObject.adresse && 
                                        <Typography variant='body2'>
                                            {selectedSiteObject.adresse.code_postal} {selectedSiteObject.adresse.ville}
                                        </Typography>}
                                </Grid>
                            </Grid>
                        </Paper>
                    </Zoom>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Zoom in={!!profil} timeout={700} style={{ transitionDelay: '700ms' }}>
                        <Paper sx={{maxWidth: 1000, borderRadius: 5, boxShadow: '-1px 4px 10px #CCCCCC', padding: '23px 30px'}}>
                            <Typography
                                variant='h6'
                                color='primary'
                                sx={{
                                    fontWeight: 'bold'
                                }}
                            >
                                Mes informations contractuelles
                            </Typography>
                            {selectedSiteObject ? <Grid container spacing={0} sx={{textAlign: 'center', pt: 2}}>
                                <Grid item xs={12} sm={6} sx={{borderRight: '5px solid #CECECE', height: 50, paddingTop: '0px !important'}}>
                                    <Typography
                                        variant='body2'
                                        sx={{ color: '#707070'}}
                                    >
                                        Date de début du contrat
                                    </Typography>
                                    <Typography
                                        variant='body2'
                                        sx={{ color: '#707070', fontWeight: 'bold' }}
                                    >
                                        {moment(selectedSiteObject.debut_de_contrat).format('DD/MM/YYYY')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Typography
                                        variant='body2'
                                        sx={{ color: '#707070'}}
                                    >
                                        Date de fin du contrat
                                    </Typography>
                                    <Typography
                                        variant='body2'
                                        sx={{ color: '#707070', fontWeight: 'bold' }}
                                    >
                                        {moment(selectedSiteObject.fin_de_contrat).format('DD/MM/YYYY')}
                                    </Typography>
                                </Grid>
                            </Grid> : 
                            <Typography variant='body1' sx={{width: '100%', textAlign: 'center', mt: 2}}>
                                Veuillez sélectionner une entreprise
                            </Typography>}
                        </Paper>
                    </Zoom>
                </Grid>
            </Grid>
            <Grid container spacing={3} sx={{mt: 2}}>
                <Grid item xs={12} sm={8}>
                    <Zoom in={!!selectedSiteObject && !hideFeatures} timeout={700} style={{ transitionDelay: '700ms' }}>
                        <Paper sx={{maxWidth: 1000, borderRadius: 5, boxShadow: '-1px 4px 10px #CCCCCC', padding: '20px 30px'}}>
                            <Typography variant='h6' color='primary'>Facturation</Typography>
                            <div style={{display: 'flex', borderBottom: '2px solid #75ADBE', paddingBottom: 30, marginTop: 30}}>
                                <Typography variant='body1' color='primary' sx={{fontWeight: 'bold', mr: 2, mt: 2.5}}>
                                    Rechercher une facture :
                                </Typography>
                                <FormControl fullWidth sx={{maxWidth: 120, mr: 2}} color='primary' variant="standard">
                                    <InputLabel id="statut-select-label">Statut</InputLabel>
                                    <Select
                                        labelId="statut-select-label"
                                        id="statut-select"
                                        value={statut}
                                        label="Statut"
                                        onChange={handleChangeStatut}
                                    >
                                        <MenuItem value={''}>Tous les status</MenuItem>
                                        <MenuItem value={'a_payer'}>à régler</MenuItem>
                                        <MenuItem value={'acquittee'}>acquittée</MenuItem>
                                    </Select>
                                </FormControl>
                                <Typography variant='body1' color='primary' sx={{mt: 2.5, mr: 2}}>
                                    Période du 
                                </Typography>
                                <DatePickerComponent
                                    text={'Date de début'}
                                    selectedDate={startDate}
                                    width={170}
                                    handleDateChange={handleChangeStartDate}
                                />
                                <Typography variant='body1' color='primary' sx={{mt: 2.5, mr: 2}}>
                                    au
                                </Typography>
                                <DatePickerComponent
                                    text={'Date de fin'}
                                    selectedDate={endDate}
                                    width={170}
                                    handleDateChange={handleChangeEndDate}
                                />
                            </div>
                            {!loading ? (
                                <Box sx={{ width: '100%' }}>
                                    <Paper sx={{ width: '100%', mb: 2, mt: 2 }} elevation={0}>
                                        <TableContainer>
                                            <Table sx={{ minWidth: 750 }} aria-labelledby="factures">
                                                <EnhancedTableHead rowCount={factures.total} />
                                                <TableBody>
                                                    {items && items.length > 0 ? (
                                                        items.map((row, index) => {
                                                            const labelId = `facture-table-${index}`;
                                                            return (
                                                                <TableRow hover tabIndex={-1} key={row.id} sx={{ cursor: 'pointer' }}>
                                                                    <TableCell component="th" id={labelId} scope="row" padding="none">
                                                                        {row.numero}
                                                                    </TableCell>
                                                                    <TableCell align="right">{row.statut.label}</TableCell>
                                                                    <TableCell align="right">{moment(row.date).format('DD/MM/YYYY')}</TableCell>
                                                                    <TableCell align="right">
                                                                        <IconButton aria-label="voir la facture" color="primary" onClick={() => handleShowFacture(row)}>
                                                                            <VisibilityIcon />
                                                                        </IconButton>
                                                                    </TableCell>
                                                                </TableRow>
                                                            );
                                                        })
                                                    ) : (
                                                        <TableRow>
                                                            <TableCell colSpan={4} style={{ textAlign: 'center' }}>
                                                                Aucune facture disponible, si vous pensez qu'il s'agit d'une erreur veuillez contacter l'équipe Pros Consulte
                                                            </TableCell>
                                                        </TableRow>
                                                    )}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                                <ThemeProvider theme={(outerTheme) => createTheme(outerTheme, locales['frFR'])}>
                                                    <TablePagination
                                                        component="div"
                                                        labelRowsPerPage=""
                                                        count={factures.total || 0}
                                                        rowsPerPage={5}
                                                        rowsPerPageOptions={[]}
                                                        ActionsComponent={TablePaginationActions}
                                                        page={page}
                                                        onPageChange={handleChangePage}
                                                    />
                                                </ThemeProvider>
                                            </Paper>
                                            {loading && <LinearProgress />}
                                </Box>
                                    ) : (
                                        <div style={{ textAlign: 'center', overflow: 'hidden', marginTop: 100 }}>
                                            <CircularProgress />
                                        </div>
                                    )}
                        </Paper>
                    </Zoom>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Zoom in={!!selectedSiteObject} timeout={700} style={{ transitionDelay: '300ms' }}>
                    <div style={{ border: '5px solid #ACCDD8', borderRadius: 15, padding: '20px 60px' }}>
                        <Typography variant='h6' color='primary' sx={{ textAlign: 'center', mb: 3 }}>
                            Mes documents contractuels
                        </Typography>
                        {loadingDoc ? (
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                <CircularProgress />
                            </div>
                        ) : (
                            <>
                                {documents && documents.length > 0 ? (
                                    documents.map((document, index) => (
                                        <Grid container spacing={3} key={index} sx={{ maxWidth: 300, margin: 'auto' }}>
                                            <Grid item xs={12} sm={6} sx={{ textAlign: 'left' }}>
                                                <Typography variant='body2'>{document.nom}</Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={6} sx={{ textAlign: 'right', mt: -1 }}>
                                                <StyledTooltip title={`Télécharger le document ${document.nom}`}> 
                                                    <IconButton
                                                        aria-label="Télécharger le document"
                                                        color="primary"
                                                        onClick={() => handleDownloadDocument(document)}
                                                        disabled={downloading}
                                                    >
                                                        {downloading ? <CircularProgress size={24} /> : <FileDownloadIcon />}
                                                    </IconButton>
                                                </StyledTooltip>
                                            </Grid>
                                        </Grid>
                                    ))
                                ) : (
                                    <Typography variant='body2'>Pas de documents disponible...</Typography>
                                )}
                            </>
                        )}
                    </div>
                    </Zoom>
                </Grid>
            </Grid>
            <Zoom in={!!selectedSiteObject && !hideFeatures} timeout={700} style={{ transitionDelay: '700ms' }}>
                <Paper sx={{maxWidth: 1000, borderRadius: 5, boxShadow: '-1px 4px 10px #CCCCCC', padding: '20px 30px'}}>
                    <Typography variant='h6' color='primary'>Avoirs</Typography>
                    <div style={{display: 'flex', borderBottom: '2px solid #75ADBE', paddingBottom: 30, marginTop: 30}}>
                    </div>
                    {!avoirLoading ? (
                        <Box sx={{ width: '100%' }}>
                            <Paper sx={{ width: '100%', mb: 2, mt: 2 }} elevation={0}>
                                <TableContainer>
                                    <Table sx={{ minWidth: 750 }} aria-labelledby="avoirs">
                                        <EnhancedTableHead rowCount={avoirs.total} avoir={true} />
                                        <TableBody>
                                            {avoirItems && avoirItems.length > 0 ? (
                                                avoirItems.map((row, index) => {
                                                    const labelId = `avoir-table-${index}`;
                                                    return (
                                                        <TableRow hover tabIndex={-1} key={row.id} sx={{ cursor: 'pointer' }}>
                                                            <TableCell component="th" id={labelId} scope="row" padding="none">
                                                                {row.numero}
                                                            </TableCell>
                                                            <TableCell align="right">{moment(row.date).format('DD/MM/YYYY')}</TableCell>
                                                            <TableCell align="right">
                                                                <IconButton aria-label="voir l'avoir" color="primary" onClick={() => handleShowFacture(row, true)}>
                                                                    <VisibilityIcon />
                                                                </IconButton>
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                })
                                            ) : (
                                                <TableRow>
                                                    <TableCell colSpan={4} style={{ textAlign: 'center' }}>
                                                        Aucun avoir disponible, si vous pensez qu'il s'agit d'une erreur veuillez contacter l'équipe Pros Consulte
                                                    </TableCell>
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                        <ThemeProvider theme={(outerTheme) => createTheme(outerTheme, locales['frFR'])}>
                                            <TablePagination
                                                component="div"
                                                labelRowsPerPage=""
                                                count={avoirs.total || 0}
                                                rowsPerPage={5}
                                                rowsPerPageOptions={[]}
                                                ActionsComponent={TablePaginationActions}
                                                page={avoirPage}
                                                onPageChange={handleChangeAvoirPage}
                                            />
                                        </ThemeProvider>
                                    </Paper>
                                    {avoirLoading && <LinearProgress />}
                        </Box>
                            ) : (
                                <div style={{ textAlign: 'center', overflow: 'hidden', marginTop: 100 }}>
                                    <CircularProgress />
                                </div>
                            )}
                </Paper>
            </Zoom>
        </div>
    )
}