import React, { useEffect, useState } from 'react'
import { Typography, TextField, InputAdornment, IconButton } from "@mui/material";
import { Visibility, VisibilityOff, LockOpen } from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router-dom';
import { TransitionLeft } from '../helpers/utils';
import LoadingButton from '@mui/lab/LoadingButton';
import AlertSnackbar from '../components/AlertSnackbar';
import { apiFetch } from '../security/apiFetch';
import logo from '../assets/logo.jpg'
import ReactGA from 'react-ga';
import moment from 'moment'

export default function Login() {
    const [showPassword, setShowPassword] = useState(false)
    const [error, setError] = useState(false)
    const [fieldError, setFieldError] = useState({})
    const [loading, setLoading] = useState(false)
    const [transition, setTransition] = useState(() => TransitionLeft)
    const [severity, setSeverity] = useState('info')
    const [message, setMessage] = useState(false)
    let navigate = useNavigate()
    const location = useLocation()
    document.title = 'Espace RH - Connexion'

    useEffect(() => {
        if (location && location.search.split('_switch_user=')[1]) {
            localStorage.setItem('SWITCH_USER', JSON.stringify(location.search.split('_switch_user=')[1]))
        }
    }, [location])

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword)
    }

    const handleSubmit = async (e) => {
        setLoading(true)
        setError(false)
        setFieldError({})
        e.preventDefault()
        let data = new FormData(e.target)
        const body = {
            email: data.get('identifiant'),
            password: data.get('password')
        }

        const login = await apiFetch('/login_check', {
            method: 'POST',
            body: JSON.stringify(body)
        }, true)

        if (login.token) {
            ReactGA.event({
                category: moment().format('YYYY-MM-DD HH:mm'),
                action: 'Connexion',
                value: body.email
            })
            localStorage.setItem('AUTH_TOKEN', JSON.stringify(login))
            navigate('/tableau-de-bord')
            /* JSON.parse(localStorage.getItem('LAST_URL')) ? 
                navigate(`${JSON.parse(localStorage.getItem('LAST_URL'))}`, {state: 'isFromLogin'}) : navigate('/') // get last route */
        } else {
            setFieldError({identifiant: true, password: true})
            setMessage(login.message ? login.message + ' veuillez réessayer !' : 'Désolé, une erreur ses produites lors de la connexion, veuillez réessayer !')
            setTransition(() => TransitionLeft)
            setSeverity('error')
            setError(true)
        }
        

        setLoading(false)
    }

    return (
        <div 
            className='login'
        >
            <div
                style={{
                    maxWidth: 380,
                    width: '100%',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)'
                }}
            >
                <div style={{padding: 20, borderRadius: '10px', backgroundColor: 'white', }}>
                    <div style={{width: '100%', margin: 'auto', textAlign: 'center'}}>
                        <img src={logo} alt="Pros consulte" width={100} height={100} />
                    </div>
                    <Typography 
                        variant='h5' 
                        color={'primary'} 
                        style={{
                            fontSize: 22,
                            fontWeight: 'bold',
                            letterSpacing: 1,
                            marginBottom: 15
                        }}
                    >
                        Bienvenue sur votre espace ! 
                    </Typography>
                    <Typography variant='h6' color={'primary'} style={{fontSize: 15}}>
                        Un outil innovant, personnalisé et sécurisé, pour suivre, analyser et consulter les statistiques de votre entreprise 
                        en temps réel, en respectant l’anonymat de vos salariés.
                    </Typography><br />
                    <Typography variant='h6' color={'primary'} style={{fontSize: 15}}>
                        <u><b>Rappel :</b></u> votre identifiant est votre adresse mail professionnelle.
                    </Typography>
                    <form noValidate onSubmit={handleSubmit} autoComplete="on">
                        <TextField
                            color={'primary'}
                            autoComplete='identifiant'
                            id="identifiant"
                            name="identifiant"
                            label="Adresse mail *"
                            sx={{width: '100%', marginTop: 3.75}}
                            error={!!fieldError.identifiant}
                            helperText={fieldError.identifiant}
                        />
                        <TextField 
                            id="password" 
                            color={'primary'}
                            name="password" 
                            label="Mot de passe *"
                            sx={{width: '100%', marginTop: 3}}
                            type={showPassword ? "text" : "password"}
                            autoComplete='current-password'
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                        >
                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                      </IconButton>
                                    </InputAdornment>
                                )
                            }}
                            error={!!fieldError.password}
                            helperText={fieldError.password}
                        /><br />
                        {message && <Typography variant='h6' color={'error'} style={{fontSize: 15}}>
                        Si vous n'avez pas encore votre identifiant, <a href="https://www.pros-consulte.com/contact/">contactez-nous</a>.
                        </Typography>}
                        <LoadingButton
                            loading={loading}
                            type="submit"
                            variant="contained"
                            color={'primary'}
                            sx={{marginTop: 5, width: '100%', height: 50, marginBottom: 2}}
                            endIcon={<LockOpen />}
                        >
                            Se connecter
                        </LoadingButton>
                        <div style={{textAlign: 'center', fontSize: 12, textDecoration: 'underline'}}>
                            <Typography sx={{cursor: 'pointer'}} onClick={() => navigate('/reset-mot-de-passe')}>Mot de passe oublié</Typography>
                        </div>
                    </form>
                </div>
            </div>
            <AlertSnackbar open={!!error} setOpen={setError} transition={transition} message={message} severity={severity} />
        </div>
    )
}