import React from 'react'
import { Typography, Button } from "@mui/material";
import error from '../assets/errorRh.svg'
import { useNavigate } from 'react-router-dom';

export default function Error() {
    let navigate = useNavigate()
    document.title = 'Espace RH - Erreur'

    const handleBack = () => {
        navigate('/')
    }

    return (
        <div style={{margin: 'auto', textAlign: 'center', marginTop: 50}}>
            <Typography variant="h4">
                Désolé !
            </Typography><br />
            <Typography variant="h5">
                Cette page n'existe pas (plus) !
            </Typography><br />
            <Button variant="contained" color={"primary"} onClick={handleBack}>
                Revenir sur la page d'accueil
            </Button><br />
            <img src={error} alt="Error page" style={{width: 750}} />
        </div>
    )
}