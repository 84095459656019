import { Button, Grid, Paper, Typography, Zoom, styled, CircularProgress,Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { apiFetch } from '../../security/apiFetch';
import { useNavigate, useParams } from 'react-router-dom';
import { TransitionLeft, findRightRessourceText } from '../../helpers/utils';
import ReactGA from 'react-ga';

const ColorButton = styled(Button)(({ theme }) => ({
    color: '#01304A',
    backgroundColor: 'white',
    '&:hover': {
      backgroundColor: '#FEFEFE',
    },
}));

export default function Theme({ setOpenSnackbar, setText, setTransition, setSeverity, setBreadcrumb }) {
    const [articles, setArticles] = useState([]);
    const [loading, setLoading] = useState(true);
    const { category } = useParams();
    let navigate = useNavigate();

    useEffect(() => {
        (async () => {
            const getArticles = await apiFetch(`/communication/conseil-prevention/categories/${category}/articles`);
            if (getArticles && getArticles.length) {
                setBreadcrumb([`Conseils prévention`, getArticles[0]]);
                setArticles(getArticles);
            } else {
                setText('Erreur lors de la récupération des articles');
                setSeverity('error');
                setTransition(() => TransitionLeft);
                setOpenSnackbar(true);
                navigate(-1);
            }
            setLoading(false); 
        })();
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, [category, setText, setSeverity, setTransition, setOpenSnackbar, navigate, setBreadcrumb]);

    const handleOpenRessource = (article) => {
        if (article.format === 'Lien hypertexte' && article.media) {
            window.open(article.media.url, '_blank');
        } else {
            setBreadcrumb([`Conseils prévention`, article, article.media]);
            navigate(`/conseils-prevention/${category}/${article.id}`);
        }
    };

    return (
        <Zoom in={true}>
            <div style={{width: '100%'}}>
                {loading ? ( 
                    <Box
                    sx={{
                        width: '100%',
                        textAlign: 'center',
                        marginTop: '20%',
                    }}
                >
                    <CircularProgress />
                </Box>

                ) : (
                    <Grid container spacing={6} sx={{width: '95%', margin: 'auto'}}>
                        {articles && !!articles.length && articles.map((article, index) => (
                            <Zoom
                                in={!!article}
                                timeout={700}
                                key={index}
                                style={{ 
                                    transitionDelay: index * 100 + 200 + 'ms' 
                                }}
                            >
                                <Grid item xs={12} sm={3} key={article.id}>
                                    <Paper sx={{boxShadow: '-1px 4px 10px #CCCCCC', borderRadius: '16px', textAlign: 'center', pb: 4}}>
                                        <img
                                            src={article.photo.url}
                                            alt={article.photo.nom}
                                            style={{
                                                width: '100%',
                                                borderTopLeftRadius: '16px',
                                                borderTopRightRadius: '16px',
                                            }}
                                        />
                                        <div
                                            style={{
                                                background: '#01304a',
                                                width: '100%',
                                                color: 'white',
                                                marginTop: -7,
                                                paddingLeft: 30,
                                                paddingRight: 30,
                                            }}
                                        >
                                            <Typography
                                                variant='body1'
                                                sx={{
                                                    fontWeight: 'bold',
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    whiteSpace: 'nowrap'
                                                }}
                                            >
                                                {article.titre}
                                            </Typography>
                                        </div>
                                        <Typography variant='body2' sx={{m: 3}} className='line-clamp'>
                                            {article.description}
                                        </Typography>
                                        <ColorButton
                                            variant='contained'
                                            onClick={() => handleOpenRessource(article)}
                                        >
                                            {findRightRessourceText(article.format)}
                                        </ColorButton>
                                    </Paper>
                                </Grid>
                            </Zoom>
                        ))}
                    </Grid>
                )}
            </div>
        </Zoom>
    );
}
