import * as React from 'react';
import{
    tooltipClasses,
    styled,
    Tooltip, 
    Zoom
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import { visuallyHidden } from '@mui/utils';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { IconButton, ThemeProvider, createTheme } from '@mui/material';
import { useTheme } from '@emotion/react';
import * as locales from '@mui/material/locale';
import { apiFetch } from '../../security/apiFetch';
import moment from 'moment';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { download } from '../../helpers/utils';

const StyledTooltip = styled(({ className, ...props }) => (
    <Tooltip placement="left" {...props} arrow TransitionComponent={Zoom} classes={{ popper: className }} />
))(({theme}) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.primary.main,
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.primary.main,
    },
}));

function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;
  
    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };
  
    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };
  
    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };
  
    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };
  
    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
              onClick={handleFirstPageButtonClick}
              disabled={page === 0}
              aria-label="première page"
            >
              {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton
              onClick={handleBackButtonClick}
              disabled={page === 0}
              aria-label="page précédente"
            >
              {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
              onClick={handleNextButtonClick}
              disabled={page >= Math.ceil(count / rowsPerPage) - 1}
              aria-label="page suivante"
            >
              {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
              onClick={handleLastPageButtonClick}
              disabled={page >= Math.ceil(count / rowsPerPage) - 1}
              aria-label="dernière page"
            >
              {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </Box>
    );
}

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}
  
function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
}
  
// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    {
        id: 'objet',
        numeric: false,
        disablePadding: true,
        label: 'Objet',
    },
    {
        id: 'Entreprise',
        numeric: true,
        disablePadding: false,
        label: 'entreprise',
    },
    {
        id: 'type',
        numeric: true,
        disablePadding: false,
        label: 'type',
    },
    {
        id: 'action',
        numeric: true,
        disablePadding: false,
        label: 'Action',
    },
];

function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } =
        props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                        style={{color: '#01304A'}}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

export default function RapportsTable({
    rapports,
    items,
    startDate,
    endDate,
    setItems,
    setRapports,
    loadingTable,
    setLoadingRap,
    selectedSiteObject
}) {
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [isDownloading, setIsDownloading] = React.useState(false);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleClick = (event, id) => {
      const selectedIndex = selected.indexOf(id);
      let newSelected = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, id);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1),
        );
      }
      setSelected(newSelected);
    };

    const handleChangePage = async (event, newPage) => {
        const filterStartDate =
            startDate ?
                `debut=${moment(startDate).format()}&` : ''
        const filterEndDate =
            endDate ?
                `fin=${moment(endDate).format()}&` : ''
        const filterEntreprise = selectedSiteObject ? typeof selectedSiteObject === 'object' && selectedSiteObject !== null ? 
                                `entreprise_id=${selectedSiteObject.id}&` : `entreprise_id=${selectedSiteObject}&` : ''
        setLoadingRap(true)
        const fetchFactures = await apiFetch(`/plateforme/rapports?limit=5&page=${newPage + 1}&${filterEntreprise}${filterStartDate}${filterEndDate}`)
        if (fetchFactures && fetchFactures.items) {
            setItems(fetchFactures.items)
            setRapports(fetchFactures)
            setPage(fetchFactures.page)
        }
        setLoadingRap(false)
        setPage(newPage);
    };

    const handleDownloadRapport = async (row) => {
        setIsDownloading(true);
        if (row.rapport) {
            const downloadRapport = await apiFetch(`${row.rapport.url.split('api-rh')[1]}`, {}, false, true)

            if (downloadRapport.ok) {
                const blob = await downloadRapport.blob()
                download(blob, `${row.rapport.nom}`)
            }
        }
        setIsDownloading(false);
        
    }

    return (
        <Box sx={{ width: '100%' }}>
            <Paper sx={{ width: '100%', mb: 2 }} elevation={0}>
                <TableContainer>
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="Rapports"
                    >
                        <EnhancedTableHead
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            rowCount={rapports.total}
                        />
                        <TableBody>
                        {!loadingTable && items && stableSort(items, getComparator(order, orderBy)).map((row, index) => {
                                const labelId = `rapports-table-${index}`;

                                return (
                                    <TableRow
                                        hover
                                        onClick={(event) => handleClick(event, row.id)}
                                        tabIndex={-1}
                                        key={row.id}
                                        sx={{ cursor: 'pointer' }}
                                    >
                                        <TableCell
                                            component="th"
                                            id={labelId}
                                            scope="row"
                                            padding="none"
                                        >
                                            {row.objet}
                                        </TableCell>
                                        <TableCell align="right">{row.entreprise && row.entreprise.nom}</TableCell>
                                        <TableCell align="right">{row.periodicite && row.periodicite.label}</TableCell>
                                        <TableCell align="right">
                                            <StyledTooltip title="Télécharger le rapport"> 
                                                <IconButton
                                                    aria-label="Télécharger le rapport"
                                                    color="primary"
                                                    onClick={() => handleDownloadRapport(row)}
                                                    disabled={isDownloading}
                                                >
                                                    {isDownloading ? ( 
                                                        <CircularProgress size={24} />
                                                    ) : (

                                                        <FileDownloadIcon />
                                                    )}
                                                </IconButton>
                                            </StyledTooltip>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                            {items.length === 0 && !loadingTable && (
                                <TableRow
                                    style={{
                                        height: 53,
                                    }}
                                >
                                    <TableCell colSpan={4}>
                                        Pas de rapports disponible pour le moment, si vous pensez qu'il s'agit d'une erreur veuillez contacter l'équipe Pros Consulte
                                    </TableCell>
                                </TableRow>
                            )}
                            {loadingTable && (
                                <TableRow
                                    style={{
                                        height: 53,
                                    }}
                                >
                                    <TableCell colSpan={4} sx={{textAlign: 'center'}}>
                                        <CircularProgress />
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <ThemeProvider theme={(outerTheme) => createTheme(outerTheme, locales['frFR'])}>
                    <TablePagination
                        component="div"
                        labelRowsPerPage=''
                        count={rapports.total || 0}
                        rowsPerPage={5}
                        rowsPerPageOptions={[]}
                        ActionsComponent={TablePaginationActions}
                        page={page}
                        onPageChange={handleChangePage}
                    />
                </ThemeProvider>
            </Paper>
        </Box>
    );
}