import {
    Avatar,
    Button,
    CircularProgress,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Typography,
    Zoom,
    tooltipClasses,
    styled,
    Tooltip,
    Box
} from '@mui/material';
import React, { createRef, useEffect, useState } from 'react';
import { TransitionLeft, download } from '../helpers/utils';
import { apiFetch } from '../security/apiFetch';
import { useNavigate } from 'react-router-dom';
import moment from 'moment'
import { DatePickerComponent } from '../components/DatePicker';
import ContactPopup from './InterventionsItems/ContactPopup';
import { BottomOfPage } from '../components/BottomOfPage';
import duree from '../assets/COm_VIS_20240205_pictos_BO-DRH_duree.png'
import adresse from '../assets/COm_VIS_20240205_pictos_BO-DRH_localisation.png';
import ReactGA from 'react-ga';

const StyledTooltip = styled(({ className, ...props }) => (
    <Tooltip placement="left" {...props} arrow TransitionComponent={Zoom} classes={{ popper: className }} />
))(({theme}) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.primary.main,
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.primary.main,
    },
}));

export default function Formations({ setText, setSeverity, setOpenSnackbar, setTransition, profil }) {
    const [items, setItems] = useState(false)
    const [formations, setFormations] = useState(false)
    const [page, setPage] = useState(false)
    const [openContactPopup, setOpenContactPopup] = useState(false)
    const [themes, setThemes] = useState(false)
    const [types, setTypes] = useState(false)
    const [formateurs, setFormateurs] = useState(false)
    const [spinner, setSpinner] = useState(false)
    const [loading, setLoading] = useState(false)
    const [zoom, setZoom] = useState(false)
    const [formationZoom, setFormationZoom] = useState(false)
    const [startDate, setStartDate] = useState(null)
    const [site, setSite] = useState('')
    const [theme, setTheme] = useState('')
    const [type, setType] = useState('');
    const [formateur, setFormateur] = useState('');
    let navigate = useNavigate()
    const target = createRef();
    const [downloading, setDownloading] = useState({
        emargement: false,
        convention: false,
        evaluation: false,
    });
    

    document.title = 'Espace RH - Formations'

    useEffect(() => {
        (async () => {
            setZoom(true)
            setLoading(true)
            const getFormations = await apiFetch('/formation/formations')
            if (getFormations && getFormations.items) {
                setThemes(getFormations.filters.find((filter) => filter.field === 'theme').available_values)
                setTypes(getFormations.filters.find((filter) => filter.field === 'type').available_values)
                setFormateurs(getFormations.filters.find((filter) => filter.field === 'formateur_id').available_values)
                setItems(getFormations.items)
                setFormations(getFormations)
                setPage(getFormations.page)
                setFormationZoom(true)
            } else {
                setText('Erreur lors de la récupération des formations !')
                setSeverity('error')
                setTransition(() => TransitionLeft)
                setOpenSnackbar(true)
                navigate('/tableau-de-bord')
            }
            setLoading(false)
        })()
        ReactGA.pageview(window.location.pathname + window.location.search);
        return () => setZoom(false)
    }, [navigate, setText, setSeverity, setOpenSnackbar, setTransition, setZoom])

    const handleDownloadDocument = async (doc, isDownloading) => {
        if (doc) {
            setDownloading(isDownloading);
            const downloadDoc = await apiFetch(`${doc.url.split('api-rh')[1]}`, {}, false, true)

            if (downloadDoc.ok) {
                const blob = await downloadDoc.blob()
                download(blob, `${doc.nom}`)
            }
            setDownloading({
                emargement: false,
                convention: false,
                evaluation: false,
            });
        }
    }

    const handleChangeSite = (event) => {
        setSite(event.target.value);
        fetchFormationsFilter(event.target.value, 'isFromSite')
    };

    const handleChangeType = (event) => {
        setType(event.target.value);
        fetchFormationsFilter(event.target.value, 'isFromType')
    };

    const handleChangeStartDate = (e) => {
        if (!e) {
            setStartDate(e);
            fetchFormationsFilter(e, 'isFromStartDate')
        } else if (e._d.toString() !== 'Invalid Date') {
            setStartDate(e);
            fetchFormationsFilter(e, 'isFromStartDate')
        }
    }

    const handleChangeFormateur = (event) => {
        setFormateur(event.target.value);
        fetchFormationsFilter(event.target.value, 'isFromFormateur')
    };

    const handleChangeTheme = (event) => {
        setTheme(event.target.value);
        fetchFormationsFilter(event.target.value, 'isFromTheme')
    };


    const fetchFormationsFilter = async (param, entryPoint) => {
        const filterSite =
            site || entryPoint === 'isFromSite' ?
                `entreprise_id=${entryPoint === 'isFromSite' ? param : site}&` : ''
        const filterType =
            type || entryPoint === 'isFromType' ? 
                `type=${entryPoint === 'isFromType' ? param : type}&` : ''
        const filterStartDate =
            startDate || entryPoint === 'isFromStartDate' ?
                `debut=${entryPoint === 'isFromStartDate' ? param ? moment(param).format() : '' : moment(startDate).format()}&` : ''
        const filterFormateur =
            formateur || entryPoint === 'isFromFormateur' ? 
                `formateur_id=${entryPoint === 'isFromFormateur' ? param : formateur}&` : ''
        const filterTheme =
            theme || entryPoint === 'isFromTheme' ? 
                `theme=${entryPoint === 'isFromTheme' ? param : theme}&` : ''
        setFormationZoom(false)
        setLoading(true)
        const fetchInterventions = await apiFetch(`/formation/formations?${filterSite}${filterType}${filterStartDate}${filterFormateur}${filterTheme}`)
        if (fetchInterventions && fetchInterventions.items) {
            setItems(fetchInterventions.items)
            setFormations(fetchInterventions)
            setPage(fetchInterventions.page)
            setFormationZoom(true)
        } else {
            setText('Erreur lors de la récupération des formations !')
            setSeverity('error')
            setTransition(() => TransitionLeft)
            setOpenSnackbar(true)
            navigate('/tableau-de-bord')
        }
        setLoading(false)
    }

    const handleOpenContactPopup = () => {
        setOpenContactPopup(true)
    }

    const enterHandler = async () => {
        const filterSite =
            site ?
                `entreprise_id=${site}&` : ''
        const filterType =
            type ?
                `type=${type}&` : ''
        const filterFormateur =
            formateur? 
                `formateur_id=${formateur}&` : ''
        const filterTheme =
            theme ? 
                `theme=${theme}&` : ''
        const filterStartDate =
            startDate ?
                `debut=${moment(startDate).format()}&` : ''
        setSpinner(true)
        const fetchInterventions = await apiFetch(`/formation/formations?${filterSite}${filterType}${filterStartDate}${filterFormateur}${filterTheme}page=${page + 1}`)
        if (fetchInterventions && fetchInterventions.items) {
            setItems(items.concat(fetchInterventions.items))
            setFormations(fetchInterventions)
            setPage(fetchInterventions.page)
        }
        setSpinner(false)
    };

    return (
        <Zoom in={zoom}>
            <div
                style={{
                    overflow: 'auto',
                    width: '100%',
                    paddingLeft: 16,
                    paddingRight: 16,
                    marginTop: 30,
                }}
                ref={target}
            >
                {formations && <BottomOfPage target={target} func={enterHandler} />}
                <div style={{ marginBottom: 50 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={8}>
                            <Paper sx={{ maxWidth: 930, borderRadius: 5, boxShadow: '-1px 4px 10px #CCCCCC', padding: '20px 30px' }}>
                                <Typography variant='h6' color='primary' sx={{ mb: 2 }}>
                                    Rechercher une formation :
                                </Typography>
                                <Box sx={{  }}>
                                    {profil.entreprises && profil.entreprises.length > 1 && (
                                        <FormControl variant="standard" fullWidth sx={{ mr: 3, width: 150 }}>
                                            <InputLabel id="multisite-select-label">Entité</InputLabel>
                                            <Select
                                                labelId="multisite-select-label"
                                                id="multisite-select"
                                                value={site}
                                                label="Entité"
                                                onChange={handleChangeSite}
                                            >
                                                <MenuItem value="">
                                                    <em>Toutes les entités</em>
                                                </MenuItem>
                                                {profil.entreprises.map((site) => (
                                                    <MenuItem key={site.id} value={site.id}>{site.nom}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    )}
                                    {themes && (
                                        <FormControl variant="standard" fullWidth sx={{ mr: 3, width: 150 }}>
                                            <InputLabel id="theme-select-label">Thème</InputLabel>
                                            <Select
                                                labelId="theme-select-label"
                                                id="theme-select"
                                                value={theme}
                                                label="Thème"
                                                onChange={handleChangeTheme}
                                            >
                                                <MenuItem value="">
                                                    <em>Tous les thèmes</em>
                                                </MenuItem>
                                                {themes.map((type) => (
                                                    <MenuItem key={type.value} value={type.value}>{type.label}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    )}
                                    {types && (
                                        <FormControl variant="standard" fullWidth sx={{ mr: 3, width: 150 }}>
                                            <InputLabel id="type-select-label">Type</InputLabel>
                                            <Select
                                                labelId="type-select-label"
                                                id="type-select"
                                                value={type}
                                                label="Type"
                                                onChange={handleChangeType}
                                            >
                                                <MenuItem value="">
                                                    <em>Tous les types</em>
                                                </MenuItem>
                                                {types.map((type) => (
                                                    <MenuItem key={type.value} value={type.value}>{type.label}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    )}
                                    <DatePickerComponent text={'Date de début'} selectedDate={startDate} handleDateChange={handleChangeStartDate} />
                                    {formateurs && (
                                        <FormControl variant="standard" fullWidth sx={{ mr: 3, width: 150 }}>
                                            <InputLabel id="type-select-label">Formateur</InputLabel>
                                            <Select
                                                labelId="type-select-label"
                                                id="type-select"
                                                value={formateur}
                                                label="Formateur"
                                                onChange={handleChangeFormateur}
                                            >
                                                <MenuItem value="">
                                                    <em>Sélectionner un formateur</em>
                                                </MenuItem>
                                                {formateurs.map((type) => (
                                                    <MenuItem key={type.value} value={type.value}>{type.label}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    )}
                                </Box>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Box
                                sx={{
                                    position: 'relative',
                                }}
                            >
                                <Button
                                    sx={{
                                        border: '5px solid #ACCDD8',
                                        borderRadius: 2,
                                        height: 70,
                                        width: '100%',
                                        maxWidth: 370,
                                        fontWeight: 'bold',
                                        position: 'absolute',
                                        right: 0,
                                        top: 70,
                                    }}
                                    onClick={handleOpenContactPopup}
                                >
                                    Contacter le service formation
                                </Button>
                                <ContactPopup
                                    open={openContactPopup}
                                    handleClose={() => setOpenContactPopup(false)}
                                    title={'formation'}
                                    text='Notre service formation est joignable au 02 97 84 32 57.'
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </div>
                {(items && !loading) ? items.map((formation, index) => {
                    return <Zoom
                        in={formationZoom}
                        timeout={700}
                        key={index}
                        style={{ 
                            transitionDelay: page > 1 ? '0ms' : index * 100 + 200 + 'ms',
                            overflow:'hidden'
                        }}
                    >
                        <Paper sx={{mt: 5, pl: 3, pt: 3, borderRadius: 7, boxShadow: '-1px 4px 10px #CCCCCC'}}>
                            <Grid container spacing={3} sx={{pb: 4, pt: 4}}>
                                <Grid item xs={12} sm={3}>
                                    <Grid container spacing={3} alignItems="flex-start">
                                        <Grid item xs={12} sm={4} sx={{ mt: 1, display: 'flex', justifyContent: 'center' }}>
                                            <Avatar
                                                alt={
                                                    formation.formateur &&
                                                    formation.formateur.photo &&
                                                    formation.formateur.photo.nom
                                                }
                                                src={
                                                    formation.formateur && 
                                                    formation.formateur.photo &&
                                                    formation.formateur.photo.url
                                                }
                                                sx={{width: 80, height: 80}}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} sx={{ mt: 2, pl: '25px !important', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                            <Typography variant='h6' color='primary' sx={{fontSize: 17, fontWeight: 'bold'}}>
                                                Intervenant
                                            </Typography>
                                            <Typography variant='body2' color='primary' sx={{fontSize: 14}}>
                                                {formation.formateur && 
                                                `${formation.formateur.prenom} ${formation.formateur.nom}`}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={1} sx={{borderRight: '5px solid #CECECE', height: 150}}>
                                            
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={5}>
                                    <Grid container spacing={3} sx={{height: 120}}>
                                        <Grid item xs={12} sm={6} sx={{paddingTop: '0px !important'}}>
                                            <Typography color='primary' sx={{fontSize: 14, mb: 2}}>
                                                <span style={{fontWeight: 'bold'}}>Site : </span>{formation.entreprise && formation.entreprise.nom}
                                            </Typography>
                                            <Typography color='primary' sx={{fontSize: 14, mb: 2}}>
                                                <span style={{fontWeight: 'bold'}}>Thème : </span>{formation.theme}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={6} sx={{borderRight: '5px solid #CECECE', height: 150, paddingTop: '0px !important'}}>
                                            <Typography color='primary' sx={{fontSize: 14, mb: 2}}>
                                                <span style={{fontWeight: 'bold'}}>Début : </span>{moment(formation.debut).format('DD MMMM YYYY')}
                                            </Typography>
                                            <div style={{display: 'flex', marginBottom: 16}}>
                                                <img src={duree} width={30} alt="durée formation" />
                                                <Typography color='primary' sx={{fontSize: 14, ml: 2, mt: 1}}>{formation.duree}</Typography>
                                            </div>
                                        </Grid>
                                    </Grid>
                                    <div style={{display: 'flex', marginBottom: 16}}>
                                        <img src={adresse} width={30} alt="adresse formation" />
                                        <Typography color='primary' sx={{fontSize: 14, ml: 2, mt: 1}}>
                                        {formation.adresse.nom === 'Formation à distance' ? 'Distanciel' : `${formation.adresse.adresse}, ${formation.adresse.code_postal}, ${formation.adresse.ville}`}
                                        </Typography>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={4} sx={{paddingTop: '0px !important'}}>
                                    <div>
                                        <Typography
                                            color='primary'
                                            variant='h6'
                                            sx={{
                                                fontSize: 15,
                                                fontWeight: 'bold'
                                            }}
                                        >
                                            Documents
                                        </Typography>
                                        <Grid
                                            container
                                            spacing={3}
                                            style={{
                                                borderTop: '3px solid #FFBDA6',
                                                borderBottom: '3px solid #FFBDA6',
                                                marginTop: 7,
                                                padding: '8px 0px',
                                                width: 405,
                                                marginLeft: 0
                                            }}
                                        >
                                            <Grid item xs={12} sm={6} sx={{paddingTop: '0px !important', paddingLeft: '0px !important'}}>
                                                <Typography
                                                    color='primary'
                                                    variant='body1'
                                                    sx={{fontSize: 14, mb: 1.5}}
                                                >
                                                    Convention de formation
                                                </Typography>
                                                <Typography
                                                    color='primary'
                                                    variant='body1'
                                                    sx={{fontSize: 14, mb: 1.5}}
                                                >
                                                    Emargement
                                                </Typography>
                                                <Typography
                                                    color='primary'
                                                    variant='body1'
                                                    sx={{fontSize: 14}}
                                                >
                                                    Evaluations de fin de stage
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={6} sx={{paddingTop: '0px !important'}}>
                                                <Typography
                                                    variant='body1'
                                                    sx={{cursor: 'pointer',
                                                    fontSize: 14,
                                                    color: formation.convention ? '#75ADBE' : '#989C9F',
                                                    mb: 1.5,
                                                }}
                                                onClick={() => handleDownloadDocument(formation.convention, {convention: true})}
                                                    
                                                >
                                                    {downloading.convention && formation.convention ? <CircularProgress color="primary" size={20} /> : (formation.convention ? 
                                                        <StyledTooltip title={`Télécharger le document de convention de la formation`}>
                                                            <span>
                                                                Disponible
                                                            </span>
                                                        </StyledTooltip> : 'Bientôt disponible'
                                                    )}
                                                        
                                                </Typography>
                                                <Typography
                                                    variant='body1'
                                                    sx={{cursor: 'pointer',
                                                    fontSize: 14,
                                                    color: formation.emargement ? '#75ADBE' : '#989C9F',
                                                    mb: 1.5,
                                                }}
                                                    onClick={() => handleDownloadDocument(formation.emargement, {emargement: true})}
                                                >
                                                    {downloading.emargement && formation.emargement? <CircularProgress color="primary" size={20} /> : (formation.emargement ? 
                                                        <StyledTooltip title={`Télécharger le document d'émargement`}>
                                                            <span>
                                                                Disponible
                                                            </span>
                                                        </StyledTooltip> : 'Bientôt disponible'
                                                    )}
                                                </Typography>
                                                <Typography
                                                    variant='body1'
                                                    sx={{cursor: 'pointer',
                                                    fontSize: 14,
                                                    color: formation.evaluations ? '#75ADBE' : '#989C9F',
                                                    mb: 1.5,
                                                }}
                                                    onClick={() => handleDownloadDocument(formation.evaluations, {evaluation: true})}
                                                >
                                                    {downloading.evaluation && formation.evaluations ?  <CircularProgress color="primary" size={20} /> : (formation.evaluations ? 
                                                        <StyledTooltip title={`Télécharger le document d'évaluations de fin de stage`}>
                                                            <span>
                                                                Disponible
                                                            </span>
                                                        </StyledTooltip> : 'Bientôt disponible'
                                                    )}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Zoom>
                }) : 
                <div style={{textAlign: 'center', overflow: 'hidden', marginTop: 200}}>
                    <CircularProgress />
                </div>}
                {spinner && <div style={{textAlign: 'center', overflow: 'hidden', marginTop: 20}}><CircularProgress color={'primary'} /></div>}
                {!loading && <div style={{textAlign: 'right', marginTop: 20}}>
                    <Typography>Total de formations : {formations.total}</Typography>
                </div>}
            </div>
        </Zoom>
    )
}