import { Navigate } from 'react-router-dom'
import { getAuth } from './Authentifier'

export const RequireAuth = ({ children }) => {

    if (!getAuth()) {
        return <Navigate to='/login' />
    }
    
    return children
}