import React from 'react';
import { 
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Slide,
    Typography
} from '@mui/material';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function VideoPopup({ open, handleClose, contact }) {

  return (
        <Dialog
            open={!!open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-labelledby="video-popup-title"
            aria-describedby="video-popup-description"
        >
            <DialogTitle id="video-popup-title">Demander une vidéo personnalisée</DialogTitle>
            <DialogContent>
                <Typography variant='body1' color='primary'>
                    Pour demander une vidéo personnalisée/ sur-mesure,
                    veuillez joindre votre contact dédié :
                    {contact.prenom} {contact.nom} au {contact.numero_de_telephone && contact.numero_de_telephone.match(/.{2}/g).join(' ')}
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>
                    Fermer
                </Button>
            </DialogActions>
        </Dialog>
  );
}