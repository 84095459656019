import React from 'react'
import logoPc from '../../assets/logoPcDrh.png'
import { 
    List,
    Divider,
    ListItem,
    ListItemButton,
    ListItemText,
} from '@mui/material/';
import { useNavigate } from 'react-router-dom';

let temporaryRoute = null
export default function Navigation({ setPage }) {
    let navigate = useNavigate()

    const firstList = [
        {path: 'tableau-de-bord', item: 'Tableau de bord'},
        {path: "ligne-d-ecoute", item: 'Ligne d\'écoute'},
        {path: 'interventions',item: 'Intervention sur site'},
        {path: 'formations',item: 'Formation'},
    ]

    const secondList = [
        {path: 'conseils-prevention', item: 'Conseils prévention'},
        {path: 'mon-contrat', item: 'Mon Contrat'},
    ]

    const handleNavigation = (e) => {
        temporaryRoute = e.currentTarget.title
        setPage(temporaryRoute)
        setTimeout(() => {
            navigate(`/${temporaryRoute}`)
            temporaryRoute = null
        }, 50)
    }

    const findActive = (text) => {
        if (window.location.pathname.slice(1).split('/')[0] === text.path) {
            return true
        }
        return false
    }

    return (
        <>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    overflowX: 'none',
                    padding: 5,
                }}
            >
                <div>
                    <img src={logoPc} alt='logo' style={{width: 215, marginTop: 30, marginBottom: 20}} />
                </div>
            </div>
            <Divider />
            <List style={{overflowX: 'hidden', paddingLeft: 50}}>
                {firstList.map((text, index) => (
                    <ListItem
                        sx={{
                            textAlign: 'center', 
                            color: findActive(text) ? '#FFBDA6' : 'white'
                        }}
                        id='tab'
                        key={text.path}
                        title={text.path}
                        onClick={handleNavigation}
                    >
                        <ListItemButton>
                            <ListItemText primary={text.item} sx={{'& .MuiTypography-root': {fontWeight: 'bold'}}} />
                        </ListItemButton>
                    </ListItem>
                ))}
                <Divider />
                {secondList.map((text, index) => (
                    <ListItem
                        sx={{
                            textAlign: 'center', 
                            color: findActive(text) ? '#FFBDA6' : 'white'
                        }}
                        id='tab'
                        key={text.path}
                        title={text.path}
                        onClick={handleNavigation}
                    >
                        <ListItemButton>
                            <ListItemText primary={text.item} sx={{'& .MuiTypography-root': {fontWeight: 'bold'}}} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </>
    )
}
