import * as React from 'react';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import { findPage } from '../../helpers/utils';
import { Toolbar, Box, Popper, Paper, ClickAwayListener, MenuList, Grow } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import PersonIcon from '@mui/icons-material/Person';
import { useNavigate } from 'react-router-dom';

export default function TopToolbar({ page, profil }) {
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);
    let navigate = useNavigate();

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (event && anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const handleDisconnect = () => {
        localStorage.removeItem('AUTH_TOKEN');
        localStorage.removeItem('SWITCH_USER');
        navigate('/login');
    };

    const navigateMonCompte = () => {
        navigate('/mon-compte');
        handleClose();
    };

    return (
        <Toolbar sx={{ paddingLeft: '15px !important', display: 'flex', justifyContent: 'space-between' }}>
            <Box>
                <div>{findPage(page)}</div>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Button
                    id="basic-button"
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    variant='contained'
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleToggle}
                    startIcon={<KeyboardArrowDownIcon />}
                    endIcon={<PersonIcon color="secondary" />}
                    ref={anchorRef}
                >
                    {profil.pseudo && profil.pseudo.replace(/\s/g, '')}
                </Button>
                <Popper
                    open={open}
                    anchorEl={anchorRef.current}
                    role={undefined}
                    transition
                    disablePortal
                    placement="right-end"
                    sx={{zIndex: 99999999999}}
                >
                    {({ TransitionProps }) => (
                        <Grow
                            {...TransitionProps}
                            style={{
                                transformOrigin: 'right-end',
                            }}
                        >
                            <Paper>
                                <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList autoFocusItem={open} id="basic-menu">
                                        <MenuItem onClick={navigateMonCompte}>Mon Compte</MenuItem>
                                        <MenuItem onClick={handleDisconnect}>Deconnexion</MenuItem>
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </Box>
        </Toolbar>
    );
}