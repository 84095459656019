import React, { useEffect, useState } from 'react'
import { CircularProgress, Grid, IconButton, InputAdornment, Paper, Switch, TextField, Typography, Zoom, useMediaQuery } from '@mui/material'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { TransitionLeft, progressBarWidth } from '../helpers/utils'
import { apiFetch } from '../security/apiFetch'
import ProgressBar from '../components/ProgressBar'
import { LoadingButton } from '@mui/lab'
import ReactGA from 'react-ga';

export default function Profil({ profil, setText, setSeverity, setTransition, setOpenSnackbar }) {
    const [error, setError] = useState({})
    const [showNewPassword, setShowNewPassword] = useState(false)
    const [disable, setDisable] = useState(false)
    const [showConfirmPassword, setShowConfirmPassword] = useState(false)
    const [style, setStyle] = useState(false)
    const [loading, setLoading] = useState(false)
    const [checkedRapport, setCheckedRapport] = useState(false);
    const [checkedConsult, setCheckedConsult] = useState(false);
    const [checkedActu, setCheckedActu] = useState(false);
    const matches = useMediaQuery('(max-width:1548px)');

    useEffect(() => {
        if (profil) {
            setCheckedActu(profil.notification_actualite_prevention_activee)
            setCheckedConsult(profil.notification_nouvelle_consultation_activee)
            setCheckedRapport(profil.notification_nouveau_bilan_prestation_activee)
        }
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, [profil])

    const handleChangeRapport = async (event) => {
        setCheckedRapport(event.target.checked);
        setDisable(true)
        const changeRapport = await apiFetch('/mon-compte/toggle-notification-nouveau-bilan-prestation', {method: 'PUT'})
        if (typeof changeRapport === 'object' && changeRapport !== null) {
            setText('Votre préférence pour les bilans à été modifié ')
            setSeverity('success')
            setTransition(() => TransitionLeft)
            setOpenSnackbar(true)
        } else {
            setCheckedRapport(!event.target.checked);
            setText('Une erreur est survenue lors de la modification ')
            setSeverity('success')
            setTransition(() => TransitionLeft)
            setOpenSnackbar(true)
        }
        setDisable(false)
    };

    const handleChangeConsult = async (event) => {
        setCheckedConsult(event.target.checked);
        setDisable(true)
        const changeConsult = await apiFetch('/mon-compte/toggle-notification-nouvelle-consultation', {method: 'PUT'})
        if (typeof changeConsult === 'object' && changeConsult !== null) {
            setText('Votre préférence pour les consultations à été modifié ')
            setSeverity('success')
            setTransition(() => TransitionLeft)
            setOpenSnackbar(true)
        } else {
            setCheckedConsult(!event.target.checked);
            setText('Une erreur est survenue lors de la modification ')
            setSeverity('success')
            setTransition(() => TransitionLeft)
            setOpenSnackbar(true)
        }
        setDisable(false)
    };

    const handleChangeActu = async (event) => {
        setCheckedActu(event.target.checked);
        setDisable(true)
        const changeActu = await apiFetch('/mon-compte/toggle-notification-actualite-prevention', {method: 'PUT'})
        if (typeof changeActu === 'object' && changeActu !== null) {
            setText('Votre préférence pour l\'actualités prévention à été modifié ')
            setSeverity('success')
            setTransition(() => TransitionLeft)
            setOpenSnackbar(true)
        } else {
            setCheckedActu(!event.target.checked);
            setText('Une erreur est survenue lors de la modification ')
            setSeverity('success')
            setTransition(() => TransitionLeft)
            setOpenSnackbar(true)
        }
        setDisable(false)
    };

    const handleClickNewPassword = () => {
        setShowNewPassword(!showNewPassword)
    }

    const handleClickConfirmNewPassword = () => {
        setShowConfirmPassword(!showConfirmPassword)
    }

    const handleChangeProgressBar = (e) => {
        progressBarWidth(e, setStyle)
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)
        setError({})

        const data = new FormData(e.target)
        const password = data.get('password')
        const confirmNewPassword = data.get('confirmNewPassword')

        if (!password) {
            setError({...error, password: 'Veuillez renseigner ce champ'})
            setLoading(false)
            return
        }

        if (password !== confirmNewPassword) {
            setLoading(false)
            return setError({confirmNewPassword: 'Ce mot de passe n\'est pas identique au nouveau mot de passe'})
        }

        const dataObject = {
            password: {
                first: password,
                second: confirmNewPassword,
            }
        }  

        const changePassword = await apiFetch(`/mon-compte/changer-mon-mot-de-passe`, {
            method: 'PUT',
            body: JSON.stringify(dataObject)
        })

        if (changePassword !== 'success') {
            setText(changePassword[0] || (changePassword.password && changePassword.password.first))
            setSeverity('error')
            setTransition(() => TransitionLeft)
            setOpenSnackbar(true)
        } else {
            setText('Le mot de passe a bien été modifié !')
            setSeverity('success')
            setTransition(() => TransitionLeft)
            setOpenSnackbar(true)
        }
        setLoading(false)
    }   

    return (
        <div>
            {profil ? <div>
                <div style={{display: 'flex',flexDirection: matches ? 'column' : 'row' , marginTop: 30}}>
                        <Zoom in={true} timeout={700} style={{ transitionDelay: '500ms' }}>
                            <Paper
                                sx={{
                                    borderRadius: 5,
                                    boxShadow: '-1px 4px 10px #CCCCCC',
                                    padding: '20px 30px',
                                    mr: matches ? 0 : 4,
                                    mb: matches ? 4 : 0,
                                    height: 260
                                }}
                            >
                                <Typography color='primary' sx={{fontWeight: 'bold'}} variant='h6'>Coordonnées</Typography>
                                <Typography variant='body1' sx={{mt: 2}}>{profil.prenom} {profil.nom}</Typography>
                                <Typography variant='body1'>{profil.numero_de_telephone && profil.numero_de_telephone.match(/.{2}/g).join(' ')}</Typography>
                                <Typography variant='body1'>{profil.email}</Typography>
                                <Typography variant='body2' sx={{mt: 2}}>
                                    Merci de joindre votre contact Pros-Consulte
                                </Typography>
                                <Typography variant='body2' sx={{mt: 0}}>
                                    si ces informations sont erronées
                                </Typography>
                            </Paper>
                        </Zoom>
                        <Zoom in={true} timeout={700} style={{ transitionDelay: '1000ms' }}>
                            <Paper
                                sx={{
                                    borderRadius: 5,
                                    boxShadow: '-1px 4px 10px #CCCCCC',
                                    padding: '20px 30px',
                                    mr: matches ? 0 : 8,
                                    mb: matches ? 8 : 16,
                                    height: 260
                                }}
                            >
                                <Typography color='primary' sx={{fontWeight: 'bold'}} variant='h6'>Mot de passe</Typography>
                                <form noValidate onSubmit={handleSubmit} autoComplete="off">
                                    <Grid container spacing={3} sx={{maxWidth: 700}}>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                id="password" 
                                                name="password" 
                                                label="Nouveau mot de passe"
                                                sx={{mt: 1, width: 320}}
                                                type={showNewPassword ? "text" : "password"}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                onClick={handleClickNewPassword}
                                                            >
                                                                {showNewPassword ? <Visibility /> : <VisibilityOff />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    )
                                                }}
                                                required
                                                autoComplete="on"
                                                onChange={handleChangeProgressBar}
                                                error={!!error.password} helperText={error.password}
                                            />
                                            <ProgressBar style={style} />
                                            <TextField 
                                                id="confirmNewPassword" 
                                                name="confirmNewPassword" 
                                                label="Confirmer le mot de passe"
                                                type={showConfirmPassword ? "text" : "password"}
                                                sx={{mt: 1, width: 320 }}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                onClick={handleClickConfirmNewPassword}
                                                            >
                                                                {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    )
                                                }}
                                                required
                                                autoComplete="on"
                                                error={!!error.confirmNewPassword}
                                                helperText={error.confirmNewPassword}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                        <Typography variant='body2' sx={{mt: 1, ml: 8}}>
                                            Pour être valide, votre mot de passe doit comporter au moins 8 caractères et 
                                            3 types de caractères différents : minuscules, majuscules, chiffres,...
                                        </Typography>
                                        </Grid>
                                    </Grid>
                                    <LoadingButton
                                            type='submit'
                                            loading={loading}
                                            color={'secondary'}
                                            variant="contained"
                                            sx={{mt: 1, mb:1}}
                                        >
                                            Modifier le mot de passe
                                    </LoadingButton>
                                </form>
                            </Paper>
                        </Zoom>
                    <Zoom in={true} timeout={700} style={{ transitionDelay: '700ms' }}>
                        <Paper
                            sx={{
                                borderRadius: 5,
                                boxShadow: '-1px 4px 10px #CCCCCC',
                                padding: '20px 30px',
                                mr: matches ? 0 : 4,
                                mt:  0,
                                mb: matches ? 16 : 0,
                            }}
                        >
                            <Typography color='primary' sx={{fontWeight: 'bold'}} variant='h6'>Préférences</Typography>
                            <Grid container spacing={3}>
                                <Grid item xs={10} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Typography variant='body1'>Alerte mail bilans</Typography>
                                </Grid>
                                <Grid item xs={2} sx={{textAlign: 'right'}}>
                                    <Switch
                                        checked={checkedRapport}
                                        onChange={handleChangeRapport}
                                        inputProps={{ 'aria-label': 'alertRapport' }}
                                        sx={{mt: -1}}
                                        disabled={disable}
                                    />
                                </Grid>
                            </Grid>
                            <Typography variant='caption'>
                                 Recevoir un mail lorsqu'un nouveau bilan de prestation est disponible
                            </Typography>
                            <Grid container spacing={3} sx={{mt: 2}}>
                                <Grid item xs={10} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Typography variant='body1'>Alertes mail consultations</Typography>
                                </Grid>
                                <Grid item xs={2} sx={{textAlign: 'right'}}>
                                    <Switch
                                        checked={checkedConsult}
                                        onChange={handleChangeConsult}
                                        inputProps={{ 'aria-label': 'alertConsult' }}
                                        sx={{mt: -1}}
                                        disabled={disable}
                                    />
                                </Grid>
                            </Grid>
                            <Typography variant='caption'>
                                Recevoir un mail à chaque nouvelle consultation sur la ligne d'écoute
                            </Typography>
                            <Grid container spacing={3} sx={{mt: 2}}>
                                <Grid item xs={10} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Typography variant='body1'>Alertes mail actualités prévention</Typography>
                                </Grid>
                                <Grid item xs={2} sx={{textAlign: 'right'}}>
                                    <Switch
                                        checked={checkedActu}
                                        onChange={handleChangeActu}
                                        inputProps={{ 'aria-label': 'alertActu' }}
                                        sx={{mt: -1}}
                                        disabled={disable}
                                    />
                                </Grid>
                            </Grid>
                            <Typography variant='caption'>
                                Recevoir un mail lorsqu'une nouvelle actualité prévention est parue (une fois par mois)
                            </Typography>
                        </Paper>
                    </Zoom>
                </div>
                {profil.entreprises && profil.entreprises.length > 1 && <Zoom in={true} timeout={700} style={{ transitionDelay: '300ms' }}>
                    <Paper
                        sx={{
                            maxWidth: 950,
                            borderRadius: 5,
                            boxShadow: '-1px 4px 10px #CCCCCC',
                            padding: '20px 30px',
                            mt: -11,
                        }}
                    >
                        <Typography color='primary' sx={{fontWeight: 'bold', borderBottom: '2px solid #75ADBE', paddingBottom: 3}} variant='h6'>
                            Mes sites
                        </Typography>
                        <Grid container spacing={3} sx={{mt: 3}}>
                            {profil.entreprises && profil.entreprises.map((entreprise) => {
                                return (
                                    <Grid item xs={12} sm={6} key={entreprise.id}>
                                        <Typography variant='body1'>
                                            {entreprise.nom}
                                        </Typography>
                                        {entreprise.adresse && <Typography variant='caption'>
                                        {entreprise.adresse.adresse}, {entreprise.adresse.code_postale} {entreprise.adresse.ville}
                                        </Typography>}
                                    </Grid>
                                )
                            })}
                        </Grid>
                    </Paper>
                </Zoom>}
            </div> : 
            <div style={{textAlign: 'center', overflow: 'hidden', marginTop: 200}}>
                <CircularProgress />
            </div>}
        </div>
    )
}