import React from 'react';
import { 
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Slide,
    Typography
} from '@mui/material';
import { findIcon } from '../../helpers/utils';
import moment from 'moment';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function ConsultationPopup({ open, handleClose }) {

  return (
        <Dialog
            open={!!open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-labelledby="consultation-popup-title"
            aria-describedby="consultation-popup-description"
            maxWidth={'md'}
            fullWidth={!!open}
            sx={{'& .MuiPaper-root': {borderRadius: '32px'}}}
        >
            <DialogTitle id="consultation-popup-title" color='primary' sx={{fontWeight: 'bold'}}>Consultation</DialogTitle>
            <DialogContent>
                <Grid container spacing={6}>
                    <Grid item xs={12} sm={6}>
                        {open.entreprise && <Typography variant='body1' color='primary' sx={{float: 'right', fontWeight: 'bold'}}>
                            Entreprise : 
                        </Typography>}<br />
                        <Typography variant='body1' color='primary' sx={{float: 'right', fontWeight: 'bold'}}>Type de consultation : </Typography><br />
                        <Typography variant='body1' color='primary' sx={{float: 'right', fontWeight: 'bold'}}>Date : </Typography><br />
                        <Typography variant='body1' color='primary' sx={{float: 'right', fontWeight: 'bold'}}>Durée : </Typography><br />
                        <Typography variant='body1' color='primary' sx={{float: 'right', fontWeight: 'bold'}}>Motif : </Typography><br />
                        <Typography variant='body1' color='primary' sx={{float: 'right', fontWeight: 'bold'}}>Avis : </Typography><br />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        {open.entreprise && <Typography variant='body1' color='primary'>{open.entreprise && open.entreprise.nom}</Typography>}
                        <Typography variant='body1' color='primary'>{open.type && findIcon(open.type.valeur)}</Typography>
                        <Typography variant='body1' color='primary'>{moment(open.debut).format('DD MMMM YYYY')}</Typography>
                        <Typography variant='body1' color='primary'>{open.duree}</Typography>
                        <Typography variant='body1' color='primary'>{open.motif}</Typography>
                        <Typography variant='body1' color='primary'>{open.avis || 'NC'}</Typography>
                    </Grid>
                </Grid>
                {open.proprietes && open.proprietes.map((propriete, i) => {
                    return <Grid container spacing={6} key={i}>
                        <Grid item xs={12} sm={6}>
                            <Typography variant='body1' color='primary' sx={{float: 'right', fontWeight: 'bold'}}>{propriete.label} :</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography variant='body1' color='primary'>{propriete.valeur}</Typography>
                        </Grid>
                    </Grid>
                })}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>
                    Fermer
                </Button>
            </DialogActions>
        </Dialog>
  );
}