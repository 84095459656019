import React, { useEffect, useState } from 'react'

export const BottomOfPage = ({ target, func }) => {
    const [isWaiting, setIsWaiting] = useState(false);
    const scrollListener = async () => {
        if (!target.current) {
            return;
        }
      
        const element = target.current;
        const totalHeight = element.clientHeight - element.offsetTop - (window.innerHeight);
        const windowScrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

        if ((windowScrollTop > totalHeight - 100) && !isWaiting ) {
            setIsWaiting(true)
            await func()
            setIsWaiting(false)
        }

    };
    
    useEffect(() => {
        window.addEventListener("scroll", scrollListener);
        return () => window.removeEventListener("scroll", scrollListener);
    });
  
    return (
    <></>
    );
};