import { getAuth, getSwitchUser, logout } from "./Authentifier"

export async function apiFetch(endpoint, options = {}, isLogin = false, forceResponse) {
    const user = getAuth()
    const token = user && user.token
    let response = null

    const classicHeader = isLogin ? {
        headers: {
            'Content-Type': 'application/json',
        },
        ...options
    } : {
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : 'Bearer ' + token,
        },
        ...options
    }

    if (getSwitchUser()) classicHeader.headers['X-Switch-User'] = getSwitchUser()

    try {
        response = await fetch(process.env.REACT_APP_BASE_URL + endpoint, classicHeader)
    } catch (e) {
        console.warn(e)
        return logout()
    }

    
    let responseData = null
    if (response.status === 404) return '404'
    if (response) {
        responseData = response.status !== 403 && response.status !== 204 && !forceResponse ? await response.json() : response
        if (responseData.message === "Expired JWT Token") {
            response = await handleRefreshToken(user, endpoint, options)
            responseData = response.status !== 403 && response.status !== 204 && !forceResponse ? await response.json() : response
        }
    } else {
        return logout()
    }

    return responseData
}

async function handleRefreshToken(user, endpoint, options = {}) {
    const data = { refresh_token: `${user.refresh_token}` }
    const refreshOptions = {
        headers: {
            'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify(data)
    }
    const refreshNeeded = await fetch (process.env.REACT_APP_BASE_URL + '/token/refresh', refreshOptions)

    if (refreshNeeded.ok) {
        const refreshNeededData = await refreshNeeded.json()
        localStorage.setItem('AUTH_TOKEN', JSON.stringify(refreshNeededData))
        const OptionsAfterRefresh = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization' : 'Bearer ' + refreshNeededData.token,
            },
            ...options
        }
        return await fetch(process.env.REACT_APP_BASE_URL + endpoint, OptionsAfterRefresh)
    } else {
        return logout()
    }
}
