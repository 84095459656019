import React from 'react';
import { 
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Slide,
    Typography
} from '@mui/material';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ContactPopup({ open, handleClose, data }) {

  return (
        <Dialog
            open={!!open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-labelledby="contact-popup-title"
            aria-describedby="contact-popup-description"
        >
            <DialogTitle id="contact-popup-title" color='primary' sx={{fontWeight: 'bold', textAlign: 'center'}}>
                {data ? 'Votre contact dédié' : 'Pour en savoir plus, veuillez contacter le service commercial'}
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={3}>
                    <Grid item xs={2}>
                        <span style={{fontWeight: 'bold', color: '#01304A'}}>Numéro : </span>
                    </Grid>
                    <Grid item xs={10}>
                        <Typography variant='body1' color='primary'>
                            {data ? data.numero_de_telephone && data.numero_de_telephone.match(/.{2}/g).join(' ') : 
                            '02 97 84 32 53'}
                        </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <span style={{fontWeight: 'bold', color: '#01304A'}}>Mail : </span>
                    </Grid>
                    <Grid item xs={10}>
                        <Typography variant='body1' color='primary'>
                            {data ? data.email : 'contact@prosconsultegroup.com'}
                        </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <span style={{fontWeight: 'bold', color: '#01304A'}}>Horaire : </span>
                    </Grid>
                    <Grid item xs={10}>
                        <Typography variant='body1' color='primary'>
                            Bureaux ouverts du lundi au jeudi 9h-12h / 13h30-17h. Vendredi 9h-12h/13h30-16h30.
                        </Typography>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>
                    Fermer
                </Button>
            </DialogActions>
        </Dialog>
  );
}