import {
    Button,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Tooltip,
    Typography,
    Zoom,
    styled,
    tooltipClasses,
    CircularProgress,
    
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { apiFetch } from '../security/apiFetch'
import { TransitionLeft, capitalizeFirstLetter, download } from '../helpers/utils'
import { DatePickerComponent } from '../components/DatePicker'
import RapportsTable from './PlateformeItems/RapportsTable'
import ConsultationsTable from './PlateformeItems/ConsultationsTable'
import moment from 'moment'
import ContactPopup from './InterventionsItems/ContactPopup'
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import plateformeLogo from '../assets/COm_VIS_20240205_pictos_BO-DRH_telephone.png'
import communiquerLogo from '../assets/COm_VIS_20240205_pictos_BO-DRH_communiquer.png'
import analyserLogo from '../assets/COm_VIS_20240205_pictos_BO-DRH_analyse.png'
import VideoPopup from './PlateformeItems/VideoPopup'
import videoImg from '../assets/video_acceuil.png'
import VideoPlayer from './DocumentsItems/VideoPlayer'
import ReactGA from 'react-ga';

const ColorButton = styled(Button)(({ theme }) => ({
    color: '#01304A',
    backgroundColor: 'white',
    '&:hover': {
      backgroundColor: '#FEFEFE',
    },
}));

const StyledTooltip = styled(({ className, ...props }) => (
    <Tooltip placement="left" {...props} arrow TransitionComponent={Zoom} classes={{ popper: className }} />
))(({theme}) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.primary.main,
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.primary.main,
    },
}));

export default function Plateforme({ profil, setText, setSeverity, setOpenSnackbar, setTransition }) {
    const [consultations, setConsultations] = useState(false)
    const [rapports, setRapports] = useState(false)
    const [rapportsItems, setRapportsItems] = useState(false)
    const [consultationsItems, setConsultationsItems] = useState(false)
    const [rapportsPage, setRapportsPage] = useState(false)
    const [consultationsPage, setConsultationsPage] = useState(false)
    const [contact, setContact] = useState(false)
    const [site, setSite] = useState('');
    const [selectedSiteObject, setSelectedSiteObject] = useState(false);
    const [consultationStartDate, setConsultationStartDate] = useState(null)
    const [showTable, setShowTalbe] = useState(false)
    const [loadingRap, setLoadingRap] = useState(false)
    const [loadingCons, setLoadingCons] = useState(false)
    const [openContactPopup, setOpenContactPopup] = useState(false)
    const [openVideoPopup, setOpenVideoPopup] = useState(false)
    const [communications, setCommunications] = useState(false)
    const [downloading, setDownloading] = useState(false)
    const [consultationEndDate, setConsultationEndDate] = useState(null)
    const [rapportStartDate, setRapportStartDate] = useState(null)
    const [rapportEndDate, setRapportEndDate] = useState(null)
    const [isPlaying, setIsPlaying] = useState(false)
    

    document.title = 'Espace RH - Ligne d\'écoute'

    useEffect(() => {
        (async () => {
            
            if (profil && profil.entreprises && profil.entreprises.length === 1) {
                setSelectedSiteObject(profil.entreprises[0])
                const getCom = await apiFetch(`/communication/elements-de-communication?entreprise_id=${profil.entreprises[0].id}`)
                if (getCom && getCom.length) {
                    setCommunications(getCom)
                } else if (getCom && getCom.length === 0) {
                    setCommunications([]);
                } else {
                    setText('Erreur lors de la récupération des supports de communication !')
                    setSeverity('error')
                    setTransition(() => TransitionLeft)
                    setOpenSnackbar(true)
                }
            }

            const getConsultations = await apiFetch('/plateforme/consultations?limit=5')
            if (getConsultations && getConsultations.items) {
                setConsultations(getConsultations)
                setConsultationsItems(getConsultations.items)
                setConsultationsPage(getConsultations.page)
                setShowTalbe(true)
            } else {
                setText('Erreur lors de la récupération des consultations !')
                setSeverity('error')
                setTransition(() => TransitionLeft)
                setOpenSnackbar(true)
            }

            const getRapports = await apiFetch('/plateforme/rapports?limit=5')
            if (getRapports && getRapports.items) {
                setRapports(getRapports)
                setRapportsItems(getRapports.items)
                setRapportsPage(getRapports.page)
                setShowTalbe(true)
            } else {
                setText('Erreur lors de la récupération des consultations !')
                setSeverity('error')
                setTransition(() => TransitionLeft)
                setOpenSnackbar(true)
            }
            const getContact = await apiFetch('/mon-compte/mon-contact-dedie')
            if (getContact && getContact.nom) {
                setContact(getContact)
            }
        })()
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, [setOpenSnackbar, setText, setTransition, setSeverity, profil])

    const handleChange = async (event) => {
        setLoadingRap(true)
        setLoadingCons(true)
        setSite(event.target.value);
        setSelectedSiteObject(profil.entreprises.find((entreprise) => entreprise.id === event.target.value));
        if (event.target.value) {
            const getCom = await apiFetch(`/communication/elements-de-communication?entreprise_id=${event.target.value}`)
            if (getCom && getCom.length) {
                setCommunications(getCom)
            } else if (getCom && getCom.length === 0) {
                setCommunications([]);
            } else {
                setText('Erreur lors de la récupération des supports de communication !')
                setSeverity('error')
                setTransition(() => TransitionLeft)
                setOpenSnackbar(true)
            }
        } else {
            setCommunications(false)
        }
        
        const filterEntreprise = event.target.value ? `entreprise_id=${event.target.value}&` : ''

        const getConsultations = await apiFetch(`/plateforme/consultations?limit=5&${filterEntreprise}`)
        if (getConsultations && getConsultations.items) {
            setConsultations(getConsultations)
            setConsultationsItems(getConsultations.items)
            setConsultationsPage(getConsultations.page)
            setShowTalbe(true)
        } else {
            setText('Erreur lors de la récupération des consultations !')
            setSeverity('error')
            setTransition(() => TransitionLeft)
            setOpenSnackbar(true)
        }
        setLoadingCons(false)
        const getRapports = await apiFetch(`/plateforme/rapports?limit=5&${filterEntreprise}`)
        if (getRapports && getRapports.items) {
            setRapports(getRapports)
            setRapportsItems(getRapports.items)
            setRapportsPage(getRapports.page)
            setShowTalbe(true)
        } else {
            setText('Erreur lors de la récupération des consultations !')
            setSeverity('error')
            setTransition(() => TransitionLeft)
            setOpenSnackbar(true)
        }
        setLoadingRap(false)
    };

    const handleChangeRapportStartDate = (e) => {
        setRapportStartDate(e);
        fetchRapportsFilter(e, 'isFromStartDate')
    }

    const handleChangeRapportEndDate = (e) => {
        setRapportEndDate(e)
        fetchRapportsFilter(e, 'isFromEndDate')
    }

    const handleChangeConsultationStartDate = (e) => {
        setConsultationStartDate(e);
        fetchConsultationsFilter(e, 'isFromStartDate')
    }

    const handleChangeConsultationEndDate = (e) => {
        setConsultationEndDate(e)
        fetchConsultationsFilter(e, 'isFromEndDate')
    }

    const fetchConsultationsFilter = async (param, entryPoint) => {
        const filterStartDate =
            (consultationStartDate && entryPoint !== 'isFromStartDate') || (entryPoint === 'isFromStartDate' && param) ?
                `debut=${entryPoint === 'isFromStartDate' ? moment(param).format() : moment(consultationStartDate).format()}&` : ''
        const filterEndDate =
            (consultationEndDate && entryPoint !== 'isFromEndDate') || (entryPoint === 'isFromEndDate' && param) ?
                `fin=${entryPoint === 'isFromEndDate' ? moment(param).format() : moment(consultationEndDate).format()}&` : ''
        const filterEntreprise = selectedSiteObject ? typeof selectedSiteObject === 'object' && selectedSiteObject !== null ? 
                                `entreprise_id=${selectedSiteObject.id}&` : `entreprise_id=${selectedSiteObject}&` : ''
        setLoadingCons(true)
        const fetchConsultations = await apiFetch(`/plateforme/consultations?limit=5&${filterEntreprise}${filterStartDate}${filterEndDate}`)
        if (fetchConsultations && fetchConsultations.items) {
            setConsultationsItems(fetchConsultations.items)
            setConsultations(fetchConsultations)
            setConsultationsPage(fetchConsultations.total < 6 ? 0 : fetchConsultations.page)
        } else {
            setText('Erreur lors de la récupération des consultations !')
            setSeverity('error')
            setTransition(() => TransitionLeft)
            setOpenSnackbar(true)
        }
        setLoadingCons(false)
    }

    const fetchRapportsFilter = async (param, entryPoint) => {
        const filterStartDate =
            (rapportStartDate && entryPoint !== 'isFromStartDate') || (entryPoint === 'isFromStartDate' && param) ?
                `debut=${entryPoint === 'isFromStartDate' ? moment(param).format() : moment(rapportStartDate).format()}&` : ''
        const filterEndDate =
            (rapportEndDate && entryPoint !== 'isFromEndDate') || (entryPoint === 'isFromEndDate' && param) ?
                `fin=${entryPoint === 'isFromEndDate' ? moment(param).format() : moment(rapportEndDate).format()}&` : ''
        const filterEntreprise = selectedSiteObject ? typeof selectedSiteObject === 'object' && selectedSiteObject !== null ? 
                                `entreprise_id=${selectedSiteObject.id}&` : `entreprise_id=${selectedSiteObject}&` : ''
        setLoadingRap(true)
        const fetchRapports = await apiFetch(`/plateforme/rapports?limit=5&${filterEntreprise}${filterStartDate}${filterEndDate}`)
        if (fetchRapports && fetchRapports.items) {
            setRapportsItems(fetchRapports.items)
            setRapports(fetchRapports)
            setRapportsPage(fetchRapports.total < 6 ? 0 : fetchRapports.page)
        } else {
            setText('Erreur lors de la récupération des rapports !')
            setSeverity('error')
            setTransition(() => TransitionLeft)
            setOpenSnackbar(true)
        }
        setLoadingRap(false)
    }

    const handleDownloadCommunication = async (row) => {
        setDownloading(true);
        const downloadCom = await apiFetch(`${row.url.split('api-rh')[1]}`, {}, false, true)

        if (downloadCom.ok) {
            const blob = await downloadCom.blob()
            download(blob, `${row.nom}`)
        }
        setDownloading(false);
    }

    const handleDownloadConsultations = async () => {
        const filterStartDate =
            consultationStartDate ?
                `debut=${moment(consultationStartDate).format()}&` : ''
        const filterEndDate =
            consultationEndDate ?
                `fin=${moment(consultationEndDate).format()}&` : ''
        const filterEntreprise = selectedSiteObject ? typeof selectedSiteObject === 'object' && selectedSiteObject !== null ? 
                                `entreprise_id=${selectedSiteObject.id}&` : `entreprise_id=${selectedSiteObject}&` : ''
        if (consultations.total > 3000) {
            setText('L\'exportation des consultations est possible seulement si vous avez moins de 3000 consultations')
            setSeverity('warning')
            setTransition(() => TransitionLeft)
            setOpenSnackbar(true)
        }
        setDownloading(true);
        const exportConsultations = await apiFetch(`/plateforme/consultations/exporter?${filterEntreprise}${filterStartDate}${filterEndDate}`, {}, false, true)

        if (exportConsultations.ok) {
            const blob = await exportConsultations.blob()
            download(blob, 'Liste des consultations')
        }
        setDownloading(false);
    }

    return (
        <div>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={9}>
                    <Zoom in={!!profil} timeout={700} style={{ transitionDelay: '400ms' }}>
                        <div>
                            {!selectedSiteObject ? <div
                                style={{
                                    border: '5px solid #FFBDA6',
                                    borderRadius: '16px',
                                    padding: '15px 30px',
                                }}
                            >
                                <div style={{display: 'flex', height: 35}}>
                                    <img src={plateformeLogo} alt="ligne d'écoute" />
                                    <Typography variant="h6" sx={{color: '#707070', ml: 1}}>Ma ligne d'écoute</Typography>
                                </div>
                                {profil && profil.entreprises && profil.entreprises.length > 1 && <div style={{display: 'flex'}}>
                                    <Typography
                                        variant="body1"
                                        color='primary'
                                        sx={{
                                            mt: 2,
                                            mr: 2
                                        }}
                                    >
                                        Veuillez choisir un site : 
                                    </Typography>
                                    <FormControl fullWidth sx={{maxWidth: 200}}>
                                        <InputLabel id="site-select-label">Mon entreprise</InputLabel>
                                        <Select
                                            labelId="site-select-label"
                                            id="site-select"
                                            value={site}
                                            label="Mon entreprise"
                                            onChange={handleChange}
                                        >
                                            <MenuItem value={''}>Toutes les entreprises</MenuItem>
                                            {profil &&
                                            profil.entreprises &&
                                            profil.entreprises.length > 1 &&
                                            profil.entreprises.map((entreprise) => {
                                                return <MenuItem key={entreprise.id} value={entreprise.id}>{entreprise.nom}</MenuItem>
                                            })}
                                        </Select>
                                    </FormControl>
                                </div>}
                            </div> : 
                            <div
                                style={{
                                    border: '5px solid #FFBDA6',
                                    borderRadius: '16px',
                                    padding: '15px 30px',
                                }}
                            >
                                <Grid container spacing={3} sx={{mt: 0}}>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={4}
                                        sx={{
                                            borderRight: selectedSiteObject.numero_vert ? '5px solid #CECECE' : '',
                                            pt: '0px !important'
                                        }}
                                    >
                                        <div style={{display: 'flex', height: 35}}>
                                            <img src={plateformeLogo} alt="ligne d'écoute" />
                                            <Typography variant="h6" sx={{color: '#707070', mb: 1, ml: 1}}>Ma ligne d'écoute</Typography>
                                        </div>
                                        {profil && profil.entreprises && profil.entreprises.length > 1 && <div>
                                            <Typography
                                                variant="caption"
                                                color='primary'
                                            >
                                                Veuillez choisir un site : 
                                            </Typography><br />
                                            <FormControl fullWidth sx={{maxWidth: 200, mt: 1}}>
                                                <InputLabel id="site-select-label">Mon entreprise</InputLabel>
                                                <Select
                                                    labelId="site-select-label"
                                                    id="site-select"
                                                    value={site}
                                                    label="Mon entreprise"
                                                    onChange={handleChange}
                                                >
                                                    <MenuItem value={''}>Toutes les entreprises</MenuItem>
                                                    {profil &&
                                                    profil.entreprises &&
                                                    profil.entreprises.length > 1 &&
                                                    profil.entreprises.map((entreprise) => {
                                                        return <MenuItem key={entreprise.id} value={entreprise.id}>{entreprise.nom}</MenuItem>
                                                    })}
                                                </Select>
                                            </FormControl>
                                        </div>}
                                        {!selectedSiteObject.numero_vert && <div style={{width: '300%'}}>
                                            <Typography variant='body1' sx={{mt: 4, mb: 2}}>
                                                Vous ne bénéficiez pas encore de la ligne d'écoute Pros-Consulte
                                            </Typography>
                                        </div>}
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={4}
                                        sx={{
                                            borderRight: selectedSiteObject.numero_vert ? '5px solid #CECECE' : '',
                                            textAlign: 'center',
                                            pt: '0px !important'
                                        }}
                                    >
                                        {selectedSiteObject.numero_vert && <Typography
                                            variant="body1"
                                            color='primary'
                                            sx={{
                                                mt: 2,
                                                fontSize: 18,
                                            }}
                                        >
                                            Numéro vert : 
                                        </Typography>}<br />
                                        <Typography
                                            variant="body1"
                                            color='primary'
                                            sx={{fontWeight: 'bold', fontSize: 20}}
                                        >
                                            {selectedSiteObject.numero_vert && formatNumVert(selectedSiteObject.numero_vert)} 
                                        </Typography>
                                        {selectedSiteObject.code_acces && <Typography
                                            variant="body1"
                                            color='primary'
                                            sx={{
                                                mt: 2,
                                                fontSize: 18,
                                            }}
                                        >
                                            Code d'accès : {selectedSiteObject.code_acces}
                                        </Typography>}
                                    </Grid>
                                    <Grid item xs={12} sm={4} sx={{textAlign: 'center', pt: '0px !important'}}>
                                        {selectedSiteObject.numero_vert && <Typography
                                            variant="body1"
                                            color='primary'
                                            sx={{
                                                mt: 2,
                                                fontSize: 18,
                                            }}
                                        >
                                            Mot de passe entreprise : 
                                        </Typography>}<br />
                                        <Typography
                                            variant="body1"
                                            color='primary'
                                            sx={{ fontWeight: 'bold', fontSize: 18 }}
                                        >
                                            {selectedSiteObject.mot_de_passe_entreprise}
                                        </Typography><br />
                                        {selectedSiteObject.numero_vert && <ColorButton
                                            variant='contained'
                                            onClick={() => window.open('https://www.mon-espace.pros-consulte.com/', "_blank")}
                                        >
                                            Accéder à l'espace salarié
                                        </ColorButton>}
                                    </Grid>
                                </Grid>
                            </div>
                            }
                        </div>
                    </Zoom>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <Zoom in={!!profil} timeout={700} style={{ transitionDelay: '200ms' }}>
                        <div style={{position: 'relative', height: '100%'}}>
                            <Button
                                variant='contained'
                                color='secondary'
                                sx={{
                                    width: '100%',
                                    position: 'absolute',
                                    bottom: 0,
                                    borderRadius: 2,
                                    height: 60,
                                    fontWeight: 'bold',
                                }}
                                onClick={() => setOpenContactPopup(true)}
                            >
                                Contacter le service ligne d'écoute
                            </Button>
                            <ContactPopup
                                open={openContactPopup}
                                handleClose={() => setOpenContactPopup(false)}
                                title="ligne d'écoute"
                                text="Notre service ligne d'écoute est joignable au 02 97 84 32 52."
                            />
                        </div>
                    </Zoom>
                </Grid>
            </Grid>
            <Grid container spacing={3} sx={{mt: 3}}>
                <Grid item xs={12} sm={9}>
                    <Zoom in={!!profil} timeout={700} style={{ transitionDelay: '700ms' }}>
                        <div
                            style={{
                                border: '5px solid #ACCDD8',
                                borderRadius: '16px',
                                padding: '15px 30px',
                            }}
                        >
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={4}>
                                    <div style={{display: 'flex'}}>
                                        <img src={communiquerLogo} alt='Communiquer' />
                                        <Typography variant="h6" sx={{color: '#707070', ml: 1}}>Communiquer</Typography>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={8}>
                                    <Typography variant="body1" color='primary' sx={{mt: 0.8, fontWeight: 'bold'}}>
                                        Mes supports de communication
                                    </Typography>
                                </Grid>
                            </Grid>
                            {!selectedSiteObject ? <div style={{textAlign: 'center', margin: '100px 0px'}}>
                                <Typography variant='caption' sx={{mt: 5}}>
                                    Les données apparaitront une fois le site choisi
                                </Typography>
                            </div> : 
                            communications.length === 0 && selectedSiteObject.numero_vert ? <div style={{textAlign: 'center', margin: '100px 0px'}}>
                                <Typography variant='caption' sx={{mt: 5}}>
                                    Vous n'avez pas de support de communications
                                </Typography>
                            </div> : !selectedSiteObject.numero_vert ? 
                            <Typography variant='body1' sx={{margin: '100px 0px'}}>
                                Vous ne bénéficiez pas encore de la ligne d'écoute Pros-Consulte
                            </Typography> : 
                            <div>
                                <Grid container spacing={3} sx={{mt: 2.5}}>
                                    {communications && communications.length && communications.map((communication, index) => {
                                        return <Grid
                                            item
                                            xs={12}
                                            sm={6}
                                            sx={{
                                                borderRight: index % 2 === 0 ? '5px solid #CECECE' : '',
                                                paddingTop: '0px !important',
                                                pb: 1,
                                            }}
                                            key={index}
                                        >
                                            <Grid container spacing={3}>
                                                <Grid item xs={12} sm={6} sx={{mt: 1}}>
                                                    <Typography variant='body2'>
                                                        {capitalizeFirstLetter(communication.nom.toLowerCase())}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} sm={3} sx={{mt: 1}}>
                                                    <Typography variant='body2'>{communication.format}</Typography>
                                                </Grid>
                                                <Grid item xs={12} sm={3}>
                                                    {downloading ? ( 
                                                        <CircularProgress />
                                                    ) : (
                                                        <StyledTooltip title="Télécharger le support de communication"> 
                                                            <IconButton
                                                                aria-label="Télécharger l'élément de communication"
                                                                color="primary"
                                                                onClick={() => handleDownloadCommunication(communication)}
                                                            >
                                                                <FileDownloadIcon />
                                                            </IconButton>
                                                        </StyledTooltip>
                                                    )}
                                                </Grid>
                                            </Grid>
                                        </Grid>    
                                    })}   
                                </Grid>    
                            </div>}
                        </div>
                    </Zoom>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <Zoom in={!!profil} timeout={700} style={{ transitionDelay: '600ms' }}>
                        <div
                            style={{
                                background: '#ACCDD8 0% 0% no-repeat padding-box',
                                boxShadow: '1px 3px 6px #CCCCCC',
                                borderRadius: '16px',
                                textAlign: 'center',
                                padding: '20px 10px',
                                position: 'relative',
                                overflow: 'hidden',
                            }}
                        >
                            {!isPlaying ?
                            <img
                                src={videoImg}
                                alt='video sur mesure'
                                onClick={() => setIsPlaying(true)}
                                width={275}
                                height={150}
                                style={{borderRadius: 8, width: '100%'}}
                            /> :
                            <VideoPlayer
                                autoPlay={false}
                                videoUrl={'https://bo-public-prod.s3.eu-west-3.amazonaws.com/communication/autres/Pros-Consulte_Video_Decouvrir_APPLI+PC.mp4'}
                                forceWith={'83%'}
                            />}
                            <Typography variant='body2' color='primary' sx={{maxWidth: 200, margin: 'auto', mt: 1, mb: 2}}>
                                Nous pouvons personnaliser cette vidéo pour vous
                            </Typography>
                            <ColorButton variant='contained' onClick={() => setOpenVideoPopup(true)}>
                                Demander ma vidéo sur-mesure
                            </ColorButton>
                            <VideoPopup open={openVideoPopup} handleClose={() => setOpenVideoPopup(false)} contact={contact} />
                        </div>
                    </Zoom>
                </Grid>
            </Grid>
            
            <Zoom in={showTable} timeout={700} style={{ transitionDelay: '200ms' }}>
                <Paper
                    sx={{
                        mt: 3,
                        borderRadius: 5,
                        boxShadow: '-1px 4px 10px #CCCCCC',
                        padding: '20px 30px'
                    }}
                >
                    <div style={{display: 'flex', height: 35, marginBottom: 16}}>
                        <img src={analyserLogo} alt='Analyser' />
                        <Typography variant='h6' sx={{color: '#707070', ml: 1}}>Analyser</Typography>
                    </div>
                    <Typography variant='body: 2' sx={{color: '#707070', mt: 2}}>Bilans d'activité</Typography>
                    <div style={{display: 'flex'}}>
                        <Typography variant='body1' color='primary' sx={{mt: 2.5, mr: 2}}>
                            Du
                        </Typography>
                        <DatePickerComponent
                            text={'Date de début'}
                            selectedDate={rapportStartDate}
                            width={210}
                            handleDateChange={handleChangeRapportStartDate}
                        />
                        <Typography variant='body1' color='primary' sx={{mt: 2.5, mr: 2}}>
                            au
                        </Typography>
                        <DatePickerComponent
                            text={'Date de fin'}
                            selectedDate={rapportEndDate}
                            width={210}
                            handleDateChange={handleChangeRapportEndDate}
                        />
                    </div>
                    <div>
                        <RapportsTable
                            items={rapportsItems}
                            page={rapportsPage}
                            setRapportsPage={setRapportsPage}
                            startDate={rapportStartDate}
                            endDate={rapportEndDate}
                            rapports={rapports}
                            loadingTable={loadingRap}
                            setLoadingRap={setLoadingRap}
                            setItems={setRapportsItems}
                            setRapports={setRapports}
                            selectedSiteObject={selectedSiteObject}
                        />
                    </div>
                    <div style={{borderBottom: '2px solid #75ADBE', marginBottom: 20}}></div>
                    <Typography variant='body: 2' sx={{color: '#707070'}}>Consultations</Typography>
                    <div style={{display: 'flex', marginBottom: 20}}>
                        <Typography variant='body1' color='primary' sx={{mt: 2.5, mr: 2}}>
                            Du
                        </Typography>
                        <DatePickerComponent
                            text={'Date de début'}
                            selectedDate={consultationStartDate}
                            width={210}
                            handleDateChange={handleChangeConsultationStartDate}
                        />
                        <Typography variant='body1' color='primary' sx={{mt: 2.5, mr: 2}}>
                            au
                        </Typography>
                        <DatePickerComponent
                            text={'Date de fin'}
                            selectedDate={consultationEndDate}
                            width={210}
                            handleDateChange={handleChangeConsultationEndDate}
                        />
                        <StyledTooltip
                            title={
                                consultations.total > 3000 ? 
                                'L\'exportation des consultations est possible seulement si vous avez moins de 3000 consultations' :
                                'Télécharger la liste des consultations'
                            }
                        >  
                            <IconButton
                                aria-label="Télécharger les consultations"
                                color="primary"
                                onClick={() => handleDownloadConsultations()}
                                sx={{marginLeft: 'auto'}}
                                disabled={downloading}
                            >
                                {downloading ? ( 
                                    
                                    <CircularProgress size={24} />
                                ) : (
                                    
                                    <FileDownloadIcon />
                                )}
                            </IconButton>
                        </StyledTooltip>
                    </div>
                    <div>
                        <ConsultationsTable
                            items={consultationsItems}
                            consultationsPage={consultationsPage}
                            setConsultationsPage={setConsultationsPage}
                            setConsultations={setConsultations}
                            setItems={setConsultationsItems}
                            consultations={consultations}
                            startDate={consultationStartDate}
                            endDate={consultationEndDate}
                            loadingTable={loadingCons}
                            setLoadingCons={setLoadingCons}
                            selectedSiteObject={selectedSiteObject}
                        />
                    </div>
                </Paper>
            </Zoom>
        </div>
    )
}

const formatNumVert = (string) => {
    return string.split('').map((c, i) => {
        if (i === 0) {
            return c + ' '
        } else if (i%2 !== 0 && i > 2) {
            return c + ' '
        } else {
            return c
        }
    })
}