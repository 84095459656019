import React from 'react';
import { 
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Slide,
    Typography
} from '@mui/material';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function InformationPopup({ open, handleClose }) {

  return (
        <Dialog
            open={!!open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-labelledby="information-popup-title"
            aria-describedby="information-popup-description"
        >
            <DialogTitle id="information-popup-title">{open.title}</DialogTitle>
            <DialogContent>
                <Typography variant='body1' color='primary'>{open.text}</Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>
                    Fermer
                </Button>
            </DialogActions>
        </Dialog>
  );
}