import React from "react";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment'
import 'moment/locale/fr'

moment.locale('fr')

export function DateTimePickerComponent({ text, selectedDate, handleDateChange, error }) {

  return (
        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={'fr'}>
            <DateTimePicker
                error={error}
                value={selectedDate}
                onChange={handleDateChange}
                label={text}
                animateYearScrolling
                showTodayButton
                format="DD MMMM YYYY"
            />
        </LocalizationProvider>
    );
}

export function DatePickerComponent({ text, selectedDate, handleDateChange, error, width }) {

    return (
        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={'fr'}>
            <DatePicker
                label={text}
                error={!!error}
                value={selectedDate}
                onChange={handleDateChange}
                showTodayButton
                animateYearScrolling
                format="DD MMMM YYYY"
                variant="standard"
                sx={{width: width || 150, mr: 3}}
                slotProps={{ textField: { variant: "standard" }, field: { clearable: true, onClear: () => handleDateChange('') },
            }}
            />
        </LocalizationProvider>
    );
  }
