import { Button, CircularProgress, Grid, IconButton, Paper, Typography, Zoom, styled, useMediaQuery } from '@mui/material';
import React, { useEffect, useState } from 'react'
import appel from '../../assets/COm_VIS_20240205_pictos_BO-DRH_telephone.png'
import signalement from '../../assets/COm_VIS_20240205_pictos_BO-DRH_signalements.png'
import charge from '../../assets/COm_VIS_20240205_pictos_BO-DRH_prise-en-charge.png'
import { apiFetch } from '../../security/apiFetch';
import { TransitionLeft } from '../../helpers/utils';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {
    BarChart,
    Bar,
    PieChart,
    Pie,
    Sector,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    Cell,
} from 'recharts';
import informationIcon from '../../assets/COm_VIS_20240205_pictos_BO-DRH_informations.png'
import InformationPopup from '../../components/InformationPopup';
import VideoPlayer from '../DocumentsItems/VideoPlayer';
import videoImg from '../../assets/video_acceuil.png'
import ContactPopup from './ContactPopup';

const COLORS = ['#DE6972', '#52AD71', '#E1C333', '#4280C2', '#AAAAAA'];

const renderActiveShape = (props) => {
    const RADIAN = Math.PI / 180;
    const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';
  
    return (
        <g>
            <Sector
                cx={cx}
                cy={cy}
                innerRadius={innerRadius}
                outerRadius={outerRadius}
                startAngle={startAngle}
                endAngle={endAngle}
                fill={fill}
            />
            <Sector
                cx={cx}
                cy={cy}
                startAngle={startAngle}
                endAngle={endAngle}
                innerRadius={outerRadius + 6}
                outerRadius={outerRadius + 10}
                fill={fill}
            />
            <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
            <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
            <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill={fill}>{`${(percent * 100).toFixed(0)}%`}</text>
            <text x={cx} y={cy + 120} dy={8} textAnchor="middle" fill={fill}>
                {payload.label}
            </text>
        </g>
    );
};

const ColorButton = styled(Button)(({ theme }) => ({
    color: '#01304A',
    backgroundColor: 'white',
    '&:hover': {
        backgroundColor: '#FEFEFE',
    },
}));

export default function Plateforme({
    selectedSiteObject,
    setText,
    setTransition,
    setSeverity,
    setOpenSnackbar,
    openInformationPopup,
    setOpenInformationPopup,
    handleOpenInformationPopup,
}) {
    const [data, setData] = useState(false)
    const [loading, setLoading] = useState(false)
    const [activeIndex, setActiveIndex] = useState(0)
    const [activeMonth, setActiveMonth] = useState('')
    const [barChartConsultations, setBarChartConsultations] = useState([]);
    const [barChartMotifs, setBarChartMotifs] = useState([]);
    const [isPlaying, setIsPlaying] = useState(false)
    const [openContactPopup, setOpenContactPopup] = useState(false)
    const matches = useMediaQuery('(max-width:1248px)');

    useEffect(() => {
        (async () => {
            setLoading(true)
            const getPlateforme = await apiFetch(`/plateforme/tableau-de-bord?entreprise_id=${selectedSiteObject.id}`)
            if (getPlateforme) {
                setData(getPlateforme)
                setBarChartConsultations(getPlateforme.evolution_des_consultations)
                setActiveMonth(getPlateforme.repartition_des_motifs_d_appels[0].mois)
                setBarChartMotifs(getPlateforme.repartition_des_motifs_d_appels[0].motifs_d_appels)
            } else {
                setText('Erreur lors de la récupération des informations de la ligne d\'écoute')
                setSeverity('error')
                setTransition(() => TransitionLeft)
                setOpenSnackbar(true)
            }
            setLoading(false)
        })()
    }, [selectedSiteObject, setText, setTransition, setSeverity, setOpenSnackbar])

    const onPieEnter = (_, index) => {
        setActiveIndex(index)
    }

    const handleAddChangeMonth = () => {
        const findIndex = data.repartition_des_motifs_d_appels.findIndex((arr) => arr.mois === activeMonth)
        if (data.repartition_des_motifs_d_appels[findIndex + 1]) {
            setActiveMonth(data.repartition_des_motifs_d_appels[findIndex + 1].mois)
            setBarChartMotifs(data.repartition_des_motifs_d_appels[findIndex + 1].motifs_d_appels)
        }
    }

    const handleRemoveChangeMonth = () => {
        const findIndex = data.repartition_des_motifs_d_appels.findIndex((arr) => arr.mois === activeMonth)
        if (data.repartition_des_motifs_d_appels[findIndex - 1]) {
            setActiveMonth(data.repartition_des_motifs_d_appels[findIndex - 1].mois)
            setBarChartMotifs(data.repartition_des_motifs_d_appels[findIndex - 1].motifs_d_appels)
        }
    }

    return (
        <div>
            {loading && <div style={{textAlign: 'center', overflow: 'hidden', marginTop: 20}}><CircularProgress color={'primary'} /></div>}
            {data && data.plateforme_active ? <Grid container spacing={3}>
                <Grid item xs={12} sm={3}>
                    <Zoom in={!!data} timeout={700} style={{ transitionDelay: '400ms' }}>
                        <Paper elevation={0} sx={{p: '10px 30px 10px 30px', borderRadius: '16px', textAlign: 'center'}}>
                            <Grid container spacing={0} sx={{mb: 1}}>
                                <Grid item xs={11}>
                                    <Typography variant='h6' color='primary' sx={{ml: 3}}>
                                        APPELS
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    xs={1}
                                    sx={{pl: 2, cursor: 'pointer'}}
                                    onClick={
                                        () => handleOpenInformationPopup(
                                            {
                                                title: 'Appels',
                                                text: "Il s’agit du nombre d’appels sur la ligne d'écoute en temps réel, pour le mois en cours. Ce nombre est comparé à celui des 30 derniers jours ce qui vous donne une évolution des appels."
                                            }
                                        )
                                    }>
                                    <img src={informationIcon} alt={'information'} />
                                </Grid>
                            </Grid>
                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 0}}>
                                <Typography variant='body1' color='primary' sx={{mr: 2, fontSize: 35}}>
                                    {data.appels}
                                </Typography>
                                <img src={appel} alt='appels' />
                            </div>
                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            {data.evolution_des_appels > 0 && (
                                <>
                                    <Typography variant='body1' sx={{ mr: 2, fontSize: 35, color: '#75ADBE' }}>
                                        {`+ ${data.evolution_des_appels}`}
                                    </Typography>
                                    <ArrowDropUpIcon fontSize={"large"} />
                                </>
                            )}
                            {data.evolution_des_appels < 0 && (
                                <>
                                    <Typography variant='body1' sx={{ mr: 2, fontSize: 35, color: '#75ADBE' }}>
                                        {`${data.evolution_des_appels}`}
                                    </Typography>
                                    <ArrowDropDownIcon fontSize={"large"} />
                                </>
                            )}
                            {data.evolution_des_appels === 0 && (
                                <Typography variant='body1' sx={{ mr: 2, fontSize: 35, color: '#75ADBE' }}>
                                    {`${data.evolution_des_appels}`}
                                </Typography>
                            )}
                            </div>
                        </Paper>
                    </Zoom>
                    <Zoom in={!!data} timeout={700} style={{ transitionDelay: '700ms' }}>
                        <Paper elevation={0} sx={{p: '10px 30px 20px 30px', borderRadius: '16px', textAlign: 'center', mt: 3}}>
                            <Grid container spacing={0} sx={{mb: 3}}>
                                <Grid item xs={11}>
                                    <Typography variant='h6' color='primary' sx={{ml: 3}}>
                                        PRISES EN CHARGE
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    xs={1}
                                    sx={{pl: 2, cursor: 'pointer'}}
                                    onClick={
                                        () => handleOpenInformationPopup(
                                            {
                                                title: 'Prise en charge',
                                                text: 'Il s’agit du nombre de prises en charge individuelles effectuées par nos psychologues par appels sortants, en temps réel, pour le mois en cours.'
                                            }
                                        )
                                    }>
                                    <img src={informationIcon} alt={'information'} />
                                </Grid>
                            </Grid>
                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                <Typography variant='body1' color='primary' sx={{mr: 2, fontSize: 35}}>
                                    {data.prises_en_charges}
                                </Typography>
                                <img src={charge} alt='prises en charge' />
                            </div>
                        </Paper>
                    </Zoom>
                    <Zoom in={!!data} timeout={700} style={{ transitionDelay: '100ms' }}>
                        <Paper elevation={0} sx={{p: '10px 30px 20px 30px', borderRadius: '16px', textAlign: 'center', mt: 3}}>
                            <Grid container spacing={0} sx={{mb: 3}}>
                                <Grid item xs={11}>
                                    <Typography variant='h6' color='primary' sx={{ml: 3}}>
                                        SIGNALEMENT
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    xs={1}
                                    sx={{pl: 2, cursor: 'pointer'}}
                                    onClick={
                                        () => handleOpenInformationPopup(
                                            {
                                                title: 'Signalement',
                                                text: "Il s’agit du nombre de signalements réalisés par des appelants, ayant choisi de remonter leur situation à leur structure, via la ligne d'écoute."
                                            }
                                        )
                                    }>
                                    <img src={informationIcon} alt={'information'} />
                                </Grid>
                            </Grid>
                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                <Typography variant='body1' color='primary' sx={{mr: 2, fontSize: 35}}>
                                    {data.signalements}
                                </Typography>
                                <img src={signalement} alt='signalement' />
                            </div>
                        </Paper>
                    </Zoom>
                </Grid>
                <Grid item xs={12} sm={5} style={{ display: 'flex', flexDirection: 'column', maxWidth: '100%', overflow: 'hidden' }} >
                    <Zoom in={!!data} timeout={700} style={{ transitionDelay: '100ms' }}>
                        <Paper elevation={0} sx={{ p: matches ? '25px 25px' : '25px 50px', borderRadius: '16px', pb: '83px', flex: 1 }}>
                            <div style={{width: '100%', textAlign: 'center', marginBottom: 50}}>
                                <Grid container spacing={0} sx={{mb: 3}}>
                                    <Grid item xs={11}>
                                        <Typography variant='h6' color='primary' sx={{ml: 3, fontWeight: 'bold'}}>
                                            Évolution des consultations
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={1}
                                        sx={{cursor: 'pointer'}}
                                        onClick={
                                            () => handleOpenInformationPopup(
                                                {
                                                    title: 'Évolution des consultations',
                                                    text: "Il s’agit de l'évolution du nombre de consultations de la ligne d'écoute au cours des 12 derniers mois."
                                                }
                                            )
                                        }>
                                        <img src={informationIcon} alt={'information'} />
                                    </Grid>
                                </Grid>
                            </div>
                            <div>
                                <BarChart
                                    width={matches ? 300 : 500}
                                    height={300}
                                    data={barChartConsultations}
                                    margin={{
                                        top: 5,
                                        right: 30,
                                        left: -30,
                                        bottom: 5,
                                    }}
                                >
                                    <CartesianGrid strokeDasharray="4 1 2" />
                                    <XAxis dataKey="mois" />
                                    <YAxis axisLine={false} display="none" />
                                    <Tooltip />
                                    <Legend formatter={(value) => value === "telephone" ? "téléphone" : value}/>
                                    <Bar dataKey="telephone" fill="#01304A" barSize={12} radius={8}/>
                                    <Bar dataKey="tchat" fill="#75ADBE" barSize={12} radius={8} />
                                    <Bar dataKey="visio" fill="#FFBDA6" barSize={12} radius={8} />
                                </BarChart>
                            </div>
                        </Paper>
                    </Zoom>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Zoom in={!!data} timeout={700} style={{ transitionDelay: '100ms' }}>
                            <Paper elevation={0} sx={{p: '25px 50px', borderRadius: '16px'}}>
                            <div style={{width: '100%', textAlign: 'center'}}>
                                <Grid container spacing={0} sx={{mb: 3}}>
                                    <Grid item xs={11}>
                                        <Typography variant='h6' color='primary' sx={{ml: 3, fontWeight: 'bold'}}>
                                            Motifs d'appels
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={1}
                                        sx={{pl: 3, cursor: 'pointer'}}
                                        onClick={
                                            () => handleOpenInformationPopup(
                                                {
                                                    title: "Motifs d'appels",
                                                    text: 'Il s\'agit des motifs d\'appels sur la ligne d\'écoute, sur le mois en cours.'
                                                }
                                            )
                                        }>
                                        <img src={informationIcon} alt={'information'} />
                                    </Grid>
                                </Grid>
                            </div>
                            <div style={{marginTop: -35, marginLeft: -20}}>
                                {barChartMotifs.length ? <PieChart width={400} height={400}>
                                    <Pie
                                        activeIndex={activeIndex}
                                        activeShape={renderActiveShape}
                                        data={barChartMotifs}
                                        cx="50%"
                                        cy="50%"
                                        innerRadius={60}
                                        outerRadius={80}
                                        fill="#FFBDA6"
                                        dataKey="pourcentage"
                                        onMouseEnter={onPieEnter}
                                    >
                                        {barChartMotifs.map((entry, index) => (
                                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                        ))}
                                    </Pie>
                                </PieChart> :
                                <Typography variant='body1' sx={{mt: 23, mb: 21}}>
                                    Pas de consultations pour ce mois, pour voir les motifs d'appels veuillez sélectionner un mois avec des appels
                                </Typography>}
                            </div>
                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: -20}}>
                                <IconButton
                                    onClick={handleAddChangeMonth}
                                    disabled={
                                        data.repartition_des_motifs_d_appels && 
                                        data.repartition_des_motifs_d_appels.findIndex((arr) => arr.mois === activeMonth) === 11
                                    }
                                >
                                    <ChevronLeftIcon />
                                </IconButton>
                                <Typography variant='body1' sx={{mt: 0}}>{activeMonth}</Typography>
                                <IconButton
                                    onClick={handleRemoveChangeMonth}
                                    disabled={
                                        data.repartition_des_motifs_d_appels && 
                                        data.repartition_des_motifs_d_appels.findIndex((arr) => arr.mois === activeMonth) === 0
                                    }
                                >
                                    <ChevronRightIcon />
                                </IconButton>
                            </div>
                        </Paper>
                    </Zoom>
                </Grid>
            </Grid> : 
            <Zoom in={!data.plateforme_active && !loading}>
            <div style={{textAlign: 'center', paddingBottom: 10}}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} sx={{pt: '40px !important'}}>
                        <Typography variant='h6' sx={{maxWidth: 400, margin: 'auto', mt: 5, fontWeight: 'bold', fontSize: 21}}>
                            Vous ne bénéficiez pas encore de la ligne d'écoute Pros-Consulte.
                        </Typography>
                        <Typography variant='body1' sx={{maxWidth: 400,  margin: 'auto', mt: 5, lineHeight: 1.7}}>
                            Votre ligne d’écoute est entièrement personnalisée selon votre organisation, nous vous proposons une solution qui vous ressemble. Faites le choix d’un outil, fiable, pérenne et pertinent. Rapports statistiques réguliers, back office dédié, trouvez toutes les informations dont vous avez besoin en quelques clics. Le tout en respectant l’anonymat de vos salariés.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} sx={{pt: '40px !important'}}>
                        {!isPlaying ?
                        <img
                            src={videoImg}
                            alt='video sur mesure'
                            onClick={() => setIsPlaying(true)}
                            width={600}
                            style={{borderRadius: 8}}
                        /> :
                        <VideoPlayer
                            autoPlay={false}
                            videoUrl={'https://bo-public-prod.s3.eu-west-3.amazonaws.com/communication/autres/Pros-Consulte_Video_Decouvrir_APPLI+PC.mp4'}
                            forceWith={'83%'}
                        />}
                        <Typography variant='body2' color='primary' sx={{margin: 'auto', mt: 5, mb: 2}}>
                            Vous souhaitez des renseignements sur notre ligne d'écoute ?
                        </Typography>
                        <ColorButton
                            variant='contained'
                            sx={{mt: 2}}
                            onClick={() => setOpenContactPopup(true)}
                        >
                            En savoir +
                        </ColorButton>
                    </Grid>
                </Grid>
            </div>
            </Zoom>}
            <InformationPopup
                open={openInformationPopup}
                handleClose={() => setOpenInformationPopup(false)}
            />
            <ContactPopup 
                open={openContactPopup}
                handleClose={() => setOpenContactPopup(false)}
            />
        </div>
    )
}