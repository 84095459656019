import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import { visuallyHidden } from '@mui/utils';
import { useTheme } from '@emotion/react';
import { IconButton, createTheme, ThemeProvider, CircularProgress } from '@mui/material';
import * as locales from '@mui/material/locale';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import moment from 'moment';
import { apiFetch } from '../../security/apiFetch';
import ConsultationPopup from './ConsultationPopup';
import { findIcon } from '../../helpers/utils';

function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;
  
    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };
  
    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };
  
    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };
  
    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };
  
    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
              onClick={handleFirstPageButtonClick}
              disabled={page === 0}
              aria-label="première page"
            >
              {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton
              onClick={handleBackButtonClick}
              disabled={page === 0}
              aria-label="page précédente"
            >
              {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
              onClick={handleNextButtonClick}
              disabled={page >= Math.ceil(count / rowsPerPage) - 1}
              aria-label="page suivante"
            >
              {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
              onClick={handleLastPageButtonClick}
              disabled={page >= Math.ceil(count / rowsPerPage) - 1}
              aria-label="dernière page"
            >
              {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </Box>
    );
}

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}   

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    {
        id: 'type',
        numeric: false,
        disablePadding: true,
        label: 'Type',
        sortable: true,
    },
    {
        id: 'entreprise',
        numeric: true,
        disablePadding: false,
        label: 'Entreprise',
        sortable: true,
    },
    {
        id: 'date',
        numeric: true,
        disablePadding: false,
        label: 'Date/heure début',
        sortable: false,
    },
    {
        id: 'duree',
        numeric: true,
        disablePadding: false,
        label: 'Durée',
        sortable: false,
    },
    {
        id: 'motif',
        numeric: true,
        disablePadding: false,
        label: 'Motif',
        sortable: true,
    },
    {
        id: 'avis',
        numeric: true,
        disablePadding: false,
        label: 'Avis',
        sortable: false,
    },
];

function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } =
        props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                        style={{color: '#01304A'}}
                    >
                        {headCell.sortable ? <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel> : headCell.label}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

export default function ConsultationsTable({
    items,
    startDate,
    endDate,
    selectedSiteObject,
    consultations,
    setConsultations,
    setItems,
    loadingTable,
    setLoadingCons,
}) {
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('');
    const [page, setPage] = React.useState(0);
    const [openConsultation, setOpenConsultation] = React.useState(false);

    const handleRequestSort = async (event, property) => {
        setLoadingCons(true);
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
        const filterStartDate =
            startDate ?
                `debut=${moment(startDate).format()}&` : ''
        const filterEndDate =
            endDate ?
                `fin=${moment(endDate).format()}&` : ''
        const filterPage = 
            page ?
                `page=${page}&` : ''
        const filterEntreprise = selectedSiteObject ? typeof selectedSiteObject === 'object' && selectedSiteObject !== null ? 
                                `entreprise_id=${selectedSiteObject.id}&` : `entreprise_id=${selectedSiteObject}&` : ''
        const fetchFactures =
            await apiFetch(`/plateforme/consultations?limit=5&${filterEntreprise}${filterPage}${filterStartDate}${filterEndDate}sort_by=${property}.${isAsc ? 'desc' : 'asc'}`)
        if (fetchFactures && fetchFactures.items) {
            setItems(fetchFactures.items)
            setConsultations(fetchFactures)
            setPage(filterPage ? fetchFactures.page : 0)
        }
        setLoadingCons(false);
    };

    const handleChangePage = async (event, newPage) => {
        const filterStartDate =
            startDate ?
                `debut=${moment(startDate).format()}&` : ''
        const filterEndDate =
            endDate ?
                `fin=${moment(endDate).format()}&` : ''
        const sortBy = 
            orderBy ? 
                `sort_by=${orderBy}.${order}` : ''
        const filterEntreprise = selectedSiteObject ? typeof selectedSiteObject === 'object' && selectedSiteObject !== null ? 
                                `entreprise_id=${selectedSiteObject.id}&` : `entreprise_id=${selectedSiteObject}&` : ''
        setLoadingCons(true)
        const fetchFactures = await apiFetch(`/plateforme/consultations?limit=5&page=${newPage + 1}&${filterEntreprise}${filterStartDate}${filterEndDate}${sortBy}`)
        if (fetchFactures && fetchFactures.items) {
            setItems(fetchFactures.items)
            setConsultations(fetchFactures)
            setPage(fetchFactures.page)
        }
        setLoadingCons(false)
        setPage(newPage);
    };

    const handleOpenConsultation = (item) => {
        setOpenConsultation(item)
    }

    return (
        <Box sx={{ width: '100%' }}>
            <Paper sx={{ width: '100%', mb: 2 }} elevation={0}>
                <TableContainer>
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="Consultations"
                    >
                        <EnhancedTableHead
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            rowCount={consultations.total}
                        />
                        <TableBody>
                            {!loadingTable && items && stableSort(items, getComparator(order, orderBy)).map((row, index) => {
                                const labelId = `consultations-table-${index}`;

                                return (
                                    <TableRow
                                        hover
                                        tabIndex={-1}
                                        key={row.id}
                                        sx={{ cursor: 'pointer' }}
                                        onClick={() => handleOpenConsultation(row)}
                                    >
                                        <TableCell
                                            component="th"
                                            id={labelId}
                                            scope="row"
                                            padding="none"
                                        >
                                            {row.type && findIcon(row.type.valeur)}
                                        </TableCell>
                                        <TableCell align="right">{row.entreprise && row.entreprise.nom}</TableCell>
                                        <TableCell align="right">{moment(row.debut).format('DD MMMM YYYY')}</TableCell>
                                        <TableCell align="right">{row.duree}</TableCell>
                                        <TableCell align="right">{row.motif}</TableCell>
                                        <TableCell align="right">{row.avis}</TableCell>
                                    </TableRow>
                                );
                            })}
                            {items.length === 0 && !loadingTable && (
                                <TableRow
                                    style={{
                                        height: 53,
                                    }}
                                >
                                    <TableCell colSpan={9}>
                                        Pas de consultation disponible pour le moment
                                    </TableCell>
                                </TableRow>
                            )}
                            {loadingTable && (
                                <TableRow
                                    style={{
                                        height: 53,
                                    }}
                                >
                                    <TableCell colSpan={9} sx={{textAlign: 'center'}}>
                                        <CircularProgress />
                                    </TableCell>
                                </TableRow>
                            )}
                            
                        </TableBody>
                    </Table>
                </TableContainer>
                <ThemeProvider theme={(outerTheme) => createTheme(outerTheme, locales['frFR'])}>
                    <TablePagination
                        component="div"
                        labelRowsPerPage=''
                        count={consultations.total || 0}
                        rowsPerPage={5}
                        rowsPerPageOptions={[]}
                        ActionsComponent={TablePaginationActions}
                        page={page}
                        onPageChange={handleChangePage}
                    />
                </ThemeProvider>
            </Paper>
            <ConsultationPopup open={openConsultation} handleClose={() => setOpenConsultation(false)} />
        </Box>
    );
}