import {
    Avatar,
    Button,
    CircularProgress,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Typography,
    Zoom,
    tooltipClasses,
    styled,
    Tooltip,
    useMediaQuery,
    Box
} from '@mui/material';
import React, { createRef, useEffect, useState } from 'react';
import { TransitionLeft, download } from '../helpers/utils';
import { apiFetch } from '../security/apiFetch';
import { useNavigate } from 'react-router-dom';
import moment from 'moment'
import { DatePickerComponent } from '../components/DatePicker';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import ContactPopup from './InterventionsItems/ContactPopup';
import { BottomOfPage } from '../components/BottomOfPage';
import duree from '../assets/COm_VIS_20240205_pictos_BO-DRH_duree.png'
import adresse from '../assets/COm_VIS_20240205_pictos_BO-DRH_localisation.png'
import ReactGA from 'react-ga';
import { useTheme } from '@mui/material/styles';


const StyledTooltip = styled(({ className, ...props }) => (
    <Tooltip placement="left" {...props} arrow TransitionComponent={Zoom} classes={{ popper: className }} />
))(({theme}) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.primary.main,
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.primary.main,
    },
}));

export default function Interventions({ setText, setSeverity, setOpenSnackbar, setTransition, profil }) {
    const [items, setItems] = useState(false)
    const [interventions, setInterventions] = useState(false)
    const [types, setTypes] = useState(false)
    const [page, setPage] = useState(false)
    const [openContactPopup, setOpenContactPopup] = useState(false)
    const [spinner, setSpinner] = useState(false)
    const [loading, setLoading] = useState(false)
    const [zoom, setZoom] = useState(false)
    const [isDownloading, setIsDownloading] = useState(false)
    const [interventionZoom, setInterventionZoom] = useState(false)
    const [startDate, setStartDate] = useState(null)
    const [site, setSite] = useState('')
    const [endDate, setEndDate] = useState(null);
    const [type, setType] = useState('');
    let navigate = useNavigate()
    const target = createRef();

    document.title = 'Espace RH - Interventions'

    useEffect(() => {
        (async () => {
            setZoom(true)
            setLoading(true)
            const getInterventions = await apiFetch('/intervention/interventions')
            if (getInterventions && getInterventions.items) {
                setItems(getInterventions.items)
                setInterventions(getInterventions)
                setTypes(getInterventions.filters.find((filter) => filter.field === 'type').available_values)
                setPage(getInterventions.page)
                setInterventionZoom(true)
            } else {
                setText('Erreur lors de la récupération des interventions !')
                setSeverity('error')
                setTransition(() => TransitionLeft)
                setOpenSnackbar(true)
                navigate('/tableau-de-bord')
            }
            setLoading(false)
            ReactGA.pageview(window.location.pathname + window.location.search);
        })()
        return () => setZoom(false)
    }, [navigate, setText, setSeverity, setOpenSnackbar, setTransition, setZoom])

    const handleDownloadRapport = async (rapport) => {
        if (rapport) {
            setIsDownloading(true);
            const downloadRapport = await apiFetch(`${rapport.url.split('api-rh')[1]}`, {}, false, true)

            if (downloadRapport.ok) {
                const blob = await downloadRapport.blob()
                download(blob, `${rapport.nom}`)
            }
            setIsDownloading(false);
        }
    }
    
    const handleDownloadInterventions = async () => {
        setIsDownloading(true);
        const exportRapport = await apiFetch(`/intervention/interventions/exporter`, {}, false, true)

        if (exportRapport.ok) {
            const blob = await exportRapport.blob()
            download(blob, `Liste des interventions`)
        }
        setIsDownloading(false);
    }   

    const handleChangeSite = (event) => {
        setSite(event.target.value);
        fetchInterventionsFilter(event.target.value, 'isFromSite')
    };

    const handleChangeType = (event) => {
        setType(event.target.value);
        fetchInterventionsFilter(event.target.value, 'isFromType')
    };

    const handleChangeStartDate = (e) => {
        if (!e) {
            setStartDate(e);
            fetchInterventionsFilter(e, 'isFromStartDate')
        } else if (e._d.toString() !== 'Invalid Date') {
            setStartDate(e);
            fetchInterventionsFilter(e, 'isFromStartDate')
        }
    }

    const handleChangeEndDate = (e) => {
        if (!e) {
            setEndDate(e);
            fetchInterventionsFilter(e, 'isFromEndDate')
        } else if (e._d.toString() !== 'Invalid Date') {
            setEndDate(e);
            fetchInterventionsFilter(e, 'isFromEndDate')
        }
    }

    const fetchInterventionsFilter = async (param, entryPoint) => {
        const filterSite =
            site || entryPoint === 'isFromSite' ?
                `entreprise_id=${entryPoint === 'isFromSite' ? param : site}&` : ''
        const filterType =
            type || entryPoint === 'isFromType' ? 
                `type=${entryPoint === 'isFromType' ? param : type}&` : ''
        const filterStartDate =
            startDate || entryPoint === 'isFromStartDate' ?
                `debut=${entryPoint === 'isFromStartDate' ? param ? moment(param).format() : '' : moment(startDate).format()}&` : ''
        const filterEndDate =
            endDate || entryPoint === 'isFromEndDate' ?
                `fin=${entryPoint === 'isFromEndDate' ? param ? moment(param).format() : '' : moment(endDate).format()}&` : ''
        setInterventionZoom(false)
        setLoading(true)
        const fetchInterventions = await apiFetch(`/intervention/interventions?${filterSite}${filterType}${filterStartDate}${filterEndDate}`)
        if (fetchInterventions && fetchInterventions.items) {
            setItems(fetchInterventions.items)
            setInterventions(fetchInterventions)
            setPage(fetchInterventions.page)
            setInterventionZoom(true)
        } else {
            setText('Erreur lors de la récupération des interventions !')
            setSeverity('error')
            setTransition(() => TransitionLeft)
            setOpenSnackbar(true)
            navigate('/tableau-de-bord')
        }
        setLoading(false)
    }

    const handleOpenContactPopup = () => {
        setOpenContactPopup(true)
    }

    const enterHandler = async () => {
        setSpinner(true)
        const filterSite =
            site ?
                `entreprise_id=${site}&` : ''
        const filterType =
            type ? 
                `type=${type}&` : ''
        const filterStartDate =
            startDate ?
                `debut=${moment(startDate).format()}&` : ''
        const filterEndDate =
            endDate ?
                `fin=${moment(endDate).format()}&` : ''
        const fetchInterventions = await apiFetch(`/intervention/interventions?${filterSite}${filterType}${filterStartDate}${filterEndDate}page=${page + 1}`)
        if (fetchInterventions && fetchInterventions.items) {
            setItems(items.concat(fetchInterventions.items))
            setInterventions(fetchInterventions)
            setPage(fetchInterventions.page)
        }
        setSpinner(false)
    };

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('(max-width:1388px)'));

    return (
        <Zoom in={zoom}>
            <Box
                sx={{
                    overflow: 'auto',
                    width: '100%',
                    paddingLeft: 2,
                    paddingRight: 2,
                    marginTop: 3,
                    position: 'relative',
                }}
                ref={target}
            >
                {interventions && <BottomOfPage target={target} func={enterHandler} />}
                <Grid container spacing={2}>
                    <Grid item xs={12} md={8}>
                        <Paper sx={{ borderRadius: 5, boxShadow: '-1px 4px 10px #CCCCCC', padding: '20px 30px', maxWidth: 850 }}>
                            <Typography variant='h6' color='primary' sx={{ mb: 2 }}>
                                Rechercher une intervention :
                            </Typography>
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
                                {profil.entreprises && profil.entreprises.length > 1 && (
                                    <FormControl variant="standard" sx={{ minWidth: 150 }}>
                                        <InputLabel id="multisite-select-label">Entité</InputLabel>
                                        <Select
                                            labelId="multisite-select-label"
                                            id="multisite-select"
                                            value={site}
                                            label="Entité"
                                            onChange={handleChangeSite}
                                        >
                                            <MenuItem value="">
                                                <em>Toutes les entités</em>
                                            </MenuItem>
                                            {profil.entreprises.map((site) => {
                                                return <MenuItem key={site.id} value={site.id}>{site.nom}</MenuItem>
                                            })}
                                        </Select>
                                    </FormControl>
                                )}
                                {types && (
                                    <FormControl variant="standard" sx={{ minWidth: 150 }}>
                                        <InputLabel id="type-select-label">Type</InputLabel>
                                        <Select
                                            labelId="type-select-label"
                                            id="type-select"
                                            value={type}
                                            label="Type"
                                            onChange={handleChangeType}
                                        >
                                            <MenuItem value="">
                                                <em>Tous les types</em>
                                            </MenuItem>
                                            {types.map(type => {
                                                return <MenuItem key={type.value} value={type.value}>{type.label}</MenuItem>
                                            })}
                                        </Select>
                                    </FormControl>
                                )}
                                <DatePickerComponent text={'Date de début'} selectedDate={startDate} handleDateChange={handleChangeStartDate} />
                                <DatePickerComponent text={'Date de fin'} selectedDate={endDate} handleDateChange={handleChangeEndDate} />
                                {isDownloading ? (
                                    <CircularProgress sx={{ mt: 2 }} />
                                ) : (
                                    <StyledTooltip title="Télécharger la liste des interventions">
                                        <IconButton aria-label="Exporter les interventions" color="primary" onClick={handleDownloadInterventions} sx={{ mt: 2 }}>
                                            <FileDownloadIcon />
                                        </IconButton>
                                    </StyledTooltip>
                                )}
                            </Box>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Box sx={{position: 'relative'}}>
                            <Button
                                sx={{
                                    border: '5px solid #ACCDD8',
                                    borderRadius: 2,
                                    height: 70,
                                    width: '100%',
                                    maxWidth: 370,
                                    fontWeight: 'bold',
                                    position: 'absolute',
                                    right: 0,
                                    top: 76
                                }}
                                onClick={handleOpenContactPopup}
                            >
                                Contacter le service interventions
                            </Button>
                            <ContactPopup
                                open={openContactPopup}
                                handleClose={() => setOpenContactPopup(false)}
                                title='intervention'
                                text='Notre service intervention est joignable au 02 97 84 32 51.'
                            />
                        </Box>
                    </Grid>
                </Grid>
                {(items && !loading) ? items.map((intervention, index) => {
                    return <Zoom
                        in={interventionZoom}
                        timeout={700}
                        key={index}
                        style={{ 
                            transitionDelay: page > 1 ? '0ms' : index * 100 + 200 + 'ms',
                            overflow: 'hidden'
                        }}
                    >
                        <Paper sx={{mt: 5, pl: 3, borderRadius: 7, boxShadow: '-1px 4px 10px #CCCCCC', maxWidth: '100%' }}>
                            <div style={{float: 'right', width: 400, marginTop: 20, marginBottom: 25}}>
                                <Typography
                                    variant='body2'
                                    color='primary'
                                    sx={{fontSize: 17}}
                                >
                                    Dossier suivi par : {intervention.responsable ? `${intervention.responsable.prenom} ${intervention.responsable.nom}` : 'Pas de responsable dédié'}
                                </Typography>
                            </div>
                            <Grid container spacing={3} sx={{pb: 5}}>
                                <Grid item xs={12} sm={3}>
                                    <Grid container spacing={3} alignItems="flex-start">
                                        <Grid item xs={12} sm={4} sx={{ mt: 1, display: 'flex', justifyContent: 'center' }}>
                                            <Avatar
                                                alt={
                                                    intervention.intervenant &&
                                                    intervention.intervenant.photo &&
                                                    intervention.intervenant.photo.nom
                                                }
                                                src={
                                                    intervention.intervenant && 
                                                    intervention.intervenant.photo &&
                                                    intervention.intervenant.photo.url
                                                }
                                                sx={{width: 80, height: 80}}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} sx={{ mt: 2, pl: '25px !important', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                            <Typography variant='h6' color='primary' sx={{fontSize: 17, fontWeight: 'bold'}}>
                                                Intervenant
                                            </Typography>
                                            <Typography variant='body2' color='primary' sx={{fontSize: 14}}>
                                                {intervention.intervenant && 
                                                `${intervention.intervenant.prenom} ${intervention.intervenant.nom}`}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={1} sx={{borderRight: '5px solid #CECECE', height: 150}}>
                                            
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={5}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={6} sx={{paddingTop: '0px !important'}}>
                                            <Typography color='primary' sx={{fontSize: 14, mb: 2}}>
                                                <span style={{fontWeight: 'bold'}}>Site : </span>{intervention.entreprise && intervention.entreprise.nom}
                                            </Typography>
                                            <Typography color='primary' sx={{fontSize: 14, mb: 2}}>
                                                <span style={{fontWeight: 'bold'}}>Début : </span>{moment(intervention.debut).format('DD MMMM YYYY')}
                                            </Typography>
                                            <Typography color='primary' sx={{fontSize: 14, mb: 2}}>
                                                <span style={{fontWeight: 'bold'}}>Fin : </span>{moment(intervention.fin).format('DD MMMM YYYY')}
                                            </Typography>
                                            <Typography color='primary' sx={{fontSize: 14, mb: 2}}>
                                                <span style={{fontWeight: 'bold'}}>Type : </span>{intervention.type && intervention.type.label}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={6} sx={{borderRight: '5px solid #CECECE', height: 150, paddingTop: '0px !important'}}>
                                            <div style={{display: 'flex', marginBottom: 16}}>
                                                <img src={duree} width={30} alt="durée d'intervention" />
                                                <Typography color='primary' sx={{fontSize: 14, ml: 2, mt: 1}}>{intervention.duree}</Typography>
                                            </div>
                                            <Grid container spacing={0} sx={{mt: 1}}>
                                                <Grid item xs={2}>
                                                    <img src={adresse} width={30} alt="adresse d'intervention" />
                                                </Grid>
                                                <Grid item xs={10}>
                                                    <Typography color='primary' sx={{fontSize: 14}}>
                                                        {intervention.adresse.adresse === 'A distance' ?
                                                        'A distance' :
                                                        `${intervention.adresse.adresse}, ${intervention.adresse.code_postal} ${intervention.adresse.ville}`}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={4} sx={{paddingTop: '0px !important'}}>
                                    {intervention.rapport ? <div>
                                        <Typography
                                            color='primary'
                                            variant='h6'
                                            sx={{
                                                fontSize: 15,
                                                fontWeight: 'bold'
                                            }}
                                        >
                                            Rapport
                                        </Typography>
                                        <Grid
                                            container
                                            spacing={3}
                                            style={{
                                                borderTop: '3px solid #FFBDA6',
                                                borderBottom: '3px solid #FFBDA6',
                                                marginTop: 15,
                                                padding: '40px 0px',
                                                width: 405,
                                                marginLeft: 0
                                            }}
                                        >
                                            <Grid item xs={12} sm={6} sx={{paddingTop: '0px !important', paddingLeft: '0px !important'}}>
                                                <Typography
                                                    color='primary'
                                                    variant='body1'
                                                    sx={{
                                                        fontSize: 14,
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                        whiteSpace: 'nowrap'
                                                    }}
                                                >
                                                    {intervention.rapport.nom}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={6} sx={{paddingTop: '0px !important'}}>
                                                {isDownloading ? (
                                                    <CircularProgress size={20} />
                                                ) : (
                                                    <StyledTooltip title={`Télécharger le document ${intervention.rapport.nom}`}>
                                                        <Typography
                                                            variant='body1'
                                                            sx={{cursor: 'pointer', fontSize: 14, color: '#75ADBE'}}
                                                            onClick={() => handleDownloadRapport(intervention.rapport)}
                                                        >
                                                            Disponible
                                                        </Typography>
                                                    </StyledTooltip>
                                                )}
                                            </Grid>
                                        </Grid>
                                    </div> : 
                                    <div>
                                        <Typography
                                            color='primary'
                                            variant='h6'
                                            sx={{
                                                fontSize: 15,
                                                fontWeight: 'bold'
                                            }}
                                        >
                                            Rapport
                                        </Typography>
                                        <div
                                            style={{
                                                borderTop: '3px solid #FFBDA6',
                                                borderBottom: '3px solid #FFBDA6',
                                                marginTop: 15,
                                                padding: '40px 0px',
                                                width: 405,
                                            }}
                                        >
                                            <Typography color='primary' variant='body1'>Pas de rapport disponible pour le moment.</Typography>
                                        </div>
                                    </div>}
                                </Grid>
                            </Grid>
                        </Paper>
                    </Zoom>
                }) : 
                <div style={{textAlign: 'center', overflow: 'hidden', marginTop: 200}}>
                    <CircularProgress />
                </div>}
                {spinner && <div style={{textAlign: 'center', overflow: 'hidden', marginTop: 20}}><CircularProgress color={'primary'} /></div>}
                {!loading && <div style={{textAlign: 'right', marginTop: 20}}>
                    <Typography>Total d'interventions : {interventions.total}</Typography>
                </div>}
            </Box>
        </Zoom>
    )
}