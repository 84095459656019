import React from 'react';
import { 
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Slide,
    Typography,
} from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
import slide1 from '../assets/Com_VIS_20240205_BO-DRH_slides-bienvenue_1.png'
import slide2 from '../assets/Com_VIS_20240205_BO-DRH_slides-bienvenue_2.png'
import slide3 from '../assets/Com_VIS_20240205_BO-DRH_slides-bienvenue_3.png'
import slide4 from '../assets/Com_VIS_20240205_BO-DRH_slides-bienvenue_4.png'
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Navigation, Pagination } from 'swiper/modules';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function WelcomePopup({ open, handleClose }) {

  return (
        <Dialog
            open={!!open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-labelledby="contact-popup-title"
            aria-describedby="contact-popup-description"
            maxWidth={'md'}
            sx={{'& .MuiPaper-root': {borderRadius: '32px'}}}
        >
            <DialogTitle id="contact-popup-title" component={'div'} sx={{mb: 4}}>
                
            </DialogTitle>
            <DialogContent sx={{height: 450}}>
                <Swiper
                    navigation={true}
                    modules={[Navigation, Pagination]}
                    className="mySwiper"
                    pagination={{
                        clickable: true,
                    }}
                >
                    <SwiperSlide>
                        <div className="swiper-div">
                            <Typography variant='h6' color='primary' sx={{fontWeight: 'bold'}}>
                                BIENVENUE DANS VOTRE NOUVEL ESPACE RH
                            </Typography>
                            <img src={slide1} alt='bienvenue' />
                            <Typography variant='body1' color='primary'>
                                Découvrez les nouvelles fonctionnalités créées pour vous, afin de vous aider à préserver la santé mentale et prévenir les risques psychosociaux.
                            </Typography>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="swiper-div">
                            <Typography variant='h6' color='primary' sx={{fontWeight: 'bold'}}>
                                SUIVRE
                            </Typography>
                            <img src={slide2} alt='suivre' />
                            <Typography variant='body1' color='primary'>
                                Suivez les actions de prévention mises en place avec Pros-Consulte et consultez les feed backs.
                            </Typography>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="swiper-div">
                            <Typography variant='h6' color='primary' sx={{fontWeight: 'bold'}}>
                                ANALYSER
                            </Typography>
                            <img src={slide3} alt='analyser' />
                            <Typography variant='body1' color='primary'>
                                Décryptez les données que nous mettons à votre disposition concernant les prestations : ligne d'écoute, interventions sur site et formations.
                            </Typography>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="swiper-div">
                            <Typography variant='h6' color='primary' sx={{fontWeight: 'bold'}}>
                                ANTICIPER
                            </Typography>
                            <img src={slide4} alt='anticiper' />
                            <Typography variant='body1' color='primary'>
                                Découvrez les conseils de nos experts pour agir face aux situations à risque psychosocial.
                            </Typography>
                        </div>
                    </SwiperSlide>
                </Swiper>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>
                    Fermer
                </Button>
            </DialogActions>
        </Dialog>
  );
}