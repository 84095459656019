export const routes = {
    login: '/login',
    home: '/',
    dashboard: '/tableau-de-bord',
    plateforme: 'ligne-d-ecoute',
    resetMdp: 'reset-mot-de-passe',
    changeMdp: 'changer-mot-de-passe',
    interventions: 'interventions',
    formations: 'formations',
    conseils: 'conseils-prevention',
    contrat: 'mon-contrat',
    compte: 'mon-compte',
    theme: 'conseils-prevention/:category',
    article: 'conseils-prevention/:category/:id',
}