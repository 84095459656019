import {
    Avatar,
    Button,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Typography,
    Zoom,
    styled,
    useMediaQuery
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { apiFetch } from '../security/apiFetch';
import { useNavigate } from 'react-router-dom';
import Formation from './DashboardItems/Formation';
import Intervention from './DashboardItems/Intervention';
import Plateforme from './DashboardItems/Plateforme';
import ContactPopup from './DashboardItems/ContactPopup';

const ColorButton = styled(Button)(({ theme }) => ({
    color: '#01304A',
    backgroundColor: 'white',
    '&:hover': {
      backgroundColor: '#FEFEFE',
    },
}));

export default function Dashboard({ profil, setTranstion, setText, setSeverity, setOpenSnackbar }) {
    const [site, setSite] = useState('')
    const [selectedSiteObject, setSelectedSiteObject] = useState(false)
    const [openInformationPopup, setOpenInformationPopup] = useState(false)
    const [openContactPopup, setOpenContactPopup] = useState(false)
    const [contact, setContact] = useState(false)
    const [actualite, setActualite] = useState(false)
    let navigate = useNavigate()
    const matches = useMediaQuery('(max-width:1248px)');
    const isSmallScreen = useMediaQuery('(max-width:1388px)');

    document.title = 'Espace RH - Tableau de bord'

    useEffect(() => {
        (async () => {
            if (profil && profil.entreprises && profil.entreprises.length === 1) {
                setSelectedSiteObject(profil.entreprises[0])
            }

            const getContact = await apiFetch('/mon-compte/mon-contact-dedie')
            if (getContact && getContact.nom) {
                setContact(getContact)
            }

            const getActualite = await apiFetch('/communication/conseil-prevention/actualite')
            if (getActualite && getActualite.id) {
                setActualite(getActualite)
            }
        })()
    }, [profil])

    const handleChange = async (event) => {
        setSite(event.target.value);
        setSelectedSiteObject(profil.entreprises.find((entreprise) => entreprise.id === event.target.value));
    };

    const handleOpenInformationPopup = (object) => {
        setOpenInformationPopup(object)
    }
    return (
        <Zoom in={!!profil}>
            <div style={{overflowX: 'hidden'}}>
                <div style={{width: '100%', textAlign: 'center', marginBottom: 60}}>
                    <Typography variant='h5' color='primary' sx={{fontWeight: 'bold'}}>BIENVENUE DANS VOTRE ESPACE RH</Typography>
                    <Typography variant='body1' color='primary'>Outil de reporting et d'information sur la prévention des risques psychosociaux</Typography>
                </div>
                <Grid container spacing={3} sx={{mb: 6}}>
                    <Grid item xs={12} sm={2}>
                        {profil &&
                        profil.entreprises &&
                        profil.entreprises.length > 1 &&
                        <Zoom in={!!profil} timeout={700} style={{ transitionDelay: '700ms' }}>
                            <div style={{border: '2px solid #75ADBE', borderRadius: 17, padding: '10px 30px 15px 30px'}}>
                                <FormControl fullWidth sx={{maxWidth: 200}}>
                                    <InputLabel id="site-select-label">Mon entreprise</InputLabel>
                                    <Select
                                        labelId="site-select-label"
                                        id="site-select"
                                        value={site}
                                        label="Mon entreprise"
                                        variant="standard"
                                        onChange={handleChange}
                                    >
                                        {profil &&
                                        profil.entreprises &&
                                        profil.entreprises.length > 1 &&
                                        profil.entreprises.map((entreprise) => {
                                            return <MenuItem key={entreprise.id} value={entreprise.id}>{entreprise.nom}</MenuItem>
                                        })}
                                    </Select>
                                </FormControl>
                            </div>
                        </Zoom>}
                    </Grid>
                    <Grid item xs={12} sm={5}>
                        <Zoom in={!!actualite} timeout={700} style={{ transitionDelay: '200ms' }}>
                            <Paper sx={{boxShadow: '-1px 4px 10px #CCCCCC', borderRadius: '16px', height: 110}}>
                                {actualite && <Grid container spacing={0}>
                                    <Grid item xs={12} sm={4}>
                                        <img
                                            src={actualite.photo.url}
                                            alt={actualite.photo.nom}
                                            height={110}
                                            style={{borderRadius: 16}}
                                        />
                                    </Grid>
                                    {!matches && <Grid item xs={12} sm={4} sx={{pt: 3, pb: 1, pl: 1}}>
                                        <Typography variant='body1' sx={{fontWeight: 'bold', mr: 1}} color='primary'>
                                            L'actu prévention
                                        </Typography>
                                        <Typography
                                            variant='body2'
                                            sx={{
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'nowrap'
                                            }}
                                        >
                                            {actualite.titre}
                                        </Typography>
                                    </Grid>}
                                    <Grid item xs={12} sm={4} sx={{position: 'relative'}}>
                                        <ColorButton
                                            variant='contained'
                                            onClick={() => navigate(`/conseils-prevention/${actualite.categorie_id}/${actualite.id}`)}
                                            sx={{position: 'absolute', bottom: 45, width: 150, right: matches ? -110 : 50}}
                                        >
                                            En savoir plus
                                        </ColorButton>
                                    </Grid>
                                </Grid>}
                            </Paper>
                        </Zoom>
                    </Grid>
                    <Grid item xs={12} sm={5}>
                        <Zoom in={!!contact} timeout={700} style={{ transitionDelay: '200ms' }}>
                            <Paper sx={{boxShadow: '1px 3px 6px #CCCCCC', borderRadius: '16px', background: '#FFD9CC', p: '20px 0px 20px 20px'}}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={2}>
                                        <Avatar
                                            src={
                                                contact.photo &&
                                                contact.photo.url
                                            }
                                            alt={
                                                contact.prenom
                                            }
                                            sx={{width: 70, height: 70}}
                                        />
                                    </Grid>
                                    {!matches && <Grid item xs={12} sm={6}>
                                        <Typography variant='body1' sx={{fontWeight: 'bold'}} color='primary'>
                                            Votre contact dédié
                                        </Typography>
                                        <Typography variant='body2' color='primary'>
                                            {contact.prenom} {contact.nom}
                                        </Typography>
                                        <Typography variant='body2' color='primary'>
                                            {contact.fonction}
                                        </Typography>
                                    </Grid>}
                                    <Grid item xs={12} sm={4} sx={{position: 'relative'}}>
                                        <ColorButton
                                            variant='contained'
                                            sx={{
                                                position: 'absolute',
                                                bottom: 10,
                                                width: 150,
                                                right: matches ? '-120px' : 'auto'
                                            }}
                                            onClick={() => setOpenContactPopup(true)}
                                        >
                                            Contacter
                                        </ColorButton>
                                        <ContactPopup open={openContactPopup} handleClose={() => setOpenContactPopup(false)} data={contact} />
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Zoom>
                    </Grid>
                </Grid>
                <div className="sd-tabs">
                    <input className="sd-tab-radio" name="tabs" tabIndex="1" type="radio" id="tabone" defaultChecked="checked" />
                    <label className="sd-tab-label" style={{padding: 30}} htmlFor="tabone">
                        <div className="sd-tab-desc">Ligne d'écoute</div>
                    </label>
                    <div className="sd-tab-content" tabIndex="1" style={{padding: 30}}>
                        {!selectedSiteObject ? <Typography sx={{p: 20, width: '100%', textAlign: 'center'}}>
                            Veuillez sélectionner une entreprise
                        </Typography> : 
                        <Plateforme
                            selectedSiteObject={selectedSiteObject}
                            setTransition={setTranstion}
                            setSeverity={setSeverity}
                            setOpenSnackbar={setOpenSnackbar}
                            setText={setText}
                            openInformationPopup={openInformationPopup}
                            setOpenInformationPopup={setOpenInformationPopup}
                            handleOpenInformationPopup={handleOpenInformationPopup}
                        />}
                    </div>
                    <input className="sd-tab-radio" tabIndex="1" name="tabs" type="radio" id="tabtwo" />
                    <label className="sd-tab-label" style={{padding: 30}} htmlFor="tabtwo"><div className="sd-tab-desc">Interventions sur site</div></label>
                    <div className="sd-tab-content" tabIndex="1" style={{padding: 30}}>
                        {!selectedSiteObject ? <Typography sx={{p: 20, width: '100%', textAlign: 'center'}}>
                            Veuillez sélectionner une entreprise
                        </Typography> : 
                        <Intervention
                            selectedSiteObject={selectedSiteObject}
                            setTransition={setTranstion}
                            setSeverity={setSeverity}
                            setOpenSnackbar={setOpenSnackbar}
                            setText={setText}
                            openInformationPopup={openInformationPopup}
                            setOpenInformationPopup={setOpenInformationPopup}
                            handleOpenInformationPopup={handleOpenInformationPopup}
                        />}
                    </div>

                    <input className="sd-tab-radio" tabIndex="1" name="tabs" type="radio" id="tabthree" />
                    <label className="sd-tab-label" style={{padding: 30}} htmlFor="tabthree"><div className="sd-tab-desc">Formations</div></label>
                    <div className="sd-tab-content" tabIndex="1" style={{padding: 30}}>
                        {!selectedSiteObject ? <Typography sx={{p: 20, width: '100%', textAlign: 'center'}}>
                            Veuillez sélectionner une entreprise
                        </Typography> :
                        <Formation 
                            selectedSiteObject={selectedSiteObject}
                            setTransition={setTranstion}
                            setSeverity={setSeverity}
                            setOpenSnackbar={setOpenSnackbar}
                            setText={setText}
                            openInformationPopup={openInformationPopup}
                            setOpenInformationPopup={setOpenInformationPopup}
                            handleOpenInformationPopup={handleOpenInformationPopup}
                        />}
                    </div>
                </div>
            </div>
        </Zoom>
    )
}