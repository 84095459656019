import React, { useEffect, useState } from 'react';
import { apiFetch } from '../../security/apiFetch';
import PdfReader from '../../components/PdfReader';
import VideoPlayer from './VideoPlayer';
import ReactAudioPlayer from 'react-audio-player';
import { useNavigate, useParams } from 'react-router-dom';
import { TransitionLeft } from '../../helpers/utils';
import { Typography, CircularProgress } from '@mui/material';
import ReactGA from 'react-ga';

export default function Article({ setText, setSeverity, setOpenSnackbar, setTransition, setBreadcrumb }) {
    const [ressource, setRessource] = useState(false);
    const [loading, setLoading] = useState(true);
    const { category, id } = useParams();
    let navigate = useNavigate();

    useEffect(() => {
        (async () => {
            const getArticles = await apiFetch(`/communication/conseil-prevention/categories/${category}/articles`);
            if (getArticles && getArticles.length) {
                const findArticle = getArticles.find(article => article.id === parseInt(id));
                setRessource(findArticle);
                setBreadcrumb([`Conseils prévention`, getArticles[0], findArticle.media]);
            } else {
                setText('Erreur lors de la récupération des articles');
                setSeverity('error');
                setTransition(() => TransitionLeft);
                setOpenSnackbar(true);
                navigate(-1);
            }
            setLoading(false); 
        })();
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, [setText, setSeverity, setTransition, setOpenSnackbar, category, id, navigate, setBreadcrumb]);

    

    return (
        <div>
            {loading && (
            <CircularProgress style={{ margin: 'auto', marginTop: '50vh', display: 'block' }} />
            )}
            {!loading && (
                <>
                    <div
                        style={{
                            width: '100%',
                            backgroundColor: '#ACCDD8',
                            marginTop: 50,
                            padding: 20,
                            textAlign: 'center'
                        }}
                    >
                        <Typography color='primary' variant='h5'>{ressource.titre}</Typography>
                    </div>
                    <img
                        src={ressource && ressource.photo.url}
                        alt={ressource && ressource.photo.nom}
                        style={{width: 200, marginLeft: 50, marginTop: -150}}
                    />
                    <div style={{maxWidth: 1300, margin: 'auto'}}>
                        <div style={{textAlign: 'center', marginBottom: 30, marginTop: 20}}>
                            <Typography color='primary' variant='body1'>{ressource.description}</Typography>
                        </div>
                        {ressource && ressource.format === 'PDF' && <PdfReader pdfUrl={ressource.media.url} />}
                        {ressource && ressource.format === 'Podcast' && 
                            <>
                                <ReactAudioPlayer
                                    src={ressource.media.url}
                                    autoPlay
                                    controls
                                    style={{
                                        width: '100%',
                                        marginTop: 30,
                                    }}
                                />
                            </>
                        }
                        {ressource && ressource.format === 'Vidéo' && 
                            <VideoPlayer videoUrl={ressource.media.url} autoPlay={true} />}
                    </div>
                </>
            )}
        </div>
    );
}
