import { Navigate } from 'react-router-dom'
import { getAuth } from './Authentifier'

export const NotRequireAuth = ({ children }) => {

    if (getAuth() && getAuth().token) {
        return <Navigate to='/' />
    }

    return children
}