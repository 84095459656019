import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Navigation from './MainMenuItems/Navigation';
import { Route, Routes, useLocation } from 'react-router-dom';
import { routes } from '../security/Routes';
import AlertSnackbar from '../components/AlertSnackbar';
import { TransitionLeft } from '../helpers/utils';
import Interventions from './Interventions';
import Dashboard from './Dashboard';
import TopToolbar from './MainMenuItems/TopToolbar';
import { apiFetch } from '../security/apiFetch';
import Profil from './Profil';
import Formations from './Formations';
import Contrat from './Contrat';
import Plateforme from './Plateforme';
import Documents from './Documents';
import WelcomePopup from '../components/WelcomePopup';
import Theme from './DocumentsItems/Theme';
import Article from './DocumentsItems/Article';
import UiBreadcrumbs from './DocumentsItems/Breadcrumbs';
import ReactGA from 'react-ga';
import Error from './Error';

const drawerWidth = 350;

export default function Menu() {
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [page, setPage] = React.useState('');
    const [isClosing, setIsClosing] = React.useState(false);
    const [breadcrumb, setBreadcrumb] = React.useState([]);
    const [profil, setProfil] = React.useState(false);
    const [transition, setTransition] = React.useState(() => TransitionLeft)
    const [severity, setSeverity] = React.useState('info')
    const [text, setText] = React.useState(false)
    const [openSnackbar, setOpenSnackbar] = React.useState(false)
    const [openWelcomePopup, setOpenWelcomePopup] = React.useState(false)
    const location = useLocation()
  
    React.useEffect(() => {
        if (window) {
            setPage(window.location.pathname.slice(1))
        }
        (async () => {
            const getMe = await apiFetch('/mon-compte/mes-informations')
            if (getMe) {
                setOpenWelcomePopup(true)
                setProfil(getMe)
            } else {
                setText('Erreur lors de la récupération des interventions !')
                setSeverity('error')
                setTransition(() => TransitionLeft)
                setOpenSnackbar(true)
            }
        })()
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, [setPage])

    const handleDrawerClose = () => {
        setIsClosing(true);
        setMobileOpen(false);
    };

    const handleDrawerTransitionEnd = () => {
        setIsClosing(false);
    };

    const handleDrawerToggle = () => {
        if (!isClosing) {
            setMobileOpen(!mobileOpen);
        }
    };

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar
                position="fixed"
                sx={{
                    width: { sm: `calc(100% - ${drawerWidth}px)` },
                    ml: { sm: `${drawerWidth}px` },
                    display : {sm: 'none' },
                }}
            >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { sm: 'none' } }}
                    >
                        <MenuIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Box
                component="nav"
                sx={{
                    width: { sm: drawerWidth },
                    flexShrink: { sm: 0 },
                }}
                aria-label="mailbox folders"
            >
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Drawer
                    variant="temporary"
                    open={mobileOpen}
                    onTransitionEnd={handleDrawerTransitionEnd}
                    onClose={handleDrawerClose}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                >
                    {<Navigation setPage={setPage} />}
                </Drawer>
                <Drawer
                    variant="permanent"
                    sx={{
                        display: { xs: 'none', sm: 'block' },
                        '& .MuiDrawer-paper': {
                            boxSizing: 'border-box',
                            width: drawerWidth,
                            backgroundColor: '#01304A',
                            color: 'white',
                            boxShadow: '3px 2px 11px #01304A8C'
                        },
                    }}
                    open
                >
                    {<Navigation setPage={setPage} />}
                </Drawer>
            </Box>
            <Box
                component="main"
                sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
            >
                <AlertSnackbar open={openSnackbar} setOpen={setOpenSnackbar} transition={transition} message={text} severity={severity} />
                <TopToolbar page={page} profil={profil} />
                <WelcomePopup open={openWelcomePopup} handleClose={() => setOpenWelcomePopup(false)} />
                {location.pathname && location.pathname.startsWith('/conseils-prevention') && <UiBreadcrumbs breadcrumb={breadcrumb} />}
                <Routes>
                    <Route path={routes.dashboard} exact element={
                        <Dashboard
                            setOpenSnackbar={setOpenSnackbar}
                            setText={setText}
                            setTransition={setTransition}
                            setSeverity={setSeverity}
                            profil={profil}
                        />}
                    />
                    <Route path={routes.home} exact element={
                        <Dashboard
                            setOpenSnackbar={setOpenSnackbar}
                            setText={setText}
                            setTransition={setTransition}
                            setSeverity={setSeverity}
                            profil={profil}
                        />}
                    />
                    <Route path={routes.interventions} exact element={
                        <Interventions
                            setOpenSnackbar={setOpenSnackbar}
                            setText={setText}
                            setTransition={setTransition}
                            setSeverity={setSeverity}
                            profil={profil}
                        />}
                    />
                    <Route path={routes.compte} exact element={
                        <Profil
                            setOpenSnackbar={setOpenSnackbar}
                            setText={setText}
                            setTransition={setTransition}
                            setSeverity={setSeverity}
                            profil={profil}
                        />}
                    />
                    <Route path={routes.formations} exact element={
                        <Formations
                            setOpenSnackbar={setOpenSnackbar}
                            setText={setText}
                            setTransition={setTransition}
                            setSeverity={setSeverity}
                            profil={profil}
                        />}
                    />
                    <Route path={routes.contrat} exact element={
                        <Contrat
                            setOpenSnackbar={setOpenSnackbar}
                            setText={setText}
                            setTransition={setTransition}
                            setSeverity={setSeverity}
                            profil={profil}
                        />}
                    />
                    <Route path={routes.plateforme} exact element={
                        <Plateforme
                            setOpenSnackbar={setOpenSnackbar}
                            setText={setText}
                            setTransition={setTransition}
                            setSeverity={setSeverity}
                            profil={profil}
                        />}
                    />
                    <Route path={routes.conseils} exact element={
                        <Documents
                            setOpenSnackbar={setOpenSnackbar}
                            setText={setText}
                            setTransition={setTransition}
                            setSeverity={setSeverity}
                            profil={profil}
                            setBreadcrumb={setBreadcrumb}
                        />}
                    />
                    <Route path={routes.theme} exact element={
                        <Theme
                            setOpenSnackbar={setOpenSnackbar}
                            setText={setText}
                            setTransition={setTransition}
                            setSeverity={setSeverity}
                            setBreadcrumb={setBreadcrumb}
                        />}
                    />
                    <Route path={routes.article} exact element={
                        <Article
                            setOpenSnackbar={setOpenSnackbar}
                            setText={setText}
                            setTransition={setTransition}
                            setSeverity={setSeverity}
                            setBreadcrumb={setBreadcrumb}
                        />}
                    />
                    <Route path={'*'} exact element={
                        <Error
                            setOpenSnackbar={setOpenSnackbar}
                            setText={setText}
                            setTransition={setTransition}
                            setSeverity={setSeverity}
                            setBreadcrumb={setBreadcrumb}
                        />}
                    />
                </Routes>
            </Box>
        </Box>
    );
}