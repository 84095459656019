import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Grid, IconButton, InputAdornment, Paper, TextField, Typography, Zoom } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ProgressBar from '../components/ProgressBar';
import { LoadingButton } from '@mui/lab';
import { TransitionLeft, progressBarWidth } from '../helpers/utils';
import { apiFetch } from '../security/apiFetch';
import AlertSnackbar from '../components/AlertSnackbar';
import { useNavigate } from 'react-router-dom';
import logo from '../assets/logo.jpg'

export default function ChangePassword() {
    const [error, setError] = useState(false)
    const [showNewPassword, setShowNewPassword] = useState(false)
    const [showConfirmPassword, setShowConfirmPassword] = useState(false)
    const [style, setStyle] = useState(false)
    const [loading, setLoading] = useState(false)
    const [transition, setTransition] = useState(() => TransitionLeft)
    const [severity, setSeverity] = useState('success')
    const [message, setMessage] = useState('')
    const [token, setToken] = useState(false)
    let navigate = useNavigate()

    useEffect(() => {
        const queryParameters = new URLSearchParams(window.location.search)
        const getToken = queryParameters.get('confirmation_token')
        if (getToken) {
            setToken(getToken)
        } else {
            setMessage('Erreur lors de la récupération du token, veuillez réessayer !')
            setSeverity('error')
            setTransition(() => TransitionLeft)
            setError(true)
            setTimeout(() => {
                navigate('/login')
            }, 3000)
        }
    }, [setMessage, setSeverity, setToken, setTransition, navigate])

    const handleClickNewPassword = () => {
        setShowNewPassword(!showNewPassword)
    }

    const handleClickConfirmNewPassword = () => {
        setShowConfirmPassword(!showConfirmPassword)
    }

    const handleChangeProgressBar = (e) => {
        progressBarWidth(e, setStyle)
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)
        setError({})

        const data = new FormData(e.target)
        const password = data.get('password')
        const confirmNewPassword = data.get('confirmNewPassword')

        if (!password) {
            setError({...error, password: 'Veuillez renseigner ce champ'})
            setLoading(false)
            return
        }

        if (password !== confirmNewPassword) {
            setLoading(false)
            return setError({confirmNewPassword: 'Ce mot de passe n\'est pas identique au nouveau mot de passe'})
        }

        const dataObject = {
            password: {
                first: password,
                second: confirmNewPassword,
            }
        }  

        const changePassword = await apiFetch(`/mon-compte/definir-mon-mot-de-passe/${token}`, {
            method: 'POST',
            body: JSON.stringify(dataObject)
        }, true)
        if (changePassword !== 'success') {
            setSeverity('error')
            setMessage('Token invalide')
            setTransition(() => TransitionLeft)
            setError(true)
        } else {
            setSeverity('success')
            setMessage('Le mot de passe a bien été modifié !')
            setTransition(() => TransitionLeft)
            setError(true)
            setTimeout(() => {
                navigate('/login')
            }, 3000)
        }
        setLoading(false)
    }

    return (
        <div 
            className='login'
        >
            <div
                style={{
                    maxWidth: 800,
                    width: '100%',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)'
                }}
            >
                <div style={{padding: 20, borderRadius: '10px', backgroundColor: 'white', }}>
                    <div style={{width: '100%', margin: 'auto', textAlign: 'center', cursor: 'pointer'}} onClick={() => navigate('/login')}>
                        <img src={logo} alt="Pros consulte" width={100} height={100} />
                    </div>
                    <Typography 
                        variant='h5' 
                        color={'primary'} 
                        style={{
                            fontSize: 22,
                            fontWeight: 'bold',
                            letterSpacing: 1,
                            marginBottom: 15,
                            textAlign: 'center',
                        }}
                    >
                        Bienvenue sur votre espace ! 
                    </Typography>
                    <Typography variant='h6' color={'primary'} style={{fontSize: 15, maxWidth: 670, marginLeft: '30px'}}>
                        Pour changer votre mot de passe, veuillez saisir votre nouveau mot de passe et votre confirmation de mot de passe.
                    </Typography><br />
                    <Zoom in={true} timeout={700} style={{ transitionDelay: '1000ms' }}>
                        <Paper
                            sx={{
                                maxWidth: 1500,
                                borderRadius: 5,
                                padding: '20px 30px',
                                mr: 4,
                                height: 170
                            }}
                            elevation={0}
                        >
                            <Typography color='primary' sx={{fontWeight: 'bold'}} variant='h6'>Mot de passe</Typography>
                            <form noValidate onSubmit={handleSubmit} autoComplete="off">
                                <Grid container spacing={3} sx={{maxWidth: 700}}>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            id="password" 
                                            name="password" 
                                            label="Nouveau mot de passe"
                                            sx={{mt: 1, width: 350}}
                                            type={showNewPassword ? "text" : "password"}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleClickNewPassword}
                                                        >
                                                            {showNewPassword ? <Visibility /> : <VisibilityOff />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                )
                                            }}
                                            required
                                            autoComplete="on"
                                            onChange={handleChangeProgressBar}
                                            error={!!error.password} helperText={error.password}
                                        />
                                        <ProgressBar style={style} />
                                        <TextField 
                                            id="confirmNewPassword" 
                                            name="confirmNewPassword" 
                                            label="Confirmer le nouveau mot de passe"
                                            type={showConfirmPassword ? "text" : "password"}
                                            sx={{mt: 1, width: 350 }}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleClickConfirmNewPassword}
                                                        >
                                                            {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                )
                                            }}
                                            required
                                            autoComplete="on"
                                            error={!!error.confirmNewPassword}
                                            helperText={error.confirmNewPassword}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Typography variant='body2' sx={{mt: 0, ml: 8}}>
                                            Pour être valide, votre mot de passe doit comporter au moins 8 caractères et 
                                            3 types de caractères différents : minuscules, majuscules, chiffres,...
                                        </Typography>
                                        <LoadingButton
                                            type='submit'
                                            loading={loading}
                                            color={'secondary'}
                                            variant="contained"
                                            sx={{mt: 1, ml: 8}}
                                        >
                                            Modifier le mot de passe
                                        </LoadingButton>
                                    </Grid>
                                </Grid>
                            </form>
                            <AlertSnackbar open={!!error} setOpen={setError} transition={transition} message={message} severity={severity} />
                        </Paper>
                    </Zoom>
                </div>
            </div>
        </div>
    )
}