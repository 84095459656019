import * as React from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useNavigate } from 'react-router-dom';


export default function UiBreadcrumbs({ breadcrumb }) {
    const navigate = useNavigate();

    function handleClick(event) {
        event.preventDefault();
    }

    const handleNavigate = (route) => {
        navigate(route);
    }

    const formatHrefBreadcrumb = (route) => {
        if (route.url) return;
        if (route === 'Conseils prévention') return '/conseils-prevention';
        if (route.categorie) return `/conseils-prevention/${route.categorie_id}`;
    }


    return (
        <div role="presentation" onClick={handleClick} style={{marginBottom: 20, maxWidth: 1500}}>
            
                <Breadcrumbs
                    separator={<NavigateNextIcon fontSize="small" />}
                    aria-label="breadcrumb"
                    sx={{
                        height: 30,
                        placeItems: 'flex-start',
                        borderBottom: 'none',
                        marginLeft: 10,
                        cursor: 'pointer',
                    }}
                >
                    {breadcrumb && breadcrumb.length && breadcrumb.map((item, index) => (
                        <Link
                            key={index}
                            underline="hover"
                            color="inherit"
                            href={`/${item.categorie ? item.categorie : item.nom ? item.nom : item}`}
                            onClick={() => handleNavigate(formatHrefBreadcrumb(item))}
                        >
                            {item.categorie || item.nom || item}
                        </Link>
                    ))}
                </Breadcrumbs>
            
        </div>
    );
}
